import { Alert, Steps } from "antd";
import React from "react";
import { Container, ContainerWrapper, PageTitle } from "../../../constants";
import {
  LinkWrapper,
  OrderLink,
  ElementWrapper,
  StepsWrapper,
} from "./resultEl";

const OrderTrackingResult = () => {
  const { Step } = Steps;

  return (
    <ContainerWrapper>
      <Container width="70%">
        <PageTitle fontSize="1.75rem" margin="80px 0 0 0">
          Order Tracking
        </PageTitle>
        <PageTitle as="h3" margin="0">
          ORD-9857920847
        </PageTitle>
        <LinkWrapper>
          <OrderLink to=".">Track another order</OrderLink>
        </LinkWrapper>
        <StepsWrapper>
          <Steps current={2} responsive>
            <Step title="Ordered" />
            <Step title="Processed" />
            <Step title="Shipped" />
            <Step title="Delivered" />
          </Steps>
        </StepsWrapper>
        <ElementWrapper>
          <Alert
            description="The order is scheduled to be deliver on Saturday 12/29/2020"
            type="info"
            showIcon
          />
        </ElementWrapper>
        <ElementWrapper>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13813.224316744845!2d31.4475004!3d30.0567598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51dde24c597a4f14!2sTech%20Village!5e0!3m2!1sen!2sus!4v1630850048253!5m2!1sen!2sus"
            width="100%"
            height={500}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          />
        </ElementWrapper>
      </Container>
    </ContainerWrapper>
  );
};

export default OrderTrackingResult;
