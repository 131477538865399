import { Select, Collapse } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomLink from "../../../components/custom-link";

import {
  CollapseRowLeft,
  CollapseRowWrap,
  CorporateOrdersTabContentCollapseHeader,
  CorporateOrdersTabContentHeader,
  CorporateOrdersTabContentWrap,
} from "../CorporateBuyerDashboardEl";

// Some Styles
const btnStyle = {
  color: "#fff",
  padding: "10px 20px",
  marginRight: 20,
  display: "block",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: ".8rem",
};

const CollapseHeader = () => {
  return (
    <CorporateOrdersTabContentCollapseHeader>
      <div>
        <span>
          <b>Details</b>
        </span>
      </div>
      <div>
        <span>
          <b>Date</b>
        </span>
      </div>
      <div>
        <span>
          <b>Status</b>
        </span>
      </div>
    </CorporateOrdersTabContentCollapseHeader>
  );
};

const CollapseRow = ({ text }) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <CollapseRowWrap>
      <CollapseRowLeft>
        <Link to="/">Product Name (Category)</Link>
      </CollapseRowLeft>
      <div>20 Aug, 2021</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomLink
          to="/"
          text={text}
          style={{ ...btnStyle, backgroundColor: mainColor }}
        />
        <BiDotsVerticalRounded />
      </div>
    </CollapseRowWrap>
  );
};

const CorporateOffersTabContent = () => {
  const { Option } = Select;
  const { Panel } = Collapse;

  const handleChange = value => {
    console.log(`selected ${value}`);
  };

  return (
    <CorporateOrdersTabContentWrap>
      <CorporateOrdersTabContentHeader>
        <h4>Orders</h4>
        <Select
          defaultValue="past-3-month"
          style={{ width: 150 }}
          onChange={handleChange}
        >
          <Option value="past-3-month">Past 3 Month</Option>
          <Option value="past-6-month">Past 6 Month</Option>
          <Option value="past-12-month">Past 12 Month</Option>
        </Select>
      </CorporateOrdersTabContentHeader>
      <div>
        <Collapse style={{ width: "100%" }}>
          <Panel header={<CollapseHeader />} key="1">
            <CollapseRow text="pending" />
          </Panel>
          <Panel header={<CollapseHeader />} key="2">
            <CollapseRow text="refused" />
          </Panel>
          <Panel header={<CollapseHeader />} key="3">
            <CollapseRow text="accepted" />
          </Panel>
          <Panel header={<CollapseHeader />} key="4">
            <CollapseRow text="accepted" />
          </Panel>
          <Panel header={<CollapseHeader />} key="5">
            <CollapseRow text="refused" />
          </Panel>
          <Panel header={<CollapseHeader />} key="6">
            <CollapseRow text="pending" />
          </Panel>
        </Collapse>
      </div>
    </CorporateOrdersTabContentWrap>
  );
};

export default CorporateOffersTabContent;
