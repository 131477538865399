import CategoriesAllPageMightAlsoLike from "../../categories-all-page-might-also-like";
import {
  PopularSearchesTitle,
  PopularSearchesWrap,
  PopularSearchMore,
  PopularSearchName,
  PopularSearchNameWrapper,
} from "./SideBarEl";

import { DownOutlined } from "@ant-design/icons";

const SideBar = ({ product, title }) => {
  return (
    <div>
      <CategoriesAllPageMightAlsoLike product={product} title={title} />
      <PopularSearches />
    </div>
  );
};

const PopularSearches = () => {
  return (
    <PopularSearchesWrap>
      <div>
        <PopularSearchesTitle>Popular Searches</PopularSearchesTitle>
        <PopularSearchNameWrapper>
          <PopularSearchName>Dental Supply</PopularSearchName>
          <PopularSearchName>Dental Supply</PopularSearchName>
          <PopularSearchName>Dental Supply</PopularSearchName>
          <PopularSearchName>Dental Supply</PopularSearchName>
          <PopularSearchName>Dental Supply</PopularSearchName>
          <PopularSearchMore>
            {" "}
            More <DownOutlined />
          </PopularSearchMore>
        </PopularSearchNameWrapper>
      </div>
    </PopularSearchesWrap>
  );
};
export default SideBar;
