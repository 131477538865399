import {
  AiOutlineUser,
  AiOutlineHeart,
  // AiOutlineSetting,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { MdPayment } from "react-icons/md";
import { BiSpreadsheet } from "react-icons/bi";

const customerSwitcherData = [
  {
    title: "Basics Info",
    name: "basics",
    icon: <AiOutlineUser />,
  },
  {
    title: "Address",
    name: "address",
    icon: <GoLocation />,
  },
  {
    title: "Wishlist",
    name: "wishlist",
    icon: <AiOutlineHeart />,
  },
  {
    title: "Orders",
    name: "orders",
    icon: <BiSpreadsheet />,
  },
  // {
  //   title: "Settings",
  //   name: "settings",
  //   icon: <AiOutlineSetting />,
  // },
];

export default customerSwitcherData;
