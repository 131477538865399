import styled from "styled-components";
import { Link } from "react-router-dom";
import { mainColor, screenLg } from "../../constants";
// import { blue, screenLg, altBlue } from "../../constants";

export const SidebarWrapper = styled.aside`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? 0 : "-150%")};
  z-index: 9999;
  width: 100%;
  /* max-width: 350px; */
  height: 100%;
  background-color: #fff;
  justify-content: center;
  transition: 0.7s all ease-in-out;
  box-shadow: 1px 0px 0px 1px #1d9079;
  overflow-y: auto;
  padding: 20px;

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${mainColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5d9c90;
  }

  a:first-of-type {
    margin-top: 40px;
  }
  a {
    font-size: 1rem;
  }

  @media screen and (min-width: ${screenLg}) {
    display: none !important;
  }
`;

export const CloseIcon = styled.div`
  margin-bottom: 30px;
  position: absolute;
  top: 30px;
  right: 20px;

  svg {
    color: ${mainColor};
    font-size: 25px;
    cursor: pointer;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LinkItem = styled(Link)`
  color: #000;
  margin-bottom: 20px;
  font-size: 0.9rem !important;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    color: ${mainColor};
  }
`;

export const BtnWrapper = styled.div`
  margin-top: 30px;
`;

export const SignupLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  background-color: ${mainColor};
  padding: 10px 30px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
  transition: 0.5s ease;
  &:hover {
    background-color: ${mainColor};
  }
`;
