import { AiOutlineShareAlt, AiFillStar } from "react-icons/ai";
import { MdAddShoppingCart, MdDelete } from "react-icons/md";
import {
  CorporateWishlistTabContentHeader,
  CorporateWishlistTabContentRow,
  CorporateWishlistTabContentRowLeft,
  CorporateWishlistTabContentRowLeftStars,
  CorporateWishlistTabContentWrap,
} from "../CorporateBuyerDashboardEl";

const CorporateWishlistTabContent = () => {
  return (
    <CorporateWishlistTabContentWrap>
      <CorporateWishlistTabContentHeader>
        <h4>Wishlist</h4>
      </CorporateWishlistTabContentHeader>
      <CorporateWishlistTabContentRow>
        <CorporateWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <CorporateWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </CorporateWishlistTabContentRowLeftStars>
          </div>
        </CorporateWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </CorporateWishlistTabContentRow>
      <CorporateWishlistTabContentRow>
        <CorporateWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <CorporateWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </CorporateWishlistTabContentRowLeftStars>
          </div>
        </CorporateWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </CorporateWishlistTabContentRow>
      <CorporateWishlistTabContentRow>
        <CorporateWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <CorporateWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </CorporateWishlistTabContentRowLeftStars>
          </div>
        </CorporateWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </CorporateWishlistTabContentRow>
      <CorporateWishlistTabContentRow>
        <CorporateWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <CorporateWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </CorporateWishlistTabContentRowLeftStars>
          </div>
        </CorporateWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </CorporateWishlistTabContentRow>
    </CorporateWishlistTabContentWrap>
  );
};

export default CorporateWishlistTabContent;
