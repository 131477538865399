import { FaRegEnvelope, FaLock } from "react-icons/fa";

const commonStyle = {
  inputWrapper: {
    width: "100%",
  },
  formRow: {
    margin: "12px 0",
  },
};

const formFields = [
  {
    type: "email",
    name: "email",
    placeholder: "Email",
    style: commonStyle,
    icon: <FaRegEnvelope />,
  },
  {
    type: "password",
    name: "password",
    placeholder: "password",
    style: commonStyle,
    icon: <FaLock />,
  },
];
export default formFields;
