import { AppColorSwitcherWrap } from "./AppColorSwitcherEl";
import { Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAppMainColor } from "../../redux/ui/ui.actions";
import { ChromePicker } from "react-color";
import CustomButton from "../custom-button";
import { AiFillSetting } from "react-icons/ai";

const AppColorSwitcher = () => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const [showColorModal, setShowColorModal] = useState(false);
  const [currColor, setCurrColor] = useState(mainColor);
  const dispatch = useDispatch();

  const handleChangeComplete = color => {
    setCurrColor(color.hex);
  };

  return (
    <>
      <AppColorSwitcherWrap
        mainColor={mainColor}
        onClick={() => setShowColorModal(!showColorModal)}
      >
        <CustomButton
          type="button"
          text={<AiFillSetting />}
          style={{
            zIndex: 9999,
            backgroundColor: mainColor,
            border: "none",
          }}
        />
      </AppColorSwitcherWrap>

      <Modal
        title="Pick app color"
        style={{ top: 20 }}
        centered
        visible={showColorModal}
        onOk={() => {
          dispatch(changeAppMainColor(currColor));
          setShowColorModal(false);
        }}
        onCancel={() => setShowColorModal(false)}
        okText="Change Color & Close"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <ChromePicker
            color={currColor}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      </Modal>
    </>
  );
};

export default AppColorSwitcher;
