import React from "react";
import {
  Answer,
  FAQsContent,
  FAQsWrapper,
  SearchForm,
  SearchWrapper,
} from "./FAQsEl";
import { Collapse, Input, Select } from "antd";
const { Panel } = Collapse;
const { Option } = Select;

const ProductFAQs = () => {
  return (
    <FAQsWrapper>
      <SearchWrapper>
        <SearchForm>
          <Input.Group>
            <Input.Search
              allowClear
              size="large"
              placeholder="Have a question? Search for answers"
            />
            <Select defaultValue="Option1" size="large">
              <Option value="Option1">Option1</Option>
              <Option value="Option2">Option2</Option>
            </Select>
          </Input.Group>
        </SearchForm>
      </SearchWrapper>

      <FAQsContent>
        <Collapse defaultActiveKey={["1"]} ghost>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="1"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="2"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="3"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="4"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="5"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="6"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="7"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
          <Panel
            header="Is this iPad just wifi or is it with cellular as well?"
            key="8"
          >
            <Answer>
              Awesome template, it has all the layouts and components I need for
              every web design project, lorem ipsum dolor sit. Lorem ipsum dolor
              sit amet, consectetur adipisicing elitsed. Awesome template, it
              has all the layouts and components I need for every web design
              project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet,
              consectetur adipisicing elitsed.
            </Answer>
          </Panel>
        </Collapse>
      </FAQsContent>
    </FAQsWrapper>
  );
};

export default ProductFAQs;
