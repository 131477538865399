const checkoutType = {
  CHECKOUT_GET_ADDRESSES_REQUEST: "CHECKOUT_GET_ADDRESSES_REQUEST",
  CHECKOUT_GET_ADDRESSES_SUCCESS: "CHECKOUT_GET_ADDRESSES_SUCCESS",
  CHECKOUT_GET_ADDRESSES_ERROR: "CHECKOUT_GET_ADDRESSES_ERROR",

  CHECKOUT_SAVE_ADDRESS_REQUEST: "CHECKOUT_SAVE_ADDRESS_REQUEST",
  CHECKOUT_SAVE_ADDRESS_SUCCESS: "CHECKOUT_SAVE_ADDRESS_SUCCESS",
  CHECKOUT_SAVE_ADDRESS_FAILED: "CHECKOUT_SAVE_ADDRESS_FAILED",
  // CHECKOUT_SHIPPING_METHOD: 'CHECKOUT_SHIPPING_METHOD',
  // CHECKOUT_PAYMENT_METHOD: 'CHECKOUT_PAYMENT_METHOD',
  // CHECKOUT_ORDER_NOW: 'CHECKOUT_ORDER_NOW',
};

export default checkoutType;
