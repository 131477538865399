import React, { useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ProductDetailsMainGallery from "../gallery";
import ProductDetailsMainDetails from "../details";
import ProductDetailsMainNav from "../nav";
import ProductNavBody from "../nav-body";
import {
  MainWrapper,
  UpperWrapper,
  ProductNavBodyWrapper,
} from "./ProductDetailsMain";

const ProductDetailsMain = ({ product }) => {
  const [currentTab, setCurrentTab] = useState("description");
  return (
    <MainWrapper>
      <UpperWrapper>
        <ProductDetailsMainGallery product={product} />
        <ProductDetailsMainDetails product={product} />
      </UpperWrapper>
      <ProductDetailsMainNav
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <ProductNavBodyWrapper>
        <ProductNavBody currentTab={currentTab} />
      </ProductNavBodyWrapper>
    </MainWrapper>
  );
};

export default ProductDetailsMain;
