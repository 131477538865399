import { FiEdit2 } from "react-icons/fi";
import {
  VendorBasicsInfoTabContentWrap,
  VendorBasicsInfoTabContentHeader,
} from "../VendorBuyerDashboardEl.js";

const VendorBasicsInfoTabContent = () => {
  return (
    <VendorBasicsInfoTabContentWrap>
      <VendorBasicsInfoTabContentHeader>
        <h4>Basics Info</h4>
      </VendorBasicsInfoTabContentHeader>
      <div>
        <div>
          <h5>First Name:</h5> <span>Toni</span>
          <h5>Second Name:</h5> <span>Stark</span>
        </div>
        <FiEdit2 />
      </div>
      <div>
        <div>
          <h5>Username:</h5> <span>Toni Stark</span>
        </div>
        <FiEdit2 />
      </div>
      <div>
        <div>
          <h5>Email:</h5> <span>stark@toni.com</span>
        </div>
        <FiEdit2 />
      </div>
      <div>
        <div>
          <h5>Mobile Number:</h5> <span>1-948-5039-7484</span>
        </div>
        <FiEdit2 />
      </div>
      <div>
        <div>
          <h5>Password:</h5> <span>***********</span>
        </div>
        <FiEdit2 />
      </div>
      <div>
        <div>
          <h5>Two-Step Verification:</h5>{" "}
          <span>Manage your two step verification authentication</span>
        </div>
        <FiEdit2 />
      </div>
    </VendorBasicsInfoTabContentWrap>
  );
};

export default VendorBasicsInfoTabContent;
