import { message, Rate } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../button";
import { CircleButton } from "../../common-styled-componnts";
import { ReactComponent as TradeSVG } from "../../../assets/images/trade-assurance.svg";

import {
  DetailsBadge,
  DetailsSectionWrapper,
  DetailsTitle,
  DetailsWrapper,
  FilterName,
  PriceWrapper,
  RateDescription,
  FiltersWrapper,
  Qty,
} from "./DetailsEl";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import CustomButton from "../../custom-button";
import { useDispatch, useSelector } from "react-redux";
import RichTextContainer from "../../rich-text-container";
import ProductDetailsAttributes from "./attributes";
import {
  AiOutlineDollarCircle,
  AiOutlineBarcode,
  AiFillStar,
} from "react-icons/ai";
import { BiCheckCircle } from "react-icons/bi";
import { FaShippingFast } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { addProductToCompare } from "../../../redux/compare/compare.actions";
import { getItemsFromCartAction } from "../../../redux/cart/cart.actions";
import api from "../../../api";
import { localCartAdd } from "../../../redux/localCart/localCart.actions";

const ProductDetailsMainDetails = ({ product }) => {
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  const path = useSelector(
    (state) => state.homepage?.data?.featuredProducts?.path
  );
  const productPrice =
    parseInt(product?.offer_price) === 0
      ? product?.price
      : product?.offer_price;
  const { mainColor } = useSelector(({ ui }) => ui);
  const [loading, setLoading] = React.useState(false);
  const {
    data: { brand, product: productData, formattedPrice },
  } = useSelector(({ product }) => product);
  const [currentAttributes, setCurrentAttributes] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (productData?.type === "configurable")
      setCurrentAttributes(productData.variants.index[0]);
  }, [productData]);
  const [quantity, setQuantity] = React.useState(1);

  const handleAddClick = () => {
    setLoading(true);
    api
      .post(`/addtocart`, { token, pro_id: product?.id, quantity })
      .then(() => {
        setLoading(false);
        dispatch(getItemsFromCartAction(token));
        message.success("Item has been added to cart successfully");
      });
  };

  const handleLocalAdd = () => {
    dispatch(
      localCartAdd({
        quantity: quantity,
        item: {
          image: product?.image1,
          productPrice,
          name: product?.name?.en,
          id: product?.id,
          path,
        },
      })
    );
    message.success("Item has been added to cart");
  };

  return (
    <DetailsWrapper>
      {brand && (
        <DetailsBadge mainColor={mainColor}>{brand.value}</DetailsBadge>
      )}
      <DetailsTitle>{product?.name?.en}</DetailsTitle>
      <DetailsSectionWrapper>
        <PriceWrapper>{formattedPrice}</PriceWrapper>
        <DetailsSectionWrapper>
          <Rate value="4" disabled />
          <RateDescription>
            {/* {productData.reviews.average_rating} ( */}
            {/* {productData.reviews.total_rating} votes) */}
          </RateDescription>
        </DetailsSectionWrapper>
      </DetailsSectionWrapper>
      <DetailsSectionWrapper>
        {/* <RichTextContainer richText={productData.short_description} /> */}
      </DetailsSectionWrapper>

      {"" === "configurable" && currentAttributes && (
        <ProductDetailsAttributes
          currentAttributes={currentAttributes}
          setCurrentAttributes={setCurrentAttributes}
          // attributes={productData.variants.attributes}
        />
      )}
      <div style={{ width: 420 }}>
        {/* <div className="all-price">
          <div className="price">
            <div className="Quantity">
              <span> 1 - 19 Pieces</span> <br />
              452.26 EGP
            </div>
          </div>
          <div className="price">
            <div className="Quantity">
              <span>20 - 99 Pieces</span> <br />
              442.20 EGP
            </div>
          </div>
          <div className="price">
            <div className="Quantity">
              <span> {">= 100 Pieces"}</span> <br />
              422.16 EGP
            </div>
          </div>
        </div> */}

        {/* <div className="leadtime-shipping">
          <div className="lead-time">
            <div className="left">lead time:</div>
            <div className="right">
              <div className="leattime-table">
                <table>
                  <tr>
                    <th>Quantity (Prices)</th>
                    <th>1 - 50</th>
                    <th> {"> 50"}</th>
                  </tr>
                  <tr>
                    <td>Est. time(days)</td>
                    <td>7</td>
                    <td>to be negotated</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div> */}

        <div className="protection">
          <div className="protection-left">Protection:</div>
          <div className="protection-right">
            <p>
              <TradeSVG style={{ width: "1rem", marginRight: 2 }} />
              {/* <span> Trade Assurance</span> */}
            </p>
            <p>
              <BiCheckCircle style={{ marginLeft: 10 }} /> Refund Policy
            </p>
          </div>
        </div>

        <div className="shipping">
          <div className="shipping-left">
            <FaShippingFast /> Delivery period:{" "}
          </div>
          <div className="shipping-right">3 Days</div>
        </div>
        <div className="shipping">
          <div className="shipping-left">
            <AiOutlineBarcode /> Vendor's Code:{" "}
          </div>
          <Link to="/" className="shipping-right">
            s-4010-af63-5511caef8a03
          </Link>
        </div>
        <div className="shipping">
          <div className="shipping-left">
            <AiFillStar />
            Vendor's Overall Rating:{" "}
          </div>
          <div className="shipping-right">3.5</div>
        </div>
        <div className="vendor-notes-wrap">
          <div className="shipping-left">
            <CgNotes /> Vendor’s Note:{" "}
          </div>
          <ul className="vendor-notes">
            <li>Allow you to manage reviews </li>
            <li>Help you request user reviews via email</li>
            <li>Support message scheduling</li>
          </ul>
        </div>
        <div
          style={{ color: mainColor, cursor: "pointer" }}
          onClick={() => dispatch(addProductToCompare(productData))}
        >
          Compare
        </div>
      </div>

      <DetailsSectionWrapper justifyContent="flex-start" margin="15px 0 0 0">
        <FilterName>QTY</FilterName>
        <FiltersWrapper>
          <DetailsSectionWrapper>
            <CircleButton
              onClick={() =>
                quantity === 1 ? null : setQuantity(quantity - 1)
              }
              mainColor={mainColor}
            >
              -
            </CircleButton>
            <Qty>{quantity}</Qty>
            <CircleButton
              onClick={() =>
                quantity === 20 ? null : setQuantity(quantity + 1)
              }
              mainColor={mainColor}
            >
              +
            </CircleButton>
          </DetailsSectionWrapper>
        </FiltersWrapper>
      </DetailsSectionWrapper>
      <DetailsSectionWrapper justifyContent="flex-start" margin="32px 0 0 0">
        <Button
          onClick={() => (token ? handleAddClick() : handleLocalAdd())}
          type="submit"
          margin="0 12px 0 0"
        >
          Add to Cart
        </Button>
        <CustomButton
          type="button"
          text="Add to Wishlist"
          style={{ padding: 10, borderRadius: 5 }}
          isHover
        >
          <HeartOutlined style={{ marginRight: "6px" }} />
        </CustomButton>
      </DetailsSectionWrapper>
    </DetailsWrapper>
  );
};

export default ProductDetailsMainDetails;
