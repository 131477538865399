import api from "../../api";
import productsTypes from "./products.types";

export const fetchAllProductsAction = (text = null) => {
  return async dispatch => {
    dispatch({
      type: productsTypes.FETCH_PRODUCTS_REQUEST,
    });
    try {
      const { data } = await api.get(`/products?token=true&name=${text}`);
      console.log(data);

      dispatch({
        type: productsTypes.FETCH_PRODUCTS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: productsTypes.FETCH_PRODUCTS_ERROR,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};
