import {
  CategoriesAllPageMightAlsoLikeCategory,
  CategoriesAllPageMightAlsoLikeImg,
  CategoriesAllPageMightAlsoLikeName,
  CategoriesAllPageMightAlsoLikePrice,
  CategoriesAllPageMightAlsoLikeProduct,
  CategoriesAllPageMightAlsoLikeTitle,
  CategoriesAllPageMightAlsoLikeWrap,
} from "./CategoriesAllPageMightAlsoLikeEl";
import CategoriesAllPageSimilarProducts from "../categories-all-page-similar";
import mightAlsoLike from "./might-also-like";

export const CategoriesAllPageMightAlsoLikeItem = ({ name, imgSrc }) => {
  return (
    <CategoriesAllPageMightAlsoLikeProduct>
      <CategoriesAllPageMightAlsoLikeImg to="/">
        <img src={imgSrc} alt="" />
      </CategoriesAllPageMightAlsoLikeImg>
      <div>
        <CategoriesAllPageMightAlsoLikeName to="/">
          {name.length > 25 ? `${name.substring(0, 25)}...` : name}
        </CategoriesAllPageMightAlsoLikeName>
        <CategoriesAllPageMightAlsoLikeCategory>
          Dental Equipment
        </CategoriesAllPageMightAlsoLikeCategory>
        <CategoriesAllPageMightAlsoLikePrice>
          30,000.00 EGP
        </CategoriesAllPageMightAlsoLikePrice>
      </div>
    </CategoriesAllPageMightAlsoLikeProduct>
  );
};

const CategoriesAllPageMightAlsoLike = ({ product, title }) => {
  return (
    <div>
      {/* <CategoriesAllPageMightAlsoLikeWrap>
        <CategoriesAllPageMightAlsoLikeTitle>
          {title ?? "You Might Also Like"}
        </CategoriesAllPageMightAlsoLikeTitle>
        {mightAlsoLike.map((item, idx) => (
          <CategoriesAllPageMightAlsoLikeItem key={idx} {...item} />
        ))}
      </CategoriesAllPageMightAlsoLikeWrap> */}

      <CategoriesAllPageSimilarProducts product={product} />
    </div>
  );
};

export default CategoriesAllPageMightAlsoLike;
