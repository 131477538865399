import React from "react";
import { Container } from "../../constants";
import CategoriesSidebar from "../categories-sidebar";
import HomepageCarousel from "../homepage-carousel";
import YouMayLike from "../you-may-like";
import { HomePageHeroContent, HomePageHeroWrap } from "./HomePageHeroEl";
import { Skeleton } from "antd";

const HomepageHeroSection = ({ data }) => {
  const [catId, setCatId] = React.useState(2000000000);

  if (!data) {
    return (
      <div
        className="Skeleton"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "30px",
          height: "400px",
        }}
      >
        <div>
          <div>
            <Skeleton.Input style={{ width: 260 }} active size="small" />
            <br />
            <br />
            <Skeleton.Input style={{ width: 260 }} active size="small" />
            <br />
            <br />
            <Skeleton.Input style={{ width: 260 }} active size="small" />
            <br />
            <br />
            <Skeleton.Input style={{ width: 260 }} active size="small" />
            <br />
            <br />
            <Skeleton.Input style={{ width: 260 }} active size="small" />
            <br />
            <br />
            <Skeleton.Input style={{ width: 260 }} active size="small" />
          </div>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <div>
            <Skeleton.Image style={{ width: 1100, height: 255 }} />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  console.log(catId);

  return (
    <>
      <HomePageHeroWrap>
        <Container>
          <HomePageHeroContent>
            <CategoriesSidebar setCatId={setCatId} />
            <HomepageCarousel setCatId={setCatId} catId={catId} data={data} />
            {/* <YouMayLike /> */}
          </HomePageHeroContent>
        </Container>
      </HomePageHeroWrap>
    </>
  );
};

export default HomepageHeroSection;
