import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mainColor, screenMLg, secColor } from '../../constants';

export const CategoriesSidebarWrap = styled.div`
  border: 1px solid ${({ mainColor }) => mainColor};
  height: fit-content;
  height: 400px;
  overflow-y: auto;
  /* overflow-x: hidden; */
  position: relative;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ mainColor }) => mainColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5d9c90;
  }

  @media screen and (max-width: ${screenMLg}) {
    display: none;
  }
`;

export const CategoriesSidebarContainer = styled.div`
  position: relative;

  &:hover {
    .hover-mega-menu {
      transform: translateX(0);
      display: block !important;
    }
  }
`;

export const CategoriesSidebarHeader = styled.div`
  background-color: ${({ mainColor }) => mainColor};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: ${secColor};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  svg {
    margin-left: 10px;
    font-size: 20px;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: translate(10px);
    }
  }
`;

export const CategoriesMenuItem = styled(Link)`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  &:hover {
    svg {
      transform: translate(10px);
    }
  }

  span {
    color: #000;
    margin-right: 20px;
    font-size: 0.8rem;
  }

  svg {
    color: ${mainColor};
    font-size: 1rem;
    transition: 0.2s ease-in-out;
  }
`;
