import { Modal } from 'antd';
import { Input } from 'antd';
import { Checkbox } from 'antd';
import './index.css';
import CustomButton from './../custom-button/index';
import PopupIconComp from '../PopupIconComp';
import { changeShowPopup } from './../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';

const HomePopup = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();

  return (
    <div className="container-homepage-popup">
      <div className="popup">
        <Modal centered visible={showModal} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)} footer={null} width={700} bodyStyle={{ height: 400 }}>
          <div className="popup-container pc">
            <div className="left-side">
              <PopupIconComp />
            </div>
            <div className="right-side">
              <div>
                <h3> Subscribe Now! </h3>
              </div>

              <div className="popup-form">
                <Input placeholder="example@email.com" />
                {/* <Button type="primary">send</Button> */}
                <CustomButton text="Send" style={{ padding: '5px 0' }} />
                <Checkbox
                  onChange={(e) => {
                    dispatch(changeShowPopup(!e.target.checked));
                  }}
                >
                  Don't show again
                </Checkbox>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default HomePopup;
