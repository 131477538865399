const cartTypes = {
  GET_CART_ITEMS_REQUEST: "GET_CART_ITEMS_REQUEST",
  GET_CART_ITEMS_SUCCESS: "GET_CART_ITEMS_SUCCESS",
  GET_CART_ITEMS_FAILED: "GET_CART_ITEMS_FAILED",

  CART_ADD_REQUEST: "CART_ADD_REQUEST",
  CART_ADD_SUCCESS: "CART_ADD_SUCCESS",
  CART_ADD_FAILED: "CART_ADD_FAILED",

  CART_REMOVE_ITEM_REQUEST: "CART_REMOVE_ITEM_REQUEST",
  CART_REMOVE_ITEM_SUCCESS: "CART_REMOVE_ITEM_SUCCESS",
  CART_REMOVE_ITEM_FAILED: "CART_REMOVE_ITEM_FAILED",

  REMOVE_ITEM_FROM_CART_LOCAL: "REMOVE_ITEM_FROM_CART_LOCAL",
};

export default cartTypes;
