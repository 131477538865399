import { AiOutlinePlus } from "react-icons/ai";
import { SiAmericanexpress } from "react-icons/si";
import { FaCcMastercard, FaCcDiscover } from "react-icons/fa";
import {
  CorporatePaymentTabContentHeader,
  CorporatePaymentTabContentRow,
  CorporatePaymentTabContentWrap,
} from "../CorporateBuyerDashboardEl";
import { FaCcVisa } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";

const CorporatePaymentTabContent = () => {
  return (
    <CorporatePaymentTabContentWrap>
      <CorporatePaymentTabContentHeader>
        <h4>Payments</h4>
        <AiOutlinePlus />
      </CorporatePaymentTabContentHeader>
      <CorporatePaymentTabContentRow>
        <div>
          <FaCcVisa />
          <span>isa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CorporatePaymentTabContentRow>
      <CorporatePaymentTabContentRow>
        <div>
          <SiAmericanexpress />
          <span>isa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CorporatePaymentTabContentRow>
      <CorporatePaymentTabContentRow>
        <div>
          <FaCcMastercard />
          <span>isa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CorporatePaymentTabContentRow>
      <CorporatePaymentTabContentRow>
        <div>
          <FaCcDiscover />
          <span>isa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CorporatePaymentTabContentRow>
    </CorporatePaymentTabContentWrap>
  );
};

export default CorporatePaymentTabContent;
