import api from "../../api";
import reviewsTypes from "./reviews.types";

export const fetchReviewsAction = (productId) => {
  return async (dispatch) => {
    dispatch({
      type: reviewsTypes.FETCH_REVIEWS_REQUEST,
    });
    api
      .get(`/reviews`, { params: { product_id: productId } })
      .then(({ data }) =>
        dispatch({
          type: reviewsTypes.FETCH_REVIEWS_SUCCESS,
          payload: data.data,
        })
      )
      .catch((err) => {
        dispatch({
          type: reviewsTypes.FETCH_REVIEWS_ERROR,
          payload:
            err.response && err.response.data.error
              ? err.response.data.error
              : err.message,
        });
      });
  };
};

export const addReviewsAction = (reviewData) => {
  return async (dispatch) => {
    dispatch({
      type: reviewsTypes.ADD_REVIEWS_REQUEST,
    });
    api
      .post(`/reviews`, reviewData)
      .then(({ data }) =>
        dispatch({
          type: reviewsTypes.ADD_REVIEWS_SUCCESS,
          payload: data.data,
        })
      )
      .catch((err) => {
        dispatch({
          type: reviewsTypes.ADD_REVIEWS_ERROR,
          payload:
            err.response && err.response.data.error
              ? err.response.data.error
              : err.message,
        });
      });
  };
};
