import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RenderCheckoutSwitcher } from "..";
import api from "../../../api";
import AlertPopup from "../../../components/alert-popup";
import CustomButton from "../../../components/custom-button";
import {
  CheckoutOrderNowPaymentMethodWrap,
  CheckoutOrderNowRow,
  CheckoutOrderNowRowWrap,
} from "../CheckoutPageEl";

const CheckoutOrderNow = () => {
  const [showPopupModal, setShowPopupModal] = useState();
  const user = useSelector((state) => state?.buyer?.data?.user_data);
  const cart = useSelector((state) => state?.cart?.cartItems);
  const addresses = useSelector((state) => state?.address?.data);
  const { addressId, paymentMethod } = useParams();
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  const history = useHistory();
  const choosenAddress = addresses?.find(
    (address) => address?.id === parseInt(addressId)
  );

  const confirmOrder = () => {
    api
      .post(`checkout`, {
        payment_method: paymentMethod,
        address_id: addressId,
        mobile: user?.mobile,
        token: token,
      })
      .then((res) => {
        message.success("Order has been placed successfully");
        history.push("/");
      })
      .catch((err) => console.log("err"));
  };

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // });

  return (
    <div className="formwrapper">
      <RenderCheckoutSwitcher step1 step2 step3 step4 />
      <h1 style={{ marginTop: 30 }}>Complete checkout and place order now</h1>
      <CheckoutOrderNowRowWrap>
        <CheckoutOrderNowRow>
          <h2>Billing Info</h2>
          <div>
            <label>Name</label>
            <h5>{user?.name}</h5>
          </div>
          <div>
            <label>Email</label>
            <h5>{user?.email}</h5>
          </div>
          <div>
            <label>Billing Address</label>
            <h5 style={{ maxWidth: "250px" }}>
              {choosenAddress?.address_details}
            </h5>
          </div>
          {/* <div>
            <label>Tax</label>
            <h5>14%</h5>
          </div> */}
          <div>
            <label>Total Amount</label>
            <h5>{cart ? cart["total price"] : null} EGP</h5>
          </div>
        </CheckoutOrderNowRow>

        <CheckoutOrderNowRow>
          <h2>Shipping Info</h2>
          <div>
            <label>First Name</label>
            <h5>John</h5>
          </div>
          <div>
            <label>Last Name</label>
            <h5>Doe</h5>
          </div>
          <div>
            <label>Email</label>
            <h5>john@doe.com</h5>
          </div>
          <div>
            <label>Shipping Address</label>
            <h5>D3 - University 0243</h5>
          </div>
        </CheckoutOrderNowRow>
      </CheckoutOrderNowRowWrap>
      <CheckoutOrderNowPaymentMethodWrap>
        {/* <div>
          <h2>Shipping Method</h2>
          <div>
            <label>Selected Shipping Method</label>
            <h5>Government Shipping</h5>
          </div>
        </div> */}
        <div>
          <h2>Payment Method</h2>
          <div>
            <label>Selected Payment Method</label>
            <h5>Cash on Delivery</h5>
          </div>
        </div>
      </CheckoutOrderNowPaymentMethodWrap>
      <CustomButton
        onClick={confirmOrder}
        text="Confirm Order"
        style={{
          padding: "13px 30px",
          marginTop: 40,
          fontWeight: 500,
        }}
      />

      <AlertPopup
        showPopupModal={showPopupModal}
        setShowPopupModal={setShowPopupModal}
        is_success
      />
    </div>
  );
};

export default CheckoutOrderNow;
