import { useSelector } from "react-redux";

const PopupIconComp = () => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <svg
      id="b9d164a0-f9aa-42f6-aed8-a14c053e9183"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="883.96828"
      height="607.46406"
      viewBox="0 0 883.96828 607.46406"
    >
      <path
        d="M930.734,274.34051h-.12466l-71.4901,30.52657-97.81319,41.767a3.15484,3.15484,0,0,1-2.41645.01245L657.97519,304.77988l-73.2464-30.38956-.1119-.04981h-.12467a4.364,4.364,0,0,0-4.35916,4.35916V468.01173a4.364,4.364,0,0,0,4.35916,4.35916H930.734a4.364,4.364,0,0,0,4.35916-4.35916V278.69967A4.364,4.364,0,0,0,930.734,274.34051Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#fff"
      />
      <path
        d="M931.04481,275.586a.62087.62087,0,0,1-.35546-.1116L758.46588,155.53305a3.12331,3.12331,0,0,0-3.56736.00608L583.91566,275.47318a.62286.62286,0,0,1-.71532-1.01986l170.983-119.934a4.373,4.373,0,0,1,4.99436-.00821l172.22344,119.941a.62286.62286,0,0,1-.35638,1.13388Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#3f3d56"
      />
      <polygon
        points="435.67 133.57 598.834 12.645 763.237 142.174 607.241 234.65 522.549 215.346 435.67 133.57"
        fill="#e6e6e6"
      />
      <path
        d="M687.71423,438.03708h-80.6073a3.76592,3.76592,0,0,1-.01113-7.53184h80.61843a3.76592,3.76592,0,0,1,.01113,7.53184Z"
        transform="translate(-158.01586 -146.26797)"
        fill={mainColor}
      />
      <path
        d="M636.027,421.84591h-28.9201a3.76592,3.76592,0,0,1-.01113-7.53183H636.027a3.76592,3.76592,0,1,1,.01114,7.53183Z"
        transform="translate(-158.01586 -146.26797)"
        fill={mainColor}
      />
      <path
        d="M759.145,347.19121a4.7102,4.7102,0,0,1-1.78672-.35L656.72972,305.08743V174.39112a4.36425,4.36425,0,0,1,4.35915-4.35916H854.13736a4.36426,4.36426,0,0,1,4.35916,4.35916V305.17865l-.18913.08089-97.32152,41.56129A4.74288,4.74288,0,0,1,759.145,347.19121Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#fff"
      />
      <path
        d="M759.145,347.50227a5.0265,5.0265,0,0,1-1.90531-.37279L656.41835,305.2954V174.39109a4.67581,4.67581,0,0,1,4.67052-4.67052H854.13736a4.67582,4.67582,0,0,1,4.67053,4.67052V305.38421L761.10811,347.107A5.05005,5.05005,0,0,1,759.145,347.50227ZM657.66378,304.46348,757.71464,345.978a3.81,3.81,0,0,0,2.90695-.01733L857.56241,304.562V174.39109a3.42912,3.42912,0,0,0-3.42505-3.425H661.08887a3.42912,3.42912,0,0,0-3.42505,3.425Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#3f3d56"
      />
      <path
        d="M929.79979,274.34051h-.12466L858.185,304.86708l-97.81319,41.767a3.15484,3.15484,0,0,1-2.41645.01245L657.041,304.77988l-73.2464-30.38956-.1119-.04981H583.558a4.364,4.364,0,0,0-4.35916,4.35916V468.01173a4.364,4.364,0,0,0,4.35916,4.35916H929.79979a4.364,4.364,0,0,0,4.35916-4.35916V278.69967A4.364,4.364,0,0,0,929.79979,274.34051Zm3.11369,193.67122a3.11492,3.11492,0,0,1-3.11369,3.11369H583.558a3.11494,3.11494,0,0,1-3.11369-3.11369V278.69967a3.12057,3.12057,0,0,1,2.99541-3.11368L657.041,306.125l100.4352,41.67357a4.43441,4.43441,0,0,0,3.38765-.01868L858.185,306.21844,929.92446,275.586a3.12378,3.12378,0,0,1,2.989,3.11368Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#3f3d56"
      />
      <path
        d="M743.47888,218.88666H674.9778a4.9819,4.9819,0,0,1,0-9.96379h68.50108a4.9819,4.9819,0,0,1,0,9.96379Z"
        transform="translate(-158.01586 -146.26797)"
        fill={mainColor}
      />
      <path
        d="M707.98287,197.7136H674.9778a4.98189,4.98189,0,0,1,0-9.96379h33.00507a4.98189,4.98189,0,0,1,0,9.96379Z"
        transform="translate(-158.01586 -146.26797)"
        fill={mainColor}
      />
      <path
        d="M808.84666,268.70562H704.22683a4.9819,4.9819,0,0,1,0-9.96379H808.84666a4.9819,4.9819,0,0,1,0,9.96379Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#ccc"
      />
      <path
        d="M808.84666,291.7469H704.22683a4.9819,4.9819,0,0,1,0-9.9638H808.84666a4.9819,4.9819,0,0,1,0,9.9638Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#ccc"
      />
      <circle cx="769.85304" cy="129.87784" r="47.37136" fill={mainColor} />
      <polygon
        points="765.339 149.875 751.161 131.644 759.406 125.231 766.119 133.863 788.798 109.922 796.382 117.107 765.339 149.875"
        fill="#fff"
      />
      <path
        d="M397.78109,556.12072a10.05581,10.05581,0,0,1,4.214-14.83234l-3.08079-35.60179,16.326,8.84847.42262,32.45151a10.11027,10.11027,0,0,1-17.8818,9.13415Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#9f616a"
      />
      <path
        d="M400.87455,533.59824l-.13257-.33593-18.01172-45.50293-2.85791-86.25293.48-.03418a26.46617,26.46617,0,0,1,28.32056,25.11328l2.6416,46.374,7.48852,59.64258Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <polygon
        points="127.8 577.505 138.154 584.07 168.401 547.255 153.119 537.567 127.8 577.505"
        fill="#9f616a"
      />
      <path
        d="M285.31764,718.7182l20.39154,12.92748.00083.00052a15.3873,15.3873,0,0,1,4.756,21.23348l-.26773.42227-33.38677-21.16611Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#2f2e41"
      />
      <polygon
        points="225.692 595.15 237.952 595.149 243.784 547.861 225.69 547.862 225.692 595.15"
        fill="#9f616a"
      />
      <path
        d="M380.58082,737.41524l24.144-.001h.001a15.38731,15.38731,0,0,1,15.38648,15.38623v.5l-39.53077.00146Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#2f2e41"
      />
      <path
        d="M349.01908,543.28431,377.8814,716.88646s-5.67783,14.19459,2.83892,16.0872,27.44285-1.89261,27.44285-1.89261,5.67784-14.19459-2.83891-16.0872l-16.0872-181.38868Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#2f2e41"
      />
      <path
        d="M366.05258,546.38029l-19.99536,81.17409-50.97755,74.02s-18.92611,1.89262-8.51675,12.302S301.70381,725.232,301.70381,725.232s16.08719-1.89261,15.14088-9.463l62.67343-73.77085,29.11821-98.45675Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#2f2e41"
      />
      <path
        d="M348.60331,553.52891l-4.82886-27.55762c-7.272-7.60059,6.46826-26.3916,8.00024-28.43262l-.89892-11.68554a3.50739,3.50739,0,0,1-1.99268-1.085c-4.511-4.60058-4.208-22.751-4.19287-23.52148L339.712,409.46152l12.38574-7.22558,31.322-1.00977L398.7161,414.483l14.73267,135.35156-.52539.0293Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <path
        d="M359.44328,563.19434a10.05575,10.05575,0,0,1-2.443-15.22456l-17.82547-30.97147,18.53545,1.12735,14.08568,29.23819a10.11028,10.11028,0,0,1-12.35262,15.83049Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#9f616a"
      />
      <path
        d="M343.65751,534.233,328.281,498.58945l-2.56982-72.81152a13.09764,13.09764,0,0,1,6.60693-11.8623l17.73877-10.83985,3.82715,76.544L365.3306,525.3668Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <circle cx="213.23836" cy="226.04429" r="22.00839" fill="#9f616a" />
      <path
        d="M379.212,339.81577c-22.1648-6.39172-28.4563,11.97907-28.4563,11.97907-10.68384,7.08807-3.99487,15.97473-3.99487,15.97473-2.6958.59881,2.69433,16.37457,4.4917,15.97534.959-.21307,3.425,2.73059,5.5166,5.52228a11.0696,11.0696,0,0,1,2.13672.20538l-4.05811-15.01288s13.67871-2.09564,17.87256-8.68506c4.01245-6.30419,17.22119-8.29455,20.98022-4.08361a9.50485,9.50485,0,0,1,1.34058-3.40387,6.80081,6.80081,0,0,1,1.971-1.97608C393.68851,342.18711,379.212,339.81577,379.212,339.81577Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#2f2e41"
      />
      <polyline
        points="196.224 305.464 194.996 349.275 210.238 381.401 196.224 304.299"
        opacity="0.2"
      />
      <path
        d="M540.01586,753.732h-381a1,1,0,1,1,0-2h381a1,1,0,0,1,0,2Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#3f3d56"
      />
      <path
        d="M487.35223,193.54645a1.99891,1.99891,0,0,0-2.99778,1.99087l1.54384,11.32333a1.29955,1.29955,0,0,1-.03357.49905,1.22138,1.22138,0,0,1-.23734.43608l-8.64673,10.24363a1.95391,1.95391,0,0,0-.12721,2.41351l.00908.01314a1.95123,1.95123,0,0,0,2.28266.752l6.24446-2.1195a1.20226,1.20226,0,0,1,1.20761.27169,1.16219,1.16219,0,0,1,.37242.85434l.24409,22.24892a1.984,1.984,0,0,0,.87415,1.62894,2.004,2.004,0,0,0,1.83248.2134l46.6071-17.82452a1.99756,1.99756,0,0,0,.30651-3.58642l-.63455-.37689-48.84721-28.98158Zm-2.20874,1.88208a1.20067,1.20067,0,0,1,1.8019-1.19268L535.777,223.20879l.65006.38537a1.20112,1.20112,0,0,1-.18261,2.151l-46.60706,17.82454a1.19819,1.19819,0,0,1-1.62406-1.10553l-.244-22.24873a1.91512,1.91512,0,0,0-.1118-.646l47.45031,5.45193a.79968.79968,0,0,0,.47957-1.49692.69588.69588,0,0,0-.125-.0544L486.7016,207.1936a1.97024,1.97024,0,0,0-.01428-.44177l-1.54385-11.32329Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#f1f1f1"
      />
      <path
        d="M487.35223,193.54645a1.99891,1.99891,0,0,0-2.99778,1.99087l1.54384,11.32333a1.29955,1.29955,0,0,1-.03357.49905,1.22138,1.22138,0,0,1-.23734.43608l-8.64673,10.24363a1.95391,1.95391,0,0,0-.12721,2.41351l.00908.01314a1.95123,1.95123,0,0,0,2.28266.752l6.24446-2.1195a1.20226,1.20226,0,0,1,1.20761.27169,1.16219,1.16219,0,0,1,.37242.85434l.24409,22.24892a1.984,1.984,0,0,0,.87415,1.62894,2.004,2.004,0,0,0,1.83248.2134l46.6071-17.82452a1.99756,1.99756,0,0,0,.30651-3.58642l-.63455-.37689-48.84721-28.98158Zm-2.20874,1.88208a1.20067,1.20067,0,0,1,1.8019-1.19268L535.777,223.20879l.65006.38537a1.20112,1.20112,0,0,1-.18261,2.151l-46.60706,17.82453a1.19819,1.19819,0,0,1-1.62405-1.10553l-.24405-22.24875a1.91516,1.91516,0,0,0-.1118-.646,1.94312,1.94312,0,0,0-.5119-.78227c-.03153-.03154-.06634-.06083-.10123-.09025l-.00222-.00321a1.99249,1.99249,0,0,0-1.90795-.35062l-6.24773,2.12177a1.16242,1.16242,0,0,1-1.36924-.45629l-.00455-.00658a1.15986,1.15986,0,0,1,.07714-1.44692l8.64685-10.24347a1.88917,1.88917,0,0,0,.23875-.3498l.0033-.00226a1.91585,1.91585,0,0,0,.15205-.37685,1.857,1.857,0,0,0,.07088-.389,1.97017,1.97017,0,0,0-.01428-.44176l-1.54386-11.32332Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <path
        d="M486.70158,207.19358,535.46233,223.47a.69687.69687,0,0,1,.125.05439.79969.79969,0,0,1-.47957,1.49693l-47.45032-5.452-.83795-.09542.09278-.79248.1298.01218.00222.0032,48.154,5.5288-48.71965-16.26619-.0033.00226-.21056-.07271.25288-.75767Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <path
        d="M1031.1283,146.54645,982.28109,175.528l-.63455.37689a1.99756,1.99756,0,0,0,.30651,3.58642l46.6071,17.82452a2.004,2.004,0,0,0,1.83248-.2134,1.984,1.984,0,0,0,.87415-1.62894l.24409-22.24892a1.16219,1.16219,0,0,1,.37242-.85434,1.20226,1.20226,0,0,1,1.20761-.27169l6.24446,2.1195a1.95123,1.95123,0,0,0,2.28266-.752l.00908-.01314a1.95391,1.95391,0,0,0-.12721-2.41351l-8.64673-10.24363a1.22138,1.22138,0,0,1-.23734-.43608,1.29956,1.29956,0,0,1-.03358-.49905l1.54385-11.32333a1.99891,1.99891,0,0,0-2.99778-1.99087Zm2.20877,1.88209-1.54385,11.32329a1.97024,1.97024,0,0,0-.01428.44177L983.01818,176.47a.69588.69588,0,0,0-.125.0544.79968.79968,0,0,0,.47957,1.49692l47.45031-5.45193a1.91512,1.91512,0,0,0-.1118.646l-.244,22.24873a1.19819,1.19819,0,0,1-1.62406,1.10553l-46.60706-17.82454a1.20112,1.20112,0,0,1-.18261-2.151l.65006-.38537,48.83158-28.97294a1.20067,1.20067,0,0,1,1.8019,1.19268Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#f1f1f1"
      />
      <path
        d="M1031.1283,146.54645,982.28109,175.528l-.63455.37689a1.99756,1.99756,0,0,0,.30651,3.58642l46.6071,17.82452a2.004,2.004,0,0,0,1.83248-.2134,1.984,1.984,0,0,0,.87415-1.62894l.24409-22.24892a1.16219,1.16219,0,0,1,.37242-.85434,1.20226,1.20226,0,0,1,1.20761-.27169l6.24446,2.1195a1.95123,1.95123,0,0,0,2.28266-.752l.00908-.01314a1.95391,1.95391,0,0,0-.12721-2.41351l-8.64673-10.24363a1.22138,1.22138,0,0,1-.23734-.43608,1.29956,1.29956,0,0,1-.03358-.49905l1.54385-11.32333a1.99891,1.99891,0,0,0-2.99778-1.99087Zm2.20877,1.88209-1.54386,11.32332a1.97017,1.97017,0,0,0-.01428.44176,1.857,1.857,0,0,0,.07088.389,1.91585,1.91585,0,0,0,.15205.37685l.0033.00226a1.88917,1.88917,0,0,0,.23875.3498l8.64685,10.24347a1.15986,1.15986,0,0,1,.07714,1.44692l-.00455.00658a1.16242,1.16242,0,0,1-1.36924.45629l-6.24773-2.12177a1.99249,1.99249,0,0,0-1.908.35062l-.00222.00321c-.03489.02942-.0697.05871-.10123.09025a1.94312,1.94312,0,0,0-.5119.78227,1.91516,1.91516,0,0,0-.1118.646l-.24405,22.24875a1.19819,1.19819,0,0,1-1.624,1.10553l-46.60706-17.82453a1.20112,1.20112,0,0,1-.18261-2.151l.65006-.38537,48.83158-28.97294a1.20067,1.20067,0,0,1,1.8019,1.19268Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <path
        d="M1031.96287,160.13132l.25288.75767-.21056.07271-.0033-.00226-48.71965,16.26619,48.154-5.5288.00222-.0032.1298-.01218.09278.79248-.83795.09542-47.45032,5.452a.79969.79969,0,0,1-.47957-1.49693.69687.69687,0,0,1,.125-.05439l48.76074-16.2764Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <path
        d="M613.1421,551.48955a1.99891,1.99891,0,0,0,.08063,3.59774l10.39574,4.74657a1.29963,1.29963,0,0,1,.40386.29508,1.22139,1.22139,0,0,1,.24174.43366l4.03707,12.7828a1.95394,1.95394,0,0,0,1.972,1.39736l.016-.00067a1.95122,1.95122,0,0,0,1.85552-1.52744l1.54574-6.41064a1.20226,1.20226,0,0,1,.875-.87549,1.16217,1.16217,0,0,1,.92113.1418l18.93561,11.684a1.98394,1.98394,0,0,0,1.844.1317,2.004,2.004,0,0,0,1.15968-1.43479l9.84234-48.91895a1.99757,1.99757,0,0,0-2.86749-2.17574l-.65768.33491L613.1421,551.48954Zm.41036,2.87269a1.20067,1.20067,0,0,1-.0451-2.16039l50.58517-25.78946.67313-.34349a1.20111,1.20111,0,0,1,1.72043,1.30387L656.6438,576.2917a1.19819,1.19819,0,0,1-1.80235.78186l-18.93542-11.684a1.91519,1.91519,0,0,0-.60576-.25074l29.96662-37.19216a.79968.79968,0,0,0-1.00893-1.20533.6958.6958,0,0,0-.11278.07656l-39.816,32.51493a1.96963,1.96963,0,0,0-.381-.224l-10.39571-4.74655Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#f1f1f1"
      />
      <path
        d="M613.1421,551.48955a1.99891,1.99891,0,0,0,.08063,3.59774l10.39574,4.74657a1.29963,1.29963,0,0,1,.40386.29508,1.22139,1.22139,0,0,1,.24174.43366l4.03707,12.7828a1.95394,1.95394,0,0,0,1.972,1.39736l.016-.00067a1.95122,1.95122,0,0,0,1.85552-1.52744l1.54574-6.41064a1.20226,1.20226,0,0,1,.875-.87549,1.16217,1.16217,0,0,1,.92113.1418l18.93561,11.684a1.98394,1.98394,0,0,0,1.844.1317,2.004,2.004,0,0,0,1.15968-1.43479l9.84234-48.91895a1.99757,1.99757,0,0,0-2.86749-2.17574l-.65768.33491L613.1421,551.48954Zm.41036,2.87269a1.20067,1.20067,0,0,1-.0451-2.16039l50.58517-25.78946.67313-.34349a1.20111,1.20111,0,0,1,1.72043,1.30387l-9.8423,48.91892a1.19819,1.19819,0,0,1-1.80235.78186l-18.93544-11.684a1.91519,1.91519,0,0,0-.60576-.25074,1.94318,1.94318,0,0,0-.93476.01459c-.04351.0098-.08687.02358-.13039.03734l-.00389.00016a1.99248,1.99248,0,0,0-1.316,1.42525l-1.54558,6.41461a1.16244,1.16244,0,0,1-1.11741.91346l-.008.00032a1.15986,1.15986,0,0,1-1.18174-.83847l-4.03687-12.78281a1.89,1.89,0,0,0-.16806-.38874l-.00015-.004a1.91524,1.91524,0,0,0-.23726-.32991,1.85747,1.85747,0,0,0-.29092-.26781,1.97042,1.97042,0,0,0-.381-.224l-10.39573-4.74655Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
      <path
        d="M624.32916,559.33283l39.816-32.51492a.6965.6965,0,0,1,.11278-.07657.79968.79968,0,0,1,1.00893,1.20533l-29.96665,37.19216-.52846.65726-.62023-.50194.07965-.1032.0039-.00016,30.40767-37.74588-39.78538,32.48565.00015.004-.174.13911-.50525-.61865Z"
        transform="translate(-158.01586 -146.26797)"
        fill="#e5e5e5"
      />
    </svg>
  );
};

export default PopupIconComp;
