import styled from "styled-components";
import { mainColor } from "../../../constants";

export const ReviewsWrapper = styled.div``;

export const RatingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 40px;
  border-bottom: 1px solid #ccc;
`;
export const ReviewsAverageWrapper = styled.div`
  width: 15%;
`;
export const SectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.width ?? "fit-content"};
`;
export const ReviewsAverageValue = styled.h1`
  all: unset;
  font-size: 4rem;
  font-weight: 700;
  color: ${mainColor};
`;
export const ReviewsAverageDescription = styled.p`
  font-weight: 600;
  color: ${mainColor};
  padding: 8px 0px;
`;
export const ReviewsBarsWrapper = styled.div`
  width: 40%;
  :not(:last-of-type) {
    padding: 0 60px;
    border-right: 1px solid ${mainColor};
  }
`;
export const ReviewBarItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .anticon.anticon-star {
    color: gold;
  }
  .ant-progress-bg {
    background-color: gold;
  }
`;
export const ReviewBarItemValue = styled.span`
  margin: 0 8px;
  font-weight: 700;
`;

export const ReviewSpecWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const ReviewSpecItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: ${mainColor};
  }
`;
export const ReviewSpecItemName = styled.span`
  font-weight: 600;
  padding-top: 6px;
`;
export const CustomerReviewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  flex-direction: column;
`;
