/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Select } from "antd";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import {
  CategoriesAllNavWrap,
  CategoriesAllSorter,
  CategoriesAllViewSwitcher,
} from "./CategoriesAllPageNavEl";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCategoriesPageViewToGrid,
  changeCategoriesPageViewToList,
} from "../../redux/ui/ui.actions";
import { useParams } from "react-router-dom";
import api from "../../api";
import { useLocation } from "react-router-dom";

const CategoriesAllPageNav = () => {
  const { isGrid, mainColor } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();
  const { Option } = Select;

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <CategoriesAllNavWrap>
      <CategoriesAllViewSwitcher>
        <BsFillGrid3X3GapFill
          style={{ color: isGrid === true ? mainColor : "#ddd" }}
          onClick={() => dispatch(changeCategoriesPageViewToGrid("grid"))}
        />
        <FaList
          style={{ color: isGrid === false ? mainColor : "#ddd" }}
          onClick={() => dispatch(changeCategoriesPageViewToList("list"))}
        />
      </CategoriesAllViewSwitcher>

      <CategoriesAllSorter>
        <span>
          <b>Sort By</b>
        </span>
        <Select
          defaultValue="pricelow"
          style={{ width: 150 }}
          onChange={handleChange}
        >
          <Option value="pricelow">Price Low - High</Option>
          <Option value="pricehigh">Price High- Low</Option>
          <Option value="lucy">Name A - Z</Option>
          <Option value="Yiminghe">Name Z - A</Option>
        </Select>
      </CategoriesAllSorter>
    </CategoriesAllNavWrap>
  );
};

export default CategoriesAllPageNav;
