import { Link } from "react-router-dom";
import styled from "styled-components";

export const LoginTitle = styled.h1`
  font-weight: 700;
  text-align: center;
  color: ${({ mainColor }) => mainColor};
  font-size: 1.8rem;
`;

export const LoginForm = styled.form`
  & .gzlYtD {
    z-index: unset;
  }
`;

export const ForgotPasswordLink = styled.div`
  color: ${({ mainColor }) => mainColor};
  text-decoration: underline;
  cursor: pointer;
`;

export const DontHaveAccount = styled(Link)`
  color: ${({ mainColor }) => mainColor};
  text-decoration: underline;
`;

export const ForgotPasswordInput = styled.input`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 10px;
  margin: 8px;
  &:focus {
    border: 1px solid ${({ mainColor }) => mainColor} !important;
  }
`;

export const ResetPasswordContent = styled.div`
  display: flex;
  flex-direction: column;
`;
