import { useEffect } from 'react';
import AOS from 'aos';
import { motion } from 'framer-motion';
import CompliantForm from '../../components/compliant-form';
import containerVariants from '../../animation';

const Compliant = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div variants={containerVariants} initial="hidden" animate="visiable" exit="exit" data-aos="flip-up">
      <CompliantForm />
    </motion.div>
  );
};

export default Compliant;
