const formFields = [
  {
    name: "product_title",
    labelText: "Product Title",
    placeholder: "Product Title",
    type: "text",
  },
  {
    name: "description",
    labelText: "Product Description",
    type: "textarea",
  },
  {
    name: "drop_off_location",
    labelText: "Drop Off Location",
    type: "text",
  },
  {
    name: "end_bid_date",
    labelText: "End Bid Date",
    type: "date",
  },
];

export default formFields;
