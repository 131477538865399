import localWishlistTypes from "./localWishlist.types";

const initialState = {
    loading: true,
    wishlist: [],
    error: false,
};

export const localWishlistReducer = (state = initialState, action) => {
    const index = action.payload?.index
    const quantity = action.payload?.quantity
    switch (action.type) {
        case localWishlistTypes.ADD_ITEM:
            return {
                ...state,
                wishlist: state.wishlist.concat([action.payload]),
                loading: true,
                error: false,
            };
        case localWishlistTypes.DELETE_ITEM:
            return {
                ...state,
                wishlist: state.wishlist.filter(item => item?.id !== action.payload),
                loading: true,
                error: false,
            };
        case localWishlistTypes.GET_CART:
            return {
                ...state,
                wishlist: state.wishlist.filter(item => item?.item?.id !== 2222222222222222),
                loading: true,
                error: false,
            };
        case localWishlistTypes.CLEAR_WISHLIST:
            return {
                ...state,
                wishlist: [],
                loading: true,
                error: false,
            };
        default:
            return state;
    }
};