import React, { useState } from "react";
import { Button, Modal, InputNumber, message, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import { getItemsFromCartAction } from "../redux/cart/cart.actions";
import { localCartAdd } from "../redux/localCart/localCart.actions";

const AddAddressModal = ({ getData, visible, setVisible, product }) => {
  const dispatch = useDispatch();
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  const [title, setTitle] = React.useState("");
  const [details, setDetails] = React.useState("");

  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  console.log("PRODUCT", product);

  const handleAddClick = () => {
    setLoading(true);
    api
      .post(`/add-new-address`, {
        token,
        address_title: title,
        address_details: details,
      })
      .then(() => {
        dispatch(getData(token));
        setVisible(false);
        setLoading(false);
        message.success("Address has been added successfully");
      });
  };

  return (
    <>
      <Modal
        visible={visible}
        title="Add Address"
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        footer={[
          <div className="flex-between">
            <Button
              style={{ fontWeight: "500", borderRadius: "12px" }}
              key="back"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleAddClick()}
              style={{ fontWeight: "500", borderRadius: "12px" }}
              key="submit"
              type="primary"
              loading={loading}
            >
              Add Address
            </Button>
          </div>,
        ]}
      >
        <div className="add-address" style={{ padding: "15px 20px" }}>
          <label>Address Title</label>
          <br />
          <input
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Address Title"
          />
          <br />
          <label>Address Details</label>
          <br />
          <textarea
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Address Details"
            rows={3}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddAddressModal;
