import { Select, Collapse } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomLink from "../../../components/custom-link";
import { mainColor } from "../../../constants";

import {
  CollapseRowLeft,
  CollapseRowWrap,
  CorporateOrdersTabContentCollapseHeader,
  CorporateOrdersTabContentHeader,
  CorporateOrdersTabContentWrap,
} from "../CorporateBuyerDashboardEl";

// Some Styles
const btnStyle = {
  backgroundColor: mainColor,
  color: "#fff",
  padding: "10px 20px",
  marginRight: 20,
  display: "block",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: ".8rem",
};

const CollapseHeader = () => {
  return (
    <CorporateOrdersTabContentCollapseHeader>
      <div>
        <span>ORDER PLACED</span>
        <span>
          <b>August 26, 2020</b>
        </span>
      </div>
      <div>
        <span>TOTAL</span>
        <span>
          <b>$322.92</b>
        </span>
      </div>
      <div>
        <span>SHIP TO</span>
        <span>
          <b>8540 NW 66 ST Miami, FL 33195</b>
        </span>
      </div>
    </CorporateOrdersTabContentCollapseHeader>
  );
};

const CollapseRow = () => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <CollapseRowWrap>
      <CollapseRowLeft>
        <img
          src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt=""
        />
        <Link to="/">Product Name</Link>
      </CollapseRowLeft>
      <div>$993.00</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomLink
          to="/"
          text="buy again"
          style={{ ...btnStyle, backgroundColor: mainColor }}
        />
        <BiDotsVerticalRounded />
      </div>
    </CollapseRowWrap>
  );
};

const CorporateOrdersTabContent = () => {
  const { Option } = Select;
  const { Panel } = Collapse;

  const handleChange = value => {
    console.log(`selected ${value}`);
  };

  return (
    <CorporateOrdersTabContentWrap>
      <CorporateOrdersTabContentHeader>
        <h4>Orders</h4>
        <Select
          defaultValue="past-3-month"
          style={{ width: 150 }}
          onChange={handleChange}
        >
          <Option value="past-3-month">Past 3 Month</Option>
          <Option value="past-6-month">Past 6 Month</Option>
          <Option value="past-12-month">Past 12 Month</Option>
        </Select>
      </CorporateOrdersTabContentHeader>
      <div>
        <Collapse style={{ width: "100%" }}>
          <Panel header={<CollapseHeader />} key="1">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="2">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="3">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="4">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="5">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="6">
            <CollapseRow />
          </Panel>
        </Collapse>
      </div>
    </CorporateOrdersTabContentWrap>
  );
};

export default CorporateOrdersTabContent;
