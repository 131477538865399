import styled from "styled-components";

export const FormWrapper = styled.div`
  padding: 32px 32px;
  border-radius: 16px;
  background-color: mintcream;
`;
export const FormDescription = styled.p`
  line-height: 1.8;
`;
