import { useForm } from 'react-hook-form';
import React from 'react';
import { Container, ContainerWrapper, PageTitle } from '../../../constants';
import { FormWrapper, FormDescription } from './formEl';
import formFields from './utils/formFields';
import FormHandler from '../../form-elements/form-handler';
import { FormRow } from '../../form-elements/constants';
import Button from '../../button';

const OrderTrackingForm = () => {
  const { control } = useForm();
  return (
    <ContainerWrapper>
      <Container width="35%">
        <PageTitle>Order Tracking</PageTitle>
        <FormWrapper>
          <FormDescription>To track your order please enter your Order ID in the box below and press the “Track” button. This was given to you on your receipt and in the confirmation email you should have received.</FormDescription>
          <form>
            <FormHandler control={control} formFields={formFields} />
            <FormRow justifyContent="flex-end" margin="20px 0">
              <Button type="submit" text="Track" />
            </FormRow>
          </form>
        </FormWrapper>
      </Container>
    </ContainerWrapper>
  );
};

export default OrderTrackingForm;
