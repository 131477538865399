import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PagesBreadCrumb from '../../components/pages-bread-crumb';
import { Container } from '../../constants';
import { RichTextWrapper, Title, TitleWrapper } from './RichTextPageEl';
import { fetchRichTextAction } from '../../redux/rich-text/richText.actions';
import LoadingSpinner from '../../components/loading-spinner';
import RichTextContainer from '../../components/rich-text-container';

const RichTextPage = ({ pageId }) => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(fetchRichTextAction(pageId));
  }, [pageId, dispatch]);

  const { data: richTextData, loading } = useSelector(({ richText }) => richText);

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <Container>
        <PagesBreadCrumb prev="Home" curr={richTextData.page_title} />
      </Container>

      <TitleWrapper mainColor={mainColor}>
        <Title mainColor={mainColor}>{richTextData.page_title}</Title>
      </TitleWrapper>

      <Container>
        <RichTextWrapper>
          <RichTextContainer richText={richTextData.html_content} />
        </RichTextWrapper>
      </Container>
    </>
  );
};

export default RichTextPage;
