import styled, { css } from "styled-components";
import { mainColor, screenMd } from "../../constants";

export const CategoriesAllPageSidebarWrap = styled.div`
  padding: 10px 20px;
  border: 1px solid #eae3e3;
  height: fit-content;

  .ant-btn-primary:hover,
  .ant-btn-primary:focus,
  .ant-btn-primary {
    border-color: ${({ mainColor }) => mainColor} !important;
    background: ${({ mainColor }) => mainColor} !important;
  }

  .ant-slider-handle {
    border: 2px solid ${({ mainColor }) => mainColor} !important;
  }

  .ant-slider-step {
    background-color: #3e32322b !important;
  }

  @media screen and (max-width: ${screenMd}) {
    display: none;
  }
`;

export const SidebarBoxWrap = styled.div`
  /* border: 1px solid red; */

  .ant-input-group-addon button {
    border-color: ${mainColor};
    background: ${mainColor};
  }
  .ant-input-group input {
    &:focus {
      border: 1px solid ${mainColor} !important;
      box-shadow: none !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${mainColor};
    border-color: ${mainColor};
  }
`;

export const BoxTitle = styled.h5`
  font-size: 1rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const OptionsWrapperCSS = css``;

export const OptionsWrapper = styled.div`
  margin: 20px 0 25px 0;
  padding-bottom: 13px;
  max-height: 200px;
  overflow-y: auto;

  :not(:last-of-type) {
    border-bottom: 1px solid #eae3e3;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ mainColor }) => mainColor};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ mainColor }) => mainColor};
  }
`;

export const ColorsOptions = styled.div`
  padding-bottom: 13px;
  margin: 20px 0 25px 0;

  :not(:last-of-type) {
    border-bottom: 1px solid #eae3e3;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ mainColor }) => mainColor};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ mainColor }) => mainColor};
  }
`;

export const SizesOptions = styled.div`
  :not(:last-of-type) {
    border-bottom: 1px solid #eae3e3;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ mainColor }) => mainColor};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ mainColor }) => mainColor};
  }
`;

export const ColorCircleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
