import { message } from "antd";
import api from "../../api";
import productTypes from "../product/product.types";
import wishlistTypes from "./wishlist.types";

export const getWishlistAction = (token) => {
  return async (dispatch) => {
    dispatch({
      type: wishlistTypes.GET_WISHLIST_REQUEST,
    });
    api
      .get(`/wishlist?currency=EGP${token === undefined ? '' : `&token=${token}`}`)
      .then(({ data }) =>
        dispatch({
          type: wishlistTypes.GET_WISHLIST_SUCCESS,
          payload: data,
        })
      )
      .catch((err) => {
        dispatch({
          type: wishlistTypes.GET_WISHLIST_ERROR,
          payload:
            err.response && err.response.data.error
              ? err.response.data.error
              : err.message,
        });
      });
  };
};

export const addToWishlistAction = (productId) => {
  return async (dispatch) => {
    dispatch({
      type: wishlistTypes.ADD_TO_WISHLIST_REQUEST,
    });
    await api
      .get(`/wishlist/add/${productId}?token=true`)
      .then(({ data }) => {
        dispatch({
          type: wishlistTypes.ADD_TO_WISHLIST_SUCCESS,
          payload: data,
        });
        // console.log("data data data data", data?.data?.product);
        // data?.data?.product &&
        //   dispatch({
        //     type: productTypes.UPDATE_PRODUCT_IS_WISH_LISTED,
        //   });
      })
      .catch((err) => {
        message.error(err.response.data.error);
        dispatch({
          type: wishlistTypes.ADD_TO_WISHLIST_ERROR,
        });
      });
  };
};
