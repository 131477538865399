import React from 'react';
import { useForm } from 'react-hook-form';
import { Container, PageTitle, ContainerWrapper } from '../../constants';
import Button from '../button';
import FormHandler from '../form-elements/form-handler';
import { PageSubtitle } from './CompliantFormEl';
import { FormRow, InputWrapper } from '../form-elements/constants';
import formFields from './utils/formFields';

const CompliantForm = () => {
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log('data data data', data);
  };

  return (
    <>
      <PageTitle>Compliant</PageTitle>
      <PageSubtitle>Order Number</PageSubtitle>
      <PageSubtitle as="p">123456</PageSubtitle>
      <ContainerWrapper>
        <Container width="50%">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHandler control={control} formFields={formFields} />
            <FormRow justifyContent="flex-end">
              <InputWrapper minWidth="20%">
                <Button type="submit" text="Submit" />
              </InputWrapper>
            </FormRow>
          </form>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default CompliantForm;
