const loclCartTypes = {
    // Local Cart
    GET_CART: "GET_CART",
    DELETE_ITEM: "DELETE_ITEM",
    EDIT_ITEM_QUANTITY: "EDIT_ITEM_QUANTITY",
    CLEAR_CART: "CLEAR_CART",
    ADD_ITEM: "ADD_ITEM",
    QUANTITY_PLUS: "QUANTITY_PLUS",
    QUANTITY_MINUS: "QUANTITY_MINUS"
};

export default loclCartTypes;
