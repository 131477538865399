import styled from "styled-components";
import ProductSearchBar from "./product-search-bar";
import AlertPopup from "../components/alert-popup";
import { useState } from "react";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8em;
`;

const TestPage = () => {
  const [showPopupModal, setShowPopupModal] = useState();
  return (
    <div>
      <AppContainer>
        <ProductSearchBar />
      </AppContainer>
      <div>
        <button onClick={setShowPopupModal}>Show</button>
        <AlertPopup
          showPopupModal={showPopupModal}
          setShowPopupModal={setShowPopupModal}
          is_success
        />
      </div>
    </div>
  );
};

export default TestPage;
