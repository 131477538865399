import { useSelector } from "react-redux";
import { CheckoutArrowItem, CheckoutArrowsWrap } from "./CheckoutPageEl";

export const RenderCheckoutSwitcher = ({ step1, step2, step3, step4 }) => {
  // Redux stuff
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <CheckoutArrowsWrap>
      <CheckoutArrowItem
        // to="/checkout/save-address"
        onClick={() => {}}
        mainColor={step1 ? mainColor : false}
      >
        Information
      </CheckoutArrowItem>
      {/* <CheckoutArrowItem
        mainColor={step2 ? mainColor : false}
        to="/checkout/shipping-method"
        onClick={() => {}}
      >
        Shipping Method
      </CheckoutArrowItem> */}
      <CheckoutArrowItem
        mainColor={step3 ? mainColor : false}
        // to="/checkout/payment-method"
        onClick={() => {}}
      >
        Payment Method
      </CheckoutArrowItem>
      <CheckoutArrowItem
        mainColor={step4 ? mainColor : false}
        // to="/checkout/place-order"
        onClick={() => {}}
      >
        Place Order
      </CheckoutArrowItem>
    </CheckoutArrowsWrap>
  );
};

const CheckoutPage = () => {
  // const [active, setactive] = useState("save-address");

  return (
    <div>
      <RenderCheckoutSwitcher step1 />
    </div>
  );
};

export default CheckoutPage;
