import loclCartTypes from "./localCart.types";

const initialState = {
    loading: true,
    cartItems: [],
    data: {
        cartItems: [],
        price: 0,
        formattedPrice: 0,
    },

    error: false,
};

export const loclCartReducer = (state = initialState, action) => {
    const index = action.payload?.index
    const quantity = action.payload?.quantity
    const itemId = action.payload?.item?.id
    switch (action.type) {
        case loclCartTypes.ADD_ITEM:
            const itemsIds = state.cartItems?.map(item => item.item.id)
            const findTheItem = state.cartItems?.find(item => item?.item?.id === itemId)
            const repeatedItem = state.cartItems.indexOf(findTheItem)
            if (itemsIds.includes(itemId)) {
                state.cartItems[repeatedItem].quantity = state.cartItems[repeatedItem].quantity + action.payload.quantity
            }
            console.log("FIND THE ITEM", repeatedItem)
            console.log("LOCAL CART ITEMS IDS", itemsIds)
            return itemsIds.includes(itemId) ?
                {
                    ...state,
                    cartItems: state.cartItems,
                    loading: true,
                    error: false,
                } :
                {
                    ...state,
                    cartItems: state.cartItems.concat([action.payload]),
                    loading: true,
                    error: false,
                }
        case loclCartTypes.DELETE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item?.item?.id !== action.payload),
                loading: true,
                error: false,
            };
        case loclCartTypes.GET_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item?.item?.id !== 2222222222222222),
                loading: true,
                error: false,
            };
        case loclCartTypes.CLEAR_CART:
            return {
                ...state,
                cartItems: [],
                loading: true,
                error: false,
            };
        case loclCartTypes.QUANTITY_PLUS:
            state.cartItems[index].quantity = quantity + 1
            return {
                ...state,
                cartItems: state.cartItems,
                loading: true,
                error: false,
            };
        case loclCartTypes.QUANTITY_MINUS:
            state.cartItems[index].quantity = quantity - 1
            return {
                ...state,
                cartItems: state.cartItems,
                loading: true,
                error: false,
            };
        default:
            return state;
    }
};