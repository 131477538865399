import { useEffect } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import ShoppingCartTable from "../../components/shopping-cart-table.index";
import containerVariants from "../../animation";
import FavTable from "../../components/shopping-cart-table.index/FavTable";
import { useSelector } from "react-redux";
import LocalFavTable from "../../components/shopping-cart-table.index/LocalFavTable";

const WhishListPage = () => {
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="flip-right"
    >
      {token ? (
        <FavTable title="Favorites" />
      ) : (
        <LocalFavTable title="Favorites" />
      )}
    </motion.div>
  );
};

export default WhishListPage;
