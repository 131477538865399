import styled, { css } from "styled-components";

const DefaultStyle = css`
  color: #fff;
  border: none;
`;
const OutlinedStyle = css`
  background-color: #fff;
  color: ${({ mainColor }) => mainColor};
  :hover {
    ${DefaultStyle}
  }
`;
export const ButtonWrap = styled.button`
  background-color: ${({ mainColor }) => mainColor};
  ${props => (props.variant === "outlined" ? OutlinedStyle : DefaultStyle)};
  text-align: center;
  width: ${props => props.width ?? "fit-content"};
  transition: 0.5s ease-in-out;
  cursor: pointer;
  padding: 12px 25px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  display: flex;
  align-items: center;

  justify-content: center;
  margin: ${props => props.margin};
  border-radius: ${props => props.borderRadius};
  opacity: ${props => props.loading && 0.75};
  border: 2px solid #fff;
`;

export const LoadingSpinnerWrapper = styled.div`
  margin-left: 12px;
  .ant-spin-dot-item {
    background-color: white;
  }
`;
