import { Rate } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const CustomRate = ({ name, control }) => {
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        // render={({ field: { onChange, value } }) => {
        return <Rate tooltips={desc} {...field} />;
      }}
    />
  );
};

export default CustomRate;
