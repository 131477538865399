import { useEffect } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OrderTrackingForm from "../../components/order-tracking/form";
import OrderTrackingResult from "../../components/order-tracking/result";
import containerVariants from "../../animation";

const OrderTracking = () => {
  const { path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="fade-up"
    >
      <Switch>
        <Route exact path={`${path}`} component={OrderTrackingForm} />
        <Route exact path={`${path}/result`} component={OrderTrackingResult} />
      </Switch>
    </motion.div>
  );
};

export default OrderTracking;
