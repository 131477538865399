import { CloseOutlined } from "@ant-design/icons";
import { mainColor } from "../../constants";
import ourCategories from "../products-categories/our-categories";
import Logo from "../../assets/images/dent-logo.png";
import {
  SidebarWrapper,
  CloseIcon,
  LinksWrapper,
  LinkItem,
} from "./MobileSidebarEl";

const Link = {
  color: mainColor,
};

const MobileSideBar = ({ isOpen, setIsOpen }) => {
  return (
    <SidebarWrapper isOpen={isOpen}>
      <CloseIcon onClick={() => setIsOpen(!isOpen)}>
        <CloseOutlined />
      </CloseIcon>

      <img src={Logo} alt="logo" />

      <LinksWrapper>
        {ourCategories.map(({ name }, idx) => (
          <LinkItem to="/" key={idx} onClick={() => setIsOpen(false)}>
            {name.length > 25 ? `${name.substring(0, 25)}...` : name}
          </LinkItem>
        ))}
      </LinksWrapper>

      <hr />
      <LinkItem
        onClick={() => setIsOpen(false)}
        to="/registration"
        style={{ ...Link, marginTop: 30, display: "block" }}
      >
        Login
      </LinkItem>
      <LinkItem
        onClick={() => setIsOpen(false)}
        to="/registration"
        style={{ ...Link }}
      >
        Register
      </LinkItem>
    </SidebarWrapper>
  );
};

export default MobileSideBar;
