const buyerFields = [
  {
    name: "name",
    type: "text",
    placeholder: "Full Name",
    labelText: "Full Name",
    id: "name",
    htmlFor: "name",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email Address",
    labelText: "Email Address",
    id: "email",
    htmlFor: "email",
  },
  {
    name: "mobile",
    type: "tel",
    placeholder: "Phone",
    labelText: "Phone",
    id: "mobile",
    htmlFor: "mobile",
  },
  {
    name: "password",
    type: "password",
    placeholder: "Password",
    labelText: "Password",
    id: "password",
    htmlFor: "password",
  },
  {
    name: "password_confirmation",
    type: "password",
    placeholder: "Password",
    labelText: "Password",
    id: "password_confirmation",
    htmlFor: "password_confirmation",
  }
];

export default buyerFields;


export const stepTwoFields = [
  {
    name: "code",
    type: "text",
    placeholder: "Code",
    labelText: "Code",
    id: "code",
    htmlFor: "code",
  }
];