import styled from "styled-components";

// App Colors
export const bodyBg = "#f0f1f2";
export const mainColor = "#0077F9";
export const secColor = "#fff";
export const appLink = "#555";
export const borderTop = "#bfbfbf75";

// App Breakpoints
export const screenXSm = "350px";
export const screenSm = "551px";
export const screenMd = "768px";
export const screenLg = "992px";
export const screenMLg = "1200px";
export const screenXl = "1500px";

// Global Variables
export const marginBt = "25px";
export const API_BASE_URL = "https://dentohome.com/test/api";

export const ContainerWrapper = styled.div`
  margin: 80px auto;
`;

// Custom Container
export const Container = styled.div`
  width: ${(props) => props.width ?? "95%"};
  margin: 0 auto;
  max-width: 1400px;

  @media screen and (max-width: ${screenMd}) {
    width: 90%;
  }
  @media screen and (min-width: ${screenMd}) and (max-width: ${screenMLg}) {
    width: 75%;
  }
`;

// Page Title
export const PageTitle = styled.h1`
  color: ${({ mainColor }) => mainColor};
  font-weight: 700;
  text-align: center;
  font-size: ${(prop) => prop.fontSize ?? "2.5rem"};
  margin-top: 80px;
  margin: ${(props) => props.margin};
`;
