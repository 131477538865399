import styled from "styled-components";

export const ReviewsFormWrapper = styled.div`
  padding: 30px 0px 30px;
  border-bottom: 1px solid #ccc;
`;

export const ReviewsFormTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 500;
`;
export const Form = styled.form`
  width: 100%;
`;
