import api from "../../api";
import categoriesTypes from "./categories.types";

export const fetchCategoriesAction = () => dispatch => {
  api.get('/categories?secret=admin123')
    .then(res => {
      dispatch({
        type: categoriesTypes.FETCH_CATEGORIES_REQUEST,
        payload: res.data
      })
    })
};

export const extractVariantValues = selectedVariants => {
  return {
    type: categoriesTypes.EXTRACT_VARIANT_VALUES,
    payload: selectedVariants,
  };
};
