import styled from "styled-components";

export const PagesBreadCrumbEl = styled.div`
  margin: 20px 0;

  a {
    color: ${({ mainColor }) => mainColor};
    font-weight: 600;
    text-transform: capitalize;
  }

  svg {
    color: ${({ mainColor }) => mainColor};
  }
`;
