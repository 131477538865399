import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import ProductDetailsMain from "../../components/product-details/main";
import SideBar from "../../components/product-details/sidebar";
// import ProductsCarousel from "../../components/products-carousel";
import { Container } from "../../constants";
import {
  ProductDetailContent,
  ProductDetailWrapper,
  ProductsCarouselWrapper,
} from "./ProductDetailsEl";
import { fetchProductAction } from "../../redux/product/product.actions";
import { fetchReviewsAction } from "../../redux/reviews/reviews.actions";
import LoadingSpinner from "../../components/loading-spinner";
import PageNotFound from "../404";
import { useData } from "../../constants/useData";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { productId } = useParams();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(fetchProductAction(productId));
    dispatch(fetchReviewsAction(productId));
  }, [dispatch, productId]);
  const token = useSelector(({ buyer }) => buyer.data.access_token);

  const {
    loading,
    error,
    data: productData,
  } = useSelector(({ product }) => product);
  // if (loading) return <LoadingSpinner />;

  const product = useData(`get-product/${productId}?token=${token}`);

  console.log("THE PRODUCT", product);

  return (
    <Container>
      <PagesBreadCrumb prev="home" curr="Ahmed" />
      <ProductDetailWrapper>
        <ProductDetailContent>
          <ProductDetailsMain product={product} />
          <SideBar product={product} title="Category Products" />
        </ProductDetailContent>
        <ProductsCarouselWrapper>
          {/* <ProductsCarousel title="Related Products" /> */}
        </ProductsCarouselWrapper>
      </ProductDetailWrapper>
    </Container>
  );
};

export default ProductDetails;
