import styled from "styled-components";
import { mainColor, screenMd } from "../../constants";

export const CategoriesAllNavWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${screenMd}) {
    align-items: flex-start !important;
  }
`;

export const CategoriesAllViewSwitcher = styled.div`
  border: 1px solid ${mainColor};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  font-size: 20px;

  svg {
    cursor: pointer;
  }

  svg:first-of-type {
    margin-right: 10px;
  }

  @media screen and (max-width: ${screenMd}) {
    display: none !important;
  }
`;

export const CategoriesAllSorter = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }

  /* @media screen and (max-width: ${screenMd}) {
    & > span {
      display: none;
    }
  } */
`;
