import api from "../../api";
import cartTypes from "./cart.types";

export const getItemsFromCartAction = (token) => {
  return async dispatch => {
    dispatch({
      type: cartTypes.GET_CART_ITEMS_REQUEST,
    });
    try {
      const { data } = await api.get(`/cart?token=${token}`);

      dispatch({
        type: cartTypes.GET_CART_ITEMS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: cartTypes.GET_CART_ITEMS_FAILED,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const addItemToCartAction = (items, tokenType) => {
  return async dispatch => {
    dispatch({
      type: cartTypes.CART_ADD_REQUEST,
    });
    try {
      const { data } = await api.post(
        `/checkout/cart/add-bulk?token=${tokenType}`,
        {
          items,
        }
      );

      dispatch({
        type: cartTypes.CART_ADD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: cartTypes.CART_ADD_FAILED,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const removeItemFromCartAction = productId => {
  return async dispatch => {
    dispatch({
      type: cartTypes.CART_REMOVE_ITEM_REQUEST,
    });
    try {
      const { data } = await api.get(
        `/checkout/cart/remove-item/${productId}?token=api`,
        { product_id: productId }
      );

      dispatch({
        type: cartTypes.CART_REMOVE_ITEM_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: cartTypes.CART_REMOVE_ITEM_FAILED,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const clearItemFromCartLocal = product_id => ({
  type: cartTypes.REMOVE_ITEM_FROM_CART_LOCAL,
  payload: product_id,
});
