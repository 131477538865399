const wishlistTypes = {
  GET_WISHLIST_REQUEST: "GET_WISHLIST_REQUEST",
  GET_WISHLIST_SUCCESS: "GET_WISHLIST_SUCCESS",
  GET_WISHLIST_ERROR: "GET_WISHLIST_ERROR",

  ADD_TO_WISHLIST_REQUEST: "ADD_TO_WISHLIST_REQUEST",
  ADD_TO_WISHLIST_SUCCESS: "ADD_TO_WISHLIST_SUCCESS",
  ADD_TO_WISHLIST_ERROR: "ADD_TO_WISHLIST_ERROR",
};

export default wishlistTypes;
