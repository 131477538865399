import api from "../../api";
import homepageTypes from "./homepage.types";

export const GetHomepageDataAction = (token) => {
  console.log("TOKEN FROM ACTION", token)
  return async dispatch => {
    dispatch({
      type: homepageTypes.FETCH_HOMEPAGE_DATA_REQUEST,
    });

    console.log("THE TOKEN FROM ACTION", token)
    try {
      const { data } = await api.get(`/homepage?secret=admin123&currency=EGP${token === undefined ? '' : `&token=${token}`}`);

      dispatch({
        type: homepageTypes.FETCH_HOMEPAGE_DATA_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: homepageTypes.FETCH_HOMEPAGE_DATA_ERROR,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};
