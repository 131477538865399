import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RenderCheckoutSwitcher } from "..";
import { CheckoutSaveAddressWrap } from "../CheckoutPageEl";
import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { CustomerAddressTabContentRow } from "../../customer-buyer-dashboard/CustomerBuyerDashboardEl";
import { Checkbox, message, Radio } from "antd";
import CustomButton from "../../../components/custom-button";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import AddAddressModal from "../../../components/AddAddressModal";
import { getAddresses } from "../../../redux/address/address.actions";
import { useHistory } from "react-router-dom";

const CheckoutSaveAddress = () => {
  const addresses = useSelector((state) => state?.address?.data);
  const [choosenAddress, setChoosenAddress] = React.useState(0);
  const [show, setShow] = React.useState();
  const history = useHistory();

  return (
    <CheckoutSaveAddressWrap>
      <RenderCheckoutSwitcher step1 />
      <AddAddressModal
        visible={show}
        setVisible={setShow}
        getData={getAddresses}
      />

      <div className="formwrapper">
        <div style={{ padding: "0px 15px" }} className="flex-between">
          <h2>Choose Address</h2>
          <AiOutlinePlus
            onClick={() => setShow(true)}
            size={20}
            style={{ cursor: "pointer" }}
          />
        </div>
        {addresses?.map((item, index) => {
          return (
            <CustomerAddressTabContentRow
              style={{
                padding: "20px",
                background:
                  choosenAddress === item?.id
                    ? "rgba(0, 119, 249, 0.5)"
                    : "#fff",
                transition: "0.3s ease",
              }}
              className="m-4"
            >
              <div>
                <h5>{item?.address_title}</h5>{" "}
                <span>{item?.address_details}</span>
              </div>

              <div>
                <input
                  type="radio"
                  style={{ border: "1px solid #ddd", zoom: "120%" }}
                  onClick={() => setChoosenAddress(item?.id)}
                  name="radio1"
                />
              </div>
            </CustomerAddressTabContentRow>
          );
        })}
        <div>
          <div className="formwrapper">
            <h2 style={{ marginLeft: "15px" }}>
              Another Phone Number (Optional)
            </h2>
            <input
              placeholder="Enter additional phone number"
              className="another-phone-input"
            />
          </div>
          <CustomButton
            onClick={() =>
              choosenAddress !== 0
                ? history.push(`/checkout/payment-method/${choosenAddress}`)
                : message.error(`Choose address first`)
            }
            type="submit"
            text="Continue"
            style={{
              padding: "13px 30px",
              marginTop: 40,
              fontWeight: 500,
            }}
          />
        </div>
      </div>
    </CheckoutSaveAddressWrap>
  );
};

export default CheckoutSaveAddress;
