import styled from "styled-components";
import { screenLg } from "../../../constants";

export const FormWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: ${screenLg}) {
    margin-top: 24px;
  }
`;

export const FormTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const FormInfo = styled.p`
  margin-top: 14px;
  font-size: 12px;
  color: #777;
`;
