import {
  CategoriesMenuItem,
  CategoriesSidebarContainer,
  CategoriesSidebarHeader,
  CategoriesSidebarWrap,
} from "./categoriesSidebarEl";
// eslint-disable-next-line
import { BsChevronRight, BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { secColor } from "../../constants";
import ourCategories from "../products-categories/our-categories";
import {} from "../header/upper-header/UpperHeaderEl";
import { useSelector } from "react-redux";

const CategoriesSidebar = ({ setCatId }) => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const categories = useSelector(
    (state) => state.homepage?.data?.categories_subcategories_childcategories
  );

  console.log("CATEGORIES", categories);

  return (
    <CategoriesSidebarContainer>
      <CategoriesSidebarWrap mainColor={mainColor}>
        <CategoriesSidebarHeader mainColor={mainColor}>
          <span> see all categories </span>
          <BsArrowRight />
        </CategoriesSidebarHeader>

        {categories?.map((category, idx) => (
          <CategoriesMenuItem
            onMouseEnter={() => setCatId(category?.id)}
            onMouseLeave={() => setCatId(2000000000)}
            key={idx}
            to={`/categories/main/${category.id}`}
          >
            <span style={{ fontSize: "16px", fontWeight: "500" }}>
              {category?.title?.en}
            </span>
            <BsChevronRight />
          </CategoriesMenuItem>
        ))}
      </CategoriesSidebarWrap>
    </CategoriesSidebarContainer>
  );
};

export default CategoriesSidebar;
