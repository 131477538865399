import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mainColor, screenLg } from '../../constants';

export const AppMainHeaderWrap = styled.div`
  background-color: ${({ mainColor }) => mainColor};
  color: #fff;
  padding: 20px 0 30px 0;
  margin-bottom: 30px;
`;

export const AppMainHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  overflow: hidden;

  @media screen and (max-width: ${screenLg}) {
    margin-top: 30px;
  }
`;

export const SearchForm = styled.form`
  width: 500px;
  margin: 30px auto;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  position: relative;

  @media screen and (max-width: ${screenLg}) {
    display: none;
  }
`;

export const ProductsSearchInput = styled.input`
  all: unset;
  padding-left: 10px;
  width: 100%;
  background-color: #fff;
  font-size: 0.7rem;
  color: #000;
`;

export const ProductsSearchIcon = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 3px;
  color: ${mainColor};
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  padding-right: 10px;
  font-size: 1.2rem;
`;

export const MainHeaderRightWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const MainHeaderRightBox = styled(Link)`
  padding: 10px 20px;
  font-size: 1.5rem;
  color: #fff;
  position: relative;
`;

export const MainHeaderRightBoxCount = styled(Link)`
  position: absolute;
  background-color: red;
  color: #fff;
  bottom: 9px;
  right: 12px;
  font-size: 0.7rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainHeaderRightBoxUser = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;

  svg {
    font-size: 1.5rem;
    margin-right: 10px;
  }
`;

export const MainHeaderRightBoxUserLinks = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: #fff;
    font-weight: 300;
  }
`;

export const AppMainHeaderBottom = styled.div`
  border-bottom: 1px solid #ffffff5c;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AppMainHeaderBottomLeft = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin-right: 20px;
    font-weight: 300;
  }
`;

export const AppMainHeaderBottomRight = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin-left: 20px;
    font-weight: 300;

    &:not(:first-of-type) {
      border-left: 2px solid #ffffff5c;
      padding-left: 20px;
    }
  }
`;
