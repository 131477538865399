import api from "../../api";
import userTypes from "../user/user.types";
import buyerTypes from "./buyer.types";

export const buyerRegisterAction = (buyerData, buyerInfo, setErr, callBack) => async dispatch => {
  dispatch({
    type: buyerTypes.BUYER_REGISTER_REQUEST,
  });

  try {
    const { data } = await api.post("/verify-code", buyerData);
    setErr({})
    dispatch({
      type: buyerTypes.BUYER_REGISTER_SUCCESS,
      payload: data,
    });

    // Login
    const { email, password } = buyerInfo;
    await api
      .post(`/customer/login?token=api`, { email, password })
      .then(({ data }) => {
        callBack()
        dispatch({
          type: userTypes.Login_USER_SUCCESS,
          payload: data,
        })
      }
      )
      .catch(err => {
        dispatch({
          type: userTypes.Login_USER_ERROR,
          payload:
            err.response && err.response.data.errors
              ? err.response.data.errors
              : err.message,
        });
      });
  } catch (err) {
    setErr(err.response?.data)
    dispatch({
      type: buyerTypes.BUYER_REGISTER_FAILED,
      payload:
        err.response && err.response.data.errors
          ? err.response.data.errors
          : err.message,
    });
  }
};

export const logoutBuyerAction = () => ({
  type: buyerTypes.BUYER_LOGOUT,
});

export const buyerClearMessages = () => ({
  type: buyerTypes.VENDOR_CLEAR_FIELDS,
});
