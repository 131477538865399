import { message } from "antd";
import wishlistTypes from "./wishlist.types";

const initialState = {
  loading: false,
  isSuccess: false,
  data: [],
};

export const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET_WISHLIST

    case wishlistTypes.GET_WISHLIST_REQUEST:
      return {
        ...state
      };
    case wishlistTypes.GET_WISHLIST_SUCCESS:
      return {
        loading: false,
        wishlist: action.payload,
        error: null,
      };

    case wishlistTypes.GET_WISHLIST_ERROR:
      return {
        loading: false,
        wishlist: [],
        error: action.payload,
      };

    // ADD_TO_WISHLIST

    case wishlistTypes.ADD_TO_WISHLIST_REQUEST:
      return {
        loading: true,
        isSuccess: false,
        data: state.data,
        error: null,
      };
    case wishlistTypes.ADD_TO_WISHLIST_SUCCESS:
      message.success(action.payload.message);
      return {
        loading: false,
        isSuccess: true,
        ...action.payload,
        error: null,
      };

    case wishlistTypes.ADD_TO_WISHLIST_ERROR:
      return {
        loading: false,
        isSuccess: false,
      };

    default:
      return state;
  }
};
