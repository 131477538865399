import { useState } from "react";
import { useSelector } from "react-redux";
import {
  SignupText,
  TabNavigatorList,
  TabNavigatorListItem,
} from "../../pages/registration/RegistrationEl";
import BuyerSignupForm from "../buyer-signup-form";
import CustomerSignupForm from "../customer-signup-form";
import VendorSignupForm from "../vendor-signup-form";
import RegisterStepOne from "../buyer-signup-form/RegisterStepOne";

const TabsNavigator = () => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const { isVendor } = useSelector(({ vendor }) => vendor);
  const [activeTab, setActiveTab] = useState("buyer");
  const [email, setEmail] = useState("");

  console.log("ACTIVE TAB", setActiveTab);

  return (
    <>
      <SignupText mainColor={mainColor}>
        {activeTab === "customer" && (
          <>
            <div>sign up as customer</div>
            <h1>Become a Customer in our platform</h1>
          </>
        )}
        {activeTab === "vendor" && (
          <>
            <div>sign up as Vendor</div>
            <h1>Become a Vendor in our platform</h1>
          </>
        )}
        {activeTab === "buyer" && (
          <>
            <div>sign up as Customer</div>
            <h1>Become a Customer in our platform</h1>
          </>
        )}
      </SignupText>
      <TabNavigatorList>
        {/* <TabNavigatorListItem
          mainColor={mainColor}
          onClick={() => setActiveTab("customer")}
          isActive={activeTab === "customer" ? true : false}
        >
          customer
        </TabNavigatorListItem> */}
        <TabNavigatorListItem
          mainColor={mainColor}
          onClick={() => setActiveTab("buyer")}
          isActive={
            activeTab === "buyer" || activeTab === "stepTwo" ? true : false
          }
        >
          Buyer
        </TabNavigatorListItem>
        <TabNavigatorListItem
          mainColor={mainColor}
          onClick={() => setActiveTab("vendor")}
          isActive={activeTab === "vendor" ? isVendor || true : false}
        >
          Vendor
        </TabNavigatorListItem>
      </TabNavigatorList>

      {activeTab === "customer" && <RegisterStepOne />}
      {activeTab === "stepTwo" && <BuyerSignupForm email={email} />}
      {activeTab === "vendor" && <VendorSignupForm />}
      {activeTab === "buyer" && (
        <RegisterStepOne setActiveTab={setActiveTab} setEmail={setEmail} />
      )}
    </>
  );
};

export default TabsNavigator;
// import React from "react";
// import { useState } from "react";
// import { useSelector } from "react-redux";
// import {
//   SignupText,
//   TabNavigatorList,
//   TabNavigatorListItem,
// } from "../../pages/registration/RegistrationEl";
// import BuyerSignupForm from "../buyer-signup-form";
// import CustomerSignupForm from "../customer-signup-form";
// import VendorSignupForm from "../vendor-signup-form";

// const TabsNavigator = () => {
//   const { mainColor } = useSelector(({ ui }) => ui);
//   const { isVendor } = useSelector(({ vendor }) => vendor);
//   const [activeTab, setActiveTab] = useState("stepOne");
//   const [email, setEmail] = React.useState("");

//   return (
//     <>
//       <SignupText mainColor={mainColor}>
//         <>
//           <div>sign up as Buyer</div>
//           <h1>Become a Customer in our platform</h1>
//         </>
//       </SignupText>

//       {activeTab === "stepTwo" && <BuyerSignupForm email={email} />}
//       {activeTab === "stepOne" && (
//         <RegisterStepOne setEmail={setEmail} setActiveTab={setActiveTab} />
//       )}
//     </>
//   );
// };

// export default TabsNavigator;
