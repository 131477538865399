import styled from "styled-components";

export const DetailsWrapper = styled.div``;
export const InfoWrapper = styled.div``;
export const InfoTitle = styled.h4`
  margin-top: 32px;
  font-size: 1.1rem;
`;

export const ContactInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #666;
  font-size: 0.9rem;
  margin-top: 12px;
`;
export const ContactInfoValue = styled.p`
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  color: #666;
  font-size: 0.9rem;
  line-height: 1.8;
  margin: 0;
`;
export const OpeningHoursValue = styled(ContactInfoValue)``;
export const ContactMail = styled.a`
  padding: ${(props) => props.padding ?? "0 0 0 16px"};
`;
