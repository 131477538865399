import { Container } from '../../constants';
import { AdvertisementSeparatorLink } from './AdvertisementSeparatorEl';

const AdvertisementSeparator = ({ imgSrc }) => {
   return (
      <Container>
         <AdvertisementSeparatorLink to="/" style={{}}>
            <img src={imgSrc} alt="" style={{ width: '100%', height: '100%', objectFit: 'fill' }} />
         </AdvertisementSeparatorLink>
      </Container>
   );
};

export default AdvertisementSeparator;
