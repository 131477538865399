import {
  CategoriesAllPageMightAlsoLikeImg,
  CategoriesAllPageMightAlsoLikeName,
} from "../categories-all-page-might-also-like/CategoriesAllPageMightAlsoLikeEl";
import {
  CategoriesAllPageSimilarProductsBox,
  CategoriesAllPageSimilarProductsFooter,
  CategoriesAllPageSimilarProductsItem,
  CategoriesAllPageSimilarProductsWrap,
  CategoriesAllPageSimilarTitle,
} from "./CategoriesAllPageSimilarProductsEl";
import { MdAddShoppingCart } from "react-icons/md";
import { Rate } from "antd";
import similarData from "./similar-data";
import { ShopNowBox } from "../you-may-like/YouMayLikeEl";
import { useSelector } from "react-redux";

const CategoriesAllPageSimilarItem = ({ id, image1, name }) => {
  console.log("THE NAMEEE", name);
  const path = useSelector(
    (state) => state.homepage?.data?.featuredProducts?.path
  );
  return (
    <CategoriesAllPageSimilarProductsItem>
      <CategoriesAllPageMightAlsoLikeImg to={`/product-details/${id}`}>
        <img src={`${path}/${image1}`} alt="" style={{ height: 65 }} />
      </CategoriesAllPageMightAlsoLikeImg>
      <div style={{ width: "85%" }}>
        <CategoriesAllPageMightAlsoLikeName to="/product-details/10">
          {name?.en}
        </CategoriesAllPageMightAlsoLikeName>
        <CategoriesAllPageSimilarProductsFooter>
          <Rate disabled defaultValue={5} />
          <MdAddShoppingCart />
        </CategoriesAllPageSimilarProductsFooter>
      </div>
    </CategoriesAllPageSimilarProductsItem>
  );
};

const CategoriesAllPageSimilarProducts = ({ product }) => {
  const { mainColor } = useSelector(({ ui }) => ui);
  console.log("SIMILAR PRODUCTS", product);

  return (
    <CategoriesAllPageSimilarProductsWrap>
      <CategoriesAllPageSimilarTitle>
        Similar Products
      </CategoriesAllPageSimilarTitle>
      <CategoriesAllPageSimilarProductsBox>
        {product?.related_products?.map((item, idx) => (
          <CategoriesAllPageSimilarItem key={idx} {...item} />
        ))}
        <ShopNowBox
          to="/product-details/10"
          style={{ margin: "15px auto" }}
          mainColor={mainColor}
        >
          shop now
        </ShopNowBox>
      </CategoriesAllPageSimilarProductsBox>
    </CategoriesAllPageSimilarProductsWrap>
  );
};

export default CategoriesAllPageSimilarProducts;
