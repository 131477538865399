import { AiOutlinePlus } from "react-icons/ai";
import { SiAmericanexpress } from "react-icons/si";
import { FaCcMastercard, FaCcDiscover } from "react-icons/fa";
import {
  CustomerPaymentTabContentHeader,
  CustomerPaymentTabContentRow,
  CustomerPaymentTabContentWrap,
} from "../CustomerBuyerDashboardEl";
import { FaCcVisa } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";

const CustomerPaymentTabContent = () => {
  return (
    <CustomerPaymentTabContentWrap>
      <CustomerPaymentTabContentHeader>
        <h4>Payments</h4>
        <AiOutlinePlus />
      </CustomerPaymentTabContentHeader>
      <CustomerPaymentTabContentRow>
        <div>
          <FaCcVisa />
          <span>Visa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CustomerPaymentTabContentRow>
      <CustomerPaymentTabContentRow>
        <div>
          <SiAmericanexpress />
          <span>Visa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CustomerPaymentTabContentRow>
      <CustomerPaymentTabContentRow>
        <div>
          <FaCcMastercard />
          <span>Visa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CustomerPaymentTabContentRow>
      <CustomerPaymentTabContentRow>
        <div>
          <FaCcDiscover />
          <span>Visa ending in 9852</span>
        </div>
        <span>07/2023</span>

        <BsChevronDown />
      </CustomerPaymentTabContentRow>
    </CustomerPaymentTabContentWrap>
  );
};

export default CustomerPaymentTabContent;
