import styled from "styled-components";

export const AppColorSwitcherWrap = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  background-color: ${({ mainColor }) => mainColor};
  color: #fff;
  padding: 10px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  z-index: 9999;
  border: 1px solid #fff;
`;
