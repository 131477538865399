import React from "react";
import { useCallback } from "react";
import CustomInput from "../custom-input";
import CustomRadio from "../custom-radio";
import CustomCheckbox from "../custom-checkbox";
import CustomSelect from "../custom-select";
import CustomDate from "../custom-datepicker";
import CustomRate from "../custom-rate";
import { FormRow, InputWrapper, FormLabel } from "../constants";
import { ErrorMessage } from "@hookform/error-message";
import FormErrors from "../../form-errors";

const FormHandler = ({ register, control, formFields, errors }) => {
  const handleFields = useCallback(
    (field, idx) => {
      return (
        <InputWrapper
          style={field?.style?.inputWrapper}
          {...field?.style?.inputWrapper}
        >
          {field.type === "rate" && (
            <CustomRate name={field.name} id={field.name} control={control} />
          )}
          {field.type === "radio" && (
            <CustomRadio
              options={field.options}
              name={field.name}
              control={control}
            />
          )}
          {field.type === "checkbox" && (
            <CustomCheckbox
              options={field.options}
              name={field.name}
              control={control}
            />
          )}
          {field.type === "select" && (
            <CustomSelect
              size={field.size ?? "large"}
              control={control}
              name={field.name}
              placeholder="Supplier Response"
              style={{ width: "100%" }}
              options={[{ label: "Option 1", value: 1 }]}
            />
          )}
          {["textarea", "email", "text", "number", "password"].includes(
            field.type
          ) && (
            <CustomInput
              name={field.name}
              id={field.name}
              register={register}
              control={control}
              type={field.type}
              placeholder={field.placeholder || field.labelText}
              icon={field.icon}
            />
          )}
          {field.type === "multi" && (
            <FormRow justifyContent="space-between" margin="0">
              {field.fields.map((childField, childIdx) =>
                handleFields(childField, idx + childIdx)
              )}
            </FormRow>
          )}
          {field.type === "date" && (
            <CustomDate
              name={field.name}
              control={control}
              style={{ width: "100%" }}
            />
          )}
          {errors && (
            <ErrorMessage
              errors={errors}
              name={field.name}
              render={FormErrors}
            />
          )}
        </InputWrapper>
      );
    },
    [control, errors, register]
  );

  const renderFields = useCallback(() => {
    return formFields.map((field, idx) => {
      return (
        <FormRow
          key={`${field.name}`}
          style={field?.style?.formRow}
          {...field?.style?.formRow}
        >
          {field.labelText && (
            <FormLabel
              style={field?.style?.label}
              {...field?.style?.label}
              htmlFor={field.name}
            >
              {field.labelText}
            </FormLabel>
          )}
          {handleFields(field, idx)}
        </FormRow>
      );
    });
  }, [handleFields, formFields]);

  return <>{renderFields()}</>;
};

export default FormHandler;
