import localCompareTypes from "./localCompare.types";

const initialState = {
    loading: true,
    compareList: [],
    error: false,
};

export const localCompareReducer = (state = initialState, action) => {
    // const index = action.payload?.index
    // const quantity = action.payload?.quantity
    switch (action.type) {
        case localCompareTypes.ADD_ITEM:
            return {
                ...state,
                compareList: state.compareList.concat([action.payload]),
                loading: true,
                error: false,
            };
        case localCompareTypes.DELETE_ITEM:
            return {
                ...state,
                compareList: state.compareList.filter(item => item?.id !== action.payload),
                loading: true,
                error: false,
            };
        case localCompareTypes.GET_COMAPARE:
            return {
                ...state,
                compareList: state.compareList.filter(item => item?.item?.id !== 2222222222222222),
                loading: true,
                error: false,
            };
        case localCompareTypes.CLEAR_COMPARE:
            return {
                ...state,
                compareList: [],
                loading: true,
                error: false,
            };
        default:
            return state;
    }
};