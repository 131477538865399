import React from 'react'
import DeviceCard from './DeviceCard'
import ProductCard from './ProductCard'

function RecentDevices({ products }) {
    return (
        <div>
            <h1 style={{ textAlign: 'center', color: '#000', marginTop: '50px' }}>Recent Devices</h1>
            <div className='recent-devices-wrapper'>
                <div style={{ flex: 1, background: '#0077F9' }}></div>
                <div style={{ flex: 3 }}>
                    <div style={{ marginTop: '-18px', display: 'flex', justifyContent: 'space-around' }}>
                        <div className='devices-wrapper'>
                            {
                                products?.map((item, idex) => {
                                    return (
                                        <DeviceCard item={item} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecentDevices