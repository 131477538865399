import { useSelector } from "react-redux";
import { VendorBuyerDashboardSwitcherItemWrap } from "../../pages/vendor-buyer-dashboard/VendorBuyerDashboardEl";
import VendorSwitcherData from "./vendor-switcher-data";

const VendorBuyerDashboardSwitcherItem = ({
  activeTab,
  setActiveTab,
  name,
  title,
  icon,
}) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <VendorBuyerDashboardSwitcherItemWrap
      to="vendor-grid"
      smooth
      duration={500}
      offset={-150}
      isActiveTab={activeTab === name ? true : false}
      onClick={() => setActiveTab(name)}
      mainColor={mainColor}
    >
      <span>{icon}</span>
      <span>{title}</span>
    </VendorBuyerDashboardSwitcherItemWrap>
  );
};

const VendorBuyerDashboardSwitcher = ({ activeTab, setActiveTab }) => {
  return (
    <div>
      {VendorSwitcherData.map((item, idx) => (
        <VendorBuyerDashboardSwitcherItem
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          key={idx}
          {...item}
        />
      ))}
    </div>
  );
};

export default VendorBuyerDashboardSwitcher;
