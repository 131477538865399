import React from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { BsFilterLeft } from "react-icons/bs";
import CategoriesAllHero from "../../components/categories-all-hero";
import CategoriesAllPageMain from "../../components/categories-all-page-main";
import CategoriesAllPageMightAlsoLike from "../../components/categories-all-page-might-also-like";
import CategoriesAllPageSidebar from "../../components/categories-all-page-sidebar";
// import CategoriesFeaturesNav from "../../components/categories-features-nav";
import CategoriesNavigator from "../../components/categories-navigator";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import { Container } from "../../constants";
import {
  CategoriesAllMobileFilter,
  CategoriesAllPageGrid,
  CategoriesAllPageGridContent,
} from "./CategoriesAllPageEl";
import containerVariants from "../../animation";
import CategoriesFilterSidebar from "../../components/categories-filter-sidebar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleCategoriesFilterSidebar } from "../../redux/ui/ui.actions";
import api from "../../api";
import { useParams } from "react-router-dom";

const CategoriesAllPage = () => {
  const { categoriesFilerSidebar } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();

  const [data, setData] = React.useState();

  const { mainId, subId, childId } = useParams();

  const getData = () => {
    api
      .get(
        childId
          ? `/get-products-with-keys?main=${mainId}&sub=${subId}&child=[${childId}]`
          : subId
          ? `/get-products-with-keys?main=${mainId}&sub=${subId}`
          : `/get-products-with-keys?main=${mainId}`
      )
      .then((res) => {
        console.log(res?.data, "API DATA");
        setData(res?.data);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
    >
      <Container>
        <PagesBreadCrumb prev="units" curr="Dentals Units" />
      </Container>
      <CategoriesNavigator />
      <CategoriesAllHero />
      {/* <CategoriesFeaturesNav /> */}
      <Container>
        <CategoriesAllMobileFilter
          onClick={() => dispatch(toggleCategoriesFilterSidebar())}
        >
          <BsFilterLeft />
          <span>Filter</span>
        </CategoriesAllMobileFilter>
      </Container>
      <CategoriesAllPageGrid>
        <Container>
          <CategoriesAllPageGridContent>
            <CategoriesAllPageSidebar setData={setData} />
            <CategoriesAllPageMain products={data?.products} />
            <CategoriesAllPageMightAlsoLike />
          </CategoriesAllPageGridContent>
        </Container>
      </CategoriesAllPageGrid>

      {categoriesFilerSidebar && <CategoriesFilterSidebar />}
    </motion.div>
  );
};

export default CategoriesAllPage;
