import styled from 'styled-components';
import { Link } from 'react-router-dom';

// solution 1
// export const AdvertisementSeparatorWrap = styled.div`
//    border: 1px solid red;
// `;

// solution 2
export const AdvertisementSeparatorLink = styled(Link)`
   height: 120;
   width: fit-content;
   display: block;
   margin: 50px auto;
   position: relative;

   &::after,
   &::before {
      transition: 0.7s;
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #d0d0d05c;
      width: 100%;
      height: 100%;
      opacity: 0;
   }

   &:hover {
      &::after {
         opacity: 1;
         width: 0;
      }
      &::before {
         opacity: 1;
         height: 0;
      }
   }
`;
