import FacebookLogin from "react-facebook-login";

const FacebookLoginComp = () => {
  const responseFacebook = response => {
    // console.log(response);
  };

  return (
    <div>
      <FacebookLogin
        appId="1088597931155576"
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="my-facebook-button-class"
        icon="fa-facebook"
        textButton="facebook"
      />
    </div>
  );
};

export default FacebookLoginComp;
