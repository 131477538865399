import { Skeleton } from 'antd';

const SkeletonComp = (style) => {
  return (
    <div>
      <Skeleton.Image style={{ width: 250, height: 200 }} />
      <br />
      <br />
      <Skeleton.Input style={{ width: 250 }} active size="small" />
      <br />
      <br />
      <Skeleton.Input style={{ width: 150 }} active size="small" />
      <br />
      <br />
      <Skeleton.Button active size shape="square" block />
    </div>
  );
};

export default SkeletonComp;
