import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { appLink, mainColor } from "../../constants";

const ProductItemOfferSpan = css`
  position: absolute;
  top: 5px;
  right: -15px;
  color: #fff;
  z-index: 500;
  padding: 0px 22px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  clip-path: polygon(25% 0%, 80% 0, 78% 100%, 25% 100%, 0% 50%);
`;

export const ShopProductItemWrap = styled.div`
  box-shadow: ${({ isGrid }) => (isGrid ? "none" : "1px 1px 9px 5px #e6e6e6")};
  display: flex;
  flex-direction: ${({ isGrid }) => (isGrid ? "column" : "row")};
  /* border: 2px solid red; */
`;

export const ShopProductImgWrap = styled(Link)`
  overflow: hidden;
  position: relative;
  border-radius: ${({ isGrid }) => (isGrid ? "6px" : "0px")};

  img {
    /* width: ${({ isGrid }) => (!isGrid ? "100%" : "250px")}; */
    width: 100px;
    height: 200px;
  }
`;

export const ShopProductSaleSpan = styled.div`
  ${ProductItemOfferSpan};
  background: #ff9000;
`;

export const ShopProductNewSpan = styled.div`
  ${ProductItemOfferSpan};
  background: #e64d4d;
`;

export const ShopProductOfferSpan = styled.div`
  ${ProductItemOfferSpan};
  background: #7eca11;
`;

export const ShopProductHotSpan = styled.div`
  ${ProductItemOfferSpan};
  background: #d24242;
`;

export const SVGWrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    cursor: pointer;
    font-size: 25px;
    color: ${({ mainColor }) => mainColor};
    margin-bottom: 10px;
  }
`;

export const ShopProductName = styled(Link)`
  color: ${appLink};
  margin: 10px 0;
  display: block;
  transition: 0.4s ease-in-out;
  font-weight: 500;

  &:hover {
    color: ${mainColor};
  }
`;

export const ShopProductRating = styled.div`
  display: flex;
  align-items: center;

  ul li {
    font-size: 0.9rem;
    line-height: 31px;
  }
`;

export const ShopProductFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;

  svg {
    font-size: 1rem;
    cursor: pointer;
  }
`;

export const ProductModalContent = styled.div`
  display: grid;
  grid-template-columns: 400px 2fr;
  gap: 20px;

  img {
    width: 100% !important;
    height: 100% !important;
    max-height: 350px !important;
  }
`;

export const ProductModalInfo = styled.div``;

export const ProductModalInfoCartBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
