import sanitizeHtml from "sanitize-html";

const formatRichText = (richText) => {
  if (!richText) return "";
  const emptyTagReplacement = /<([^>]+)\s*>\s*<\/\1\s*>/gi;
  const spaceReplacement = new RegExp(String.fromCharCode(160), "g");
  const replacement = /(style=".*") | (class=".*")/gi;

  console.log(sanitizeHtml(richText).replace(replacement, " "));

  return sanitizeHtml(richText)
    .replace(spaceReplacement, " ")
    .replace(emptyTagReplacement, " ")
    .replace(replacement, " ");
};

export default formatRichText;
