// TODO If you want ot animate the page with transform uncomment x: properties

const containerVariants = {
  hidden: {
    opacity: 0,
    // x: "100vw",
  },
  visiable: {
    opacity: 1,
    // x: 0,
    transition: { delay: 0.1, type: "spring" },
  },
  exit: {
    // x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

export default containerVariants;
