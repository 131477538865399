import styled from 'styled-components';
import { mainColor, screenMd } from './../../constants';

export const HomepageCarouselWrap = styled.div`
   /* z-index: 2000; */

   .awssld__controls .awssld__next,
   .awssld__prev {
      width: 60px !important;
      overflow: hidden !important;
      height: 27px !important;
      top: calc(50% - (0.5 * 27px));
   }

   .awssld__controls__arrow-left:before,
   .awssld__controls__arrow-left:after,
   .awssld__controls__arrow-right:before,
   .awssld__controls__arrow-right:after {
      background-color: ${mainColor} !important;
   }

   .awssld__bullets button {
      width: 10px !important;
      height: 10px !important;
   }

   .awssld__bullets .awssld__bullets--active {
      background: ${mainColor};
   }

   .awssld__content {
      & > div {
         z-index: 8000;
         position: absolute;
         top: 55%;
         left: 15%;
         transform: translate(-10%, -50%);
      }
   }
   .awssld__wrapper {
      height: 400px;
   }
`;

export const CarouselItem = styled.div`
   h1 {
      font-size: 2rem;
      font-weight: 700;
   }
`;

export const CarouselTitle = styled.h1`
   line-height: 40px;
   text-transform: uppercase;
   font-size: 2rem;
   color: #fff;
   font-weight: 700;

   @media screen and (max-width: ${screenMd}) {
      font-size: 27px !important;
      line-height: 1.5;
   }
`;

export const CarouseSecondItem = styled.div``;

export const CarouselSecondTitle = styled.h1`
   line-height: 40px;
   text-transform: uppercase;
   font-weight: 600;
   font-size: 2rem;
   color: #fff;
`;

export const HomeCarouselFeatures = styled.div`
   display: flex;
   margin-top: 15px;

   @media screen and (max-width: ${screenMd}) {
      flex-direction: column !important;
      margin-top: 20px !important;
   }
`;
