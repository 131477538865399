import React from "react";
import AwesomeSlider from "react-awesome-slider";
import { secColor } from "../../constants";
import CustomLink from "../custom-link";
import {
  CarouselItem,
  CarouselTitle,
  HomepageCarouselWrap,
} from "./HomepageCarouselEl";

// Carousel Styles
import "react-awesome-slider/dist/styles.css";
import AwesomeSliderStyles from "react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss";
import { useSelector } from "react-redux";
import SliderSkeleton from "../slider-skeleton";
import { useHistory } from "react-router-dom";
import api from "../../api";

const HomepageCarousel = ({ setCatId, catId, data }) => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const path = data?.path;
  const history = useHistory();
  const categories = useSelector(
    (state) => state.homepage?.data?.categories_subcategories_childcategories
  );

  // Styles
  const btnStyle = {
    zIndex: 9999,
    backgroundColor: mainColor,
    color: secColor,
    padding: "5px 20px",
    marginBottom: 20,
    display: "block",
    borderRadius: "5px",
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: ".8rem",
  };

  if (!data) {
    return <SliderSkeleton />;
  }

  const currentCat = categories?.find((item) => item?.id === catId);

  console.log(categories ? categories[catId] : null, "CATEGORIES");

  return (
    <HomepageCarouselWrap style={{ position: "relative" }}>
      <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>
        {data?.items?.map(({ id, image, title, content }) => (
          <CarouselItem
            key={id}
            data-src={`${path}/${image}`}
            alt={title}
            style={{ zIndex: 300 }}
            onClick={() => history.push(`/categories/main/${catId}`)}
          >
            <CarouselTitle>
              {content} <br /> {title}
            </CarouselTitle>
            <CustomLink to="/" text="shop now" style={btnStyle} />
          </CarouselItem>
        ))}
      </AwesomeSlider>
      <div
        onMouseEnter={() => setCatId(catId)}
        onMouseLeave={() => setCatId(2000000000)}
        style={{
          height: "100%",
          width: catId === 2000000000 ? "0%" : "100%",
          background: "white",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "10000000",
          opacity: catId === 2000000000 ? "0" : "1",
          transition: "1s ease",
          marginLeft: "-10px",
        }}
      >
        <div style={{ display: "flex" }}>
          {categories
            ? currentCat?.subcategories?.map((subItem, index) => {
                return (
                  <div>
                    <h3
                      onClick={() =>
                        history.push(
                          `/categories/sub/${subItem.id}/main/${catId}`
                        )
                      }
                      style={{ marginLeft: "40px", cursor: "pointer" }}
                      key={index}
                    >
                      {subItem?.title?.en}
                    </h3>
                    {subItem?.childcategory?.map((item, index) => {
                      return (
                        <div
                          style={{
                            marginLeft: "40px",
                            marginBottom: "5px",
                            cursor: "pointer",
                          }}
                          key={index}
                          onClick={() =>
                            history.push(
                              `/categories/child/${item.id}/main/${catId}/sub/${subItem.id}`
                            )
                          }
                        >
                          {item?.title?.en}
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </HomepageCarouselWrap>
  );
};

export default HomepageCarousel;
