import styled from "styled-components";
import { screenMd } from "../../constants";

export const NewCollectionWrap = styled.div`
  margin: 80px auto;
  width: 85%;

  .BrainhubCarousel__arrows {
    background-color: ${({ mainColor }) => mainColor} !important;
  }

  @media screen and (max-width: ${screenMd}) {
    display: none;
  }
`;
