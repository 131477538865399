import styled from "styled-components";
import { screenLg } from "../../../constants";
import { ColorCircleItem, SizeCircleItem } from "../../common-styled-componnts";

export const DetailsWrapper = styled.div`
  width: 49%;
  height: fit-content;
  /* border: 1px solid red; */

  @media screen and (max-width: ${screenLg}) {
    width: 100%;
    padding: 24px 0;
  }

  .all-price {
    display: flex;
    width: fit-content;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    /* padding: 10px; */
    margin-top: 15px;
  }

  .all-price .price {
    padding: 10px 0 10px 20px;
  }

  .all-price .price:not(:last-of-type) {
    padding: 10px;
    margin-right: 1px solid;
  }
  .all-price .price:nth-child(2) {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }

  .Quantity {
    font-weight: bold;
  }
  .Quantity span {
    color: #9d9d9d;
  }

  .lead-time {
    display: flex;
    padding: 10px;
  }
  .lead-time .left {
    margin-right: 20px;
  }
  .lead-time .right {
    width: 100%;
  }

  .selected-pro-content .lead-time table,
  .selected-pro-content th,
  .selected-pro-content td {
    border: 1px solid black;
  }

  .lead-time table {
    width: 100%;
  }

  .lead-time table th,
  .lead-time table td {
    /* text-align: center; */
  }

  .leattime-table {
    width: 80% !important;
  }

  .leattime-table table th {
    text-align: left !important;
  }

  .shipping,
  .protection {
    display: flex;
    margin-top: 5px;
  }

  .shipping .shipping-left,
  .protection .protection-left {
    margin-right: 10px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      font-size: 1.2rem;
    }
  }
  .shipping .shipping-right,
  .protection .protection-right {
    display: flex;
    align-items: center;
  }

  .vendor-notes-wrap {
    margin-top: 10px;
  }
  .vendor-notes {
    margin-left: 20px;
  }
  .protection-right svg:first-child {
    color: rgb(199 165 41);
  }
  .protection-right svg {
    font-size: 20px;
    transform: translate(0, 6px);
  }
`;
export const DetailsBadge = styled.div`
  padding: 4px 24px;
  color: white;
  background: ${({ mainColor }) => mainColor};
  display: inline-block;
  border-radius: 4px;
`;
export const DetailsTitle = styled.h1`
  font-size: 1.5rem;
  margin: 10px 0;
`;
export const DetailsSectionWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent ?? "space-between"};
  align-items: center;
  margin: ${props => props.margin};

  @media screen and (max-width: ${screenLg}) {
    flex-direction: column;
    align-items: flex-start;

    > button {
      width: 100%;
      :not(:first-of-type) {
        margin: 12px 0;
      }
    }
  }
`;

export const PriceWrapper = styled.h4`
  font-size: 1rem;
`;
export const RateDescription = styled.div`
  margin-left: 12px;

  @media screen and (max-width: ${screenLg}) {
    margin-left: 0px;
    margin-top: 12px;
  }
`;

export const DetailsSectionDescription = styled.p`
  /* line-height: 1.8; */
  font-size: 0.9rem;
  padding: 10px 0px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent ?? "flex-start"};
  align-items: ${props => props.alignItems ?? "baseline"};
`;

export const FilterName = styled.h5`
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  margin-right: 15px;
`;

export const SizeFilter = styled(SizeCircleItem)`
  margin: 0 5px;
`;

export const ColorFilter = styled(ColorCircleItem)`
  margin: 0 5px;
`;

export const Qty = styled.div`
  padding: 0 24px;
  font-weight: 600;

  @media screen and (max-width: ${screenLg}) {
    padding: 12px;
  }
`;
