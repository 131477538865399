import { Link } from "react-router-dom";
import styled from "styled-components";
import { mainColor } from "../../../constants";

export const LinkWrapper = styled.div`
  text-align: center;
  margin-top: 12px;
`;
export const OrderLink = styled(Link)`
  color: ${mainColor};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${mainColor};
  }
`;
export const FormDescription = styled.p`
  line-height: 1.8;
`;
export const ElementWrapper = styled.div`
  margin: 42px 0;
`;
export const StepsWrapper = styled(ElementWrapper)`
  .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${mainColor};
  }
  .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: ${mainColor};
  }

  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    color: ${mainColor};
  }
`;
