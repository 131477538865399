import { Link } from "react-router-dom";
import styled from "styled-components";

export const CheckoutArrowsWrap = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
`;

export const CheckoutSaveAddressWrap = styled.div`
  .ant-form-item {
    margin-bottom: 15px !important;
  }

  .ant-form-item-control-input {
    height: 35px !important;
    /* margin: 15px 0 10px 0 !important; */
    margin-bottom: 15px;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    font-weight: 500 !important;
    font-size: 0.8rem !important;
  }
`;

export const CheckoutArrowItem = styled.div`
  clip-path: polygon(75% 0%, 90% 50%, 75% 100%, 0% 100%, 10% 50%, 0% 0%);
  flex: 1;
  background-color: ${({ mainColor }) => (mainColor ? mainColor : "#eeeeee")};
  color: ${({ mainColor }) => (mainColor ? "#fff" : "#000")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px;

  &:hover {
    color: ${({ mainColor }) => (mainColor ? "#fff" : mainColor)};
  }
`;

export const ChosenPaymentMethodWrap = styled.div`
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
    0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  border: 2px solid
    ${({ mainColor }) => (mainColor ? mainColor : "transparent")};
`;

export const ChosenPaymentMethodGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

export const ChosenPaymentMethodCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ChosenPaymentMethodCardFooter = styled.div`
  color: #7c7c7c;
`;

export const CheckoutOrderNowRowWrap = styled.div`
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 15px;
  display: flex;

  h2 {
    color: #323232d9;
    border-bottom: 2px solid #0077f9;
    display: inline-block;
  }

  label {
    font-size: 0.8rem;
    color: #999;
    display: block;
  }

  h5 {
    font-size: 1rem;
  }
`;

export const CheckoutOrderNowRow = styled.div`
  flex: 1;
`;

export const CheckoutOrderNowPaymentMethodWrap = styled.div`
  padding-top: 15px;
  display: flex;

  h2 {
    color: #323232d9;
    border-bottom: 2px solid #0077f9;
    display: inline-block;
  }

  label {
    font-size: 0.8rem;
    color: #999;
    display: block;
  }

  h5 {
    font-size: 1rem;
  }

  & > div {
    flex: 1;
  }
`;
