import styled from "styled-components";

export const ProductsCategoriesSectionWrap = styled.section`
  margin-top: 50px;
  height: 800px;
`;

export const ProductsCategoriesTitle = styled.h1`
  width: fit-content;
  margin: 30px 0 20px 0;
  color: #333;
  font-size: 2rem;
  font-weight: 400;
`;

export const ProductsCategoriesGrid = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
`;
