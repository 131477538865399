import buyerTypes from "./buyer.types";

const initialState = { loading: false, data: {}, error: false };

const buyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case buyerTypes.BUYER_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: false,
      };
    case buyerTypes.BUYER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case buyerTypes.BUYER_REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload.response,
      };
    case buyerTypes.VENDOR_CLEAR_FIELDS:
      return {
        ...state,
        loading: false,
        data: {},
        error: {},
      };

    case buyerTypes.BUYER_LOGOUT:
      return {
        loading: false,
        data: {},
        error: {},
      };

    default:
      return state;
  }
};

export default buyerReducer;
