import styled from "styled-components";
import { mainColor } from "../../../constants";

export const CustomInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CustomInputLabel = styled.label`
  margin-bottom: 7px;
  font-weight: 500;
  color: #6f6f6f;
`;

export const CustomInputStyle = styled.input`
  all: unset;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
  transition: 0.4s ease-in-out;
  padding-left: ${(props) => props.paddingLeft};
  &:focus {
    border: 1px solid ${mainColor} !important;
  }
`;

export const InputIcon = styled.div`
  all: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${mainColor};
`;
export const InputButtonIcon = styled.button`
  all: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  z-index: 9999;
`;
