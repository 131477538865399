import styled from "styled-components";
import { screenLg } from "../../../constants";

export const TitleWrapper = styled.div``;
export const TitleContent = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: color-burn;
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: 300;
  color: white;
  margin: 0;
  padding: 0;
  letter-spacing: -1px;

  @media screen and (max-width: ${screenLg}) {
    font-size: 2.8rem;
  }
`;
