import styled from "styled-components";

export const RichTextWrapper = styled.div`
  * {
    font-size: 0.9rem;
    padding-top: 10px;
    font-weight: 500;
    line-height: 1.8;
  }
  h1 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.1rem;
  }
  strong {
    font-weight: 700;
  }
`;
