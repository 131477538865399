import { useState } from "react";
import {
  BoxTitle,
  CategoriesFilterSidebarWrap,
  ColorCircleWrap,
  ColorsOptions,
  OptionsWrapper,
  SidebarBoxWrap,
} from "./categoriesFilterSidebarEl";
import { Input, Checkbox, Rate, Tooltip, Slider } from "antd";
import { ColorCircleItem } from "../common-styled-componnts";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "../mobile-sidebar/MobileSidebarEl";
import { CloseOutlined } from "@ant-design/icons";
import { toggleCategoriesFilterSidebar } from "../../redux/ui/ui.actions";

const CategoriesFilterSidebar = () => {
  const { categoriesFilerSidebar } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();

  const [rateVal, setRateVal] = useState(3);
  // eslint-disable-next-line no-unused-vars
  const [isPriceDisabled, setIsPriceDisbled] = useState(false);
  const { Search } = Input;
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  const onSearch = value => console.log(value);

  const onChange = e => {
    console.log(`checked = ${e.target.checked}`);
  };

  const ratingHandleChange = value => {
    setRateVal(value);
  };

  return (
    <CategoriesFilterSidebarWrap isOpen={categoriesFilerSidebar}>
      <SidebarBoxWrap>
        <BoxTitle style={{ marginTop: 30 }}>Brand</BoxTitle>
        <Search placeholder="Search..." onSearch={onSearch} enterButton />

        <OptionsWrapper>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Infection</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Congenital disorders</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Cardiovascular</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Injuries and accidents</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Mental health</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Musculoskeletal</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Skin</Checkbox>
          </div>
        </OptionsWrapper>
      </SidebarBoxWrap>

      <SidebarBoxWrap>
        <BoxTitle>Categories</BoxTitle>

        <OptionsWrapper>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Neurology</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Ophthalmology</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Diagnostic radiology</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Pediatrics</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Preventive medicine</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Urology</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Surgery</Checkbox>
          </div>
          <div style={{ marginBottom: 7 }}>
            <Checkbox onChange={onChange}>Radiation oncology</Checkbox>
          </div>
        </OptionsWrapper>

        <OptionsWrapper>
          <BoxTitle>Rating</BoxTitle>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
        </OptionsWrapper>

        <ColorsOptions>
          <BoxTitle>Color</BoxTitle>
          <ColorCircleWrap>
            <Tooltip placement="top" title="#5fec11">
              <ColorCircleItem bg="#5fec11" />
            </Tooltip>
            <Tooltip placement="top" title="#ad2edb">
              <ColorCircleItem bg="#ad2edb" />
            </Tooltip>
            <Tooltip placement="top" title="#0b98ee">
              <ColorCircleItem bg="#0b98ee" />
            </Tooltip>
            <Tooltip placement="top" title="#22d1f2">
              <ColorCircleItem bg="#22d1f2" />
            </Tooltip>
            <Tooltip placement="top" title="#e3ec1d">
              <ColorCircleItem bg="#e3ec1d" />
            </Tooltip>
          </ColorCircleWrap>
        </ColorsOptions>

        {/* <ColorsOptions>
          <BoxTitle>Size</BoxTitle>
          <ColorCircleWrap>
            <Tooltip placement="top">
              <SizeCircleItem>xs</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem>s</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem isSelected>m</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem>l</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem isSelected>xl</SizeCircleItem>
            </Tooltip>
          </ColorCircleWrap>
        </ColorsOptions> */}
        <ColorsOptions>
          <BoxTitle>Price</BoxTitle>
          <Slider
            range
            defaultValue={[1, 500]}
            disabled={isPriceDisabled}
            mim={1}
            max={1000}
          />
        </ColorsOptions>
      </SidebarBoxWrap>

      <CloseIcon onClick={() => dispatch(toggleCategoriesFilterSidebar())}>
        <CloseOutlined />
      </CloseIcon>
    </CategoriesFilterSidebarWrap>
  );
};

export default CategoriesFilterSidebar;
