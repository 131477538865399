const commonStyles = {
  inputWrapper: {
    width: "fit-content",
  },
  formRow: {
    justifyContent: "center",
  },
};
const reviewFields = [
  {
    name: "on_time_shipment",
    labelText: "On-Time Shipment",
    type: "rate",
    style: commonStyles,
  },
  {
    name: "response",
    labelText: "Response",
    type: "rate",
    style: commonStyles,
  },
  {
    name: "overall_service",
    labelText: "Overall Service",
    type: "rate",
    style: commonStyles,
  },
  {
    name: "comment",
    placeholder: "Leave a Comment",
    type: "textarea",
    style: {
      ...commonStyles,
      inputWrapper: {
        width: "80%",
      },
    },
  },
];

export default reviewFields;
