import { Skeleton } from "antd";

const SliderSkeleton = () => {
  return (
    <>
      <Skeleton.Input
        style={{ width: "100%", height: 250 }}
        active
        size="large"
      />
    </>
  );
};

export default SliderSkeleton;
