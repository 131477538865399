import { Container } from "../../constants";
import { HomepageBestSellerSectionWrap } from "./HomepageBestSellerSectionEl";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { ProductsCategoriesTitle } from "../products-categories/ProductsCategoriesSectionEl";
import AliceCarousel from "react-alice-carousel";
import SliderProductItem from "../slider-product-item";
import SkeletonComp from "../Skelaton/Skelaton";
// import bestSellers from "./best-sellers-data";

const HomepageBestSellerSection = ({ data, path }) => {
  const responsive = {
    0: { items: 1 },
    768: { items: 1 },
    800: { items: 2 },
    992: { items: 3 },
    1024: { items: 5 },
  };

  if (!data) {
    return (
      <div
        className="Skeleton"
        style={{ display: "flex", justifyContent: "center", padding: "30px" }}
      >
        <div style={{ flex: 1 }}>
          <SkeletonComp style={{ border: "1px solid" }} />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
      </div>
    );
  }

  const items = data?.map((item, idx) => (
    <SliderProductItem
      path={path}
      key={idx}
      {...item}
      style={{ margin: "0 10px !important", width: "95%" }}
      isOffer={idx % 2 === 0}
    />
  ));

  return (
    <HomepageBestSellerSectionWrap>
      <Container>
        <ProductsCategoriesTitle>Featured Products</ProductsCategoriesTitle>
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          disableDotsControls
          autoPlay
          autoPlayInterval={4200}
          infinite
          renderNextButton={() => (
            <HiArrowNarrowRight
              style={{ fontSize: 25, cursor: "pointer", marginTop: 0 }}
            />
          )}
          renderPrevButton={() => (
            <HiArrowNarrowLeft
              style={{ fontSize: 25, cursor: "pointer", marginTop: 0 }}
            />
          )}
        />
      </Container>
    </HomepageBestSellerSectionWrap>
  );
};

export default HomepageBestSellerSection;
