import api from "../../api";
import userTypes from "../user/user.types";
import vendorTypes from "./vendor.types";

export const vendorRegisterAction =
  (vendorData, vendorInfo) => async dispatch => {
    dispatch({
      type: vendorTypes.VENDOR_REGISTER_REQUEST,
    });

    try {
      const { data } = await api.post("/vendor-register", vendorData);
      dispatch({
        type: vendorTypes.VENDOR_REGISTER_SUCCESS,
        payload: { ...data, vendorInfo },
      });

      // Login
      const { email, password } = vendorInfo;
      await api
        .post(`/customer/login?token=api`, { email, password })
        .then(({ data }) =>
          dispatch({
            type: userTypes.Login_USER_SUCCESS,
            payload: data,
          })
        )
        .catch(err => {
          dispatch({
            type: userTypes.Login_USER_ERROR,
            payload:
              err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message,
          });
        });
    } catch (err) {
      dispatch({
        type: vendorTypes.VENDOR_REGISTER_FAILED,
        payload:
          err.response && err.response.data.errors
            ? err.response.data.errors
            : err.message,
      });
    }
  };

export const logoutVendorAction = () => ({
  type: vendorTypes.VENDOR_LOGOUT,
});

export const vendorClearMessages = () => ({
  type: vendorTypes.VENDOR_CLEAR_FIELDS,
});

export const changeRegistrationActiveTab = () => ({
  type: vendorTypes.SET_REGISTRATION_ACTIVE_TAB,
});
