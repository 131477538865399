import { Radio } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const CustomRadio = ({ name, control, options }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <Radio.Group options={options} {...field} />;
      }}
    />
  );
};

export default CustomRadio;
