import api from "../../api";
import buyerTypes from "../buyer/buyer.types";
import userTypes from "./user.types";

export const loginUserAction = ({ email, password }, callBack) => {
  return async dispatch => {
    dispatch({
      type: userTypes.Login_USER_REQUEST,
    });
    api
      .post(`/login`, { email, password })
      .then(({ data }) => {
        console.log("DATA", data?.redirect)
        if (data?.redirect) {
          window.location.href = data?.redirect
        } else {
          callBack(data)
          dispatch({
            type: buyerTypes.BUYER_REGISTER_SUCCESS,
            payload: data,
          })
        }
      }
      )
      .catch(err => {
        dispatch({
          type: userTypes.Login_USER_ERROR,
          payload:
            err.response?.data?.msg
        });
      });
  };
};

export const logoutUserAction = () => ({
  type: userTypes.USER_LOGOUT,
});
