import { useSelector } from "react-redux";
import { IoIosCall } from "react-icons/io";
import { GoLocation, GoDeviceMobile } from "react-icons/go";
import { FaFacebookF, FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import { Container } from "../../constants";
import CustomButton from "../custom-button";
import Payment from "../../assets/images/payment-light.png";
import GooglePlayImg from "../../assets/images/google-play.png";
import AppleImg from "../../assets/images/app-store.png";
import {
  AppFooterSubscribeNowContent,
  AppFooterWrap,
  Column,
  ColumnTitle,
  FooterCopyRights,
  FooterMiddleSide,
  FooterMiddleSideAddress,
  FooterMiddleSideAddressSocial,
  FooterMiddleSideNewsLetter,
  FooterMiddleSideTimes,
  FooterRow,
  FooterWrapper,
  LinkItem,
  PaymentMethodsList,
} from "./AppFooterEl";

const styles = {
  color: "#fff",
  marginBottom: 10,
};

const AppFooter = () => {
  const { mainColor } = useSelector(({ ui }) => ui);
  // useEffect(() => {
  //   AOS.init({ duration: 2000 });
  // }, []);

  return (
    <>
      <AppFooterWrap>
        <FooterWrapper mainColor={mainColor}>
          <Container>
            <FooterRow>
              <Column>
                <ColumnTitle>Quick Links</ColumnTitle>
                <LinkItem to="/categories">All Categories</LinkItem>
                <LinkItem to="/categories">Category</LinkItem>
                <LinkItem to="/product-details/10">Product Details</LinkItem>
                <LinkItem to="/registration">Register</LinkItem>
                <LinkItem to="/review-supplier">Review Suppliers</LinkItem>
                <LinkItem to="/shopping-cart">Shopping Cart</LinkItem>
                <LinkItem to="/whishlist">Favorites</LinkItem>
                <LinkItem to="/checkout/save-address">Checkout</LinkItem>
              </Column>

              <Column>
                <ColumnTitle>Services</ColumnTitle>
                <LinkItem to="/compliant">Complaint</LinkItem>
                <LinkItem to="/send-inquiry">Send Inquiry</LinkItem>
                <LinkItem to="/request-bid">Request Bid</LinkItem>
                <LinkItem to="/order-tracking">Order Tracking</LinkItem>
                <LinkItem to="/order-tracking/result">
                  Order Tracking Result
                </LinkItem>
                <LinkItem to="/customer-buyer-dashboard">
                  Customer Dashboard
                </LinkItem>
                <LinkItem to="/vendor-buyer-dashboard">
                  Vendor Dashboard
                </LinkItem>
                <LinkItem to="/corporate-buyer-dashboard">
                  Corporate Dashboard
                </LinkItem>
              </Column>

              <Column>
                <ColumnTitle>Let us help you</ColumnTitle>
                <LinkItem to="/terms-and-conditions">
                  Terms and Conditions
                </LinkItem>
                <LinkItem to="/privacy-policy">Privacy Policy</LinkItem>
                <LinkItem to="/payment-policy">Payment Policy</LinkItem>
                <LinkItem to="/refund-policy">Refund Policy</LinkItem>
                <LinkItem to="/return-policy">Return Policy</LinkItem>
                <LinkItem to="/shipping-policy">Shipping Policy</LinkItem>
                <LinkItem to="/terms-of-use">Terms of Use</LinkItem>
              </Column>
              <Column>
                <ColumnTitle>Company</ColumnTitle>
                <LinkItem to="/">About Us</LinkItem>
                <LinkItem to="/contact-us">Contact Us</LinkItem>
                <LinkItem to="/">How it Works</LinkItem>
                <LinkItem to="/">Vendors</LinkItem>
              </Column>
              <Column>
                <ColumnTitle>Contact Us</ColumnTitle>
                <div style={styles}>
                  <IoIosCall /> Tel: +202 22618703
                </div>
                <div style={styles}>
                  <GoDeviceMobile />
                  Mobile: +2 01063800003
                </div>
                <div style={styles}>
                  <GoLocation /> Email address: dento@gmail.com
                </div>
                <div style={styles}>
                  <GoLocation />
                  Address: 6 Youssef Abbas St. Nasr City, Cairo, Egypt
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 15,
                  }}
                >
                  <LinkItem to="/">
                    <img src={GooglePlayImg} alt="" />
                  </LinkItem>
                  <LinkItem to="/">
                    <img src={AppleImg} alt="" />
                  </LinkItem>
                </div>
              </Column>
            </FooterRow>

            {/* <FooterMiddleSide mainColor={mainColor}>
              <FooterMiddleSideTimes>
                <h1>XPOYA.COM</h1>
                <p>Monday – Friday: 9:00-20:00</p>
                <p>Saturday: 11:00 – 15:00</p>

                <a href="mailto:xpoya2021@gmail.com">xpoya2021@gmail.com</a>
              </FooterMiddleSideTimes>

              <FooterMiddleSideAddress>
                <p>
                  6 Youssef Abbas, St. Nasr City, <br /> Cairo, Egypt.
                </p>

                <FooterMiddleSideAddressSocial>
                  <FaFacebookF />
                  <FaWhatsapp />
                  <FaLinkedinIn />
                </FooterMiddleSideAddressSocial>
              </FooterMiddleSideAddress>

              <FooterMiddleSideNewsLetter>
                <AppFooterSubscribeNowContent>
                  <h1>Join our Newsletter</h1>
                  <form>
                    <input type="text" placeholder="Enter e-email address" />
                    <CustomButton
                      type="submit"
                      text="Subscribe"
                      style={{
                        padding: "10px 20px",
                        borderRadius: 0,
                        border: `1px solid #fff`,
                        borderLeft: "none",
                        backgroundColor: mainColor,
                      }}
                    />
                  </form>
                </AppFooterSubscribeNowContent>
              </FooterMiddleSideNewsLetter>
            </FooterMiddleSide> */}

            <FooterRow>
              <Column>
                <LinkItem to="/categories">About us</LinkItem>
                <LinkItem to="/categories">Delivery information</LinkItem>
                <LinkItem to="/refund-policy">Refund Policy</LinkItem>
              </Column>
              <Column>
                <LinkItem to="/categories">Affiliate</LinkItem>
                <LinkItem to="/categories">Sales</LinkItem>
                <LinkItem to="/terms-and-conditions">
                  Terms & Conditions
                </LinkItem>
              </Column>
              <Column>
                <LinkItem to="/order-tracking">Bestsellers</LinkItem>
                <LinkItem to="/categories">Discount</LinkItem>
                <LinkItem to="/categories">Latest products</LinkItem>
              </Column>
              <Column>
                <LinkItem to="/categories">My account</LinkItem>
                <LinkItem to="/categoriess">My orders</LinkItem>
                <LinkItem to="/return-policy">Returns</LinkItem>
              </Column>
              <Column>
                <LinkItem to="/shopping-cart">Shopping Cart</LinkItem>
                <LinkItem to="/whishlist">Wishlist</LinkItem>
                <LinkItem to="/shipping-policy">Shipping</LinkItem>
              </Column>
            </FooterRow>

            <FooterCopyRights>
              <p>
                © Copyright 2021 Dento Home . All Right Reserved. Powered By{" "}
                <a href="https://techvillageco.com/" style={{ marginLeft: 5 }}>
                  {" "}
                  Tech Village
                </a>
                .
              </p>
              <PaymentMethodsList>
                <p style={{ display: "flex", alignItems: "center" }}>
                  We Using Safe Payment For:{" "}
                </p>
                <div>
                  <img src={Payment} alt="payment" />
                </div>
              </PaymentMethodsList>
            </FooterCopyRights>
          </Container>
        </FooterWrapper>
      </AppFooterWrap>
    </>
  );
};

export default AppFooter;
