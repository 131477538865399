import { useEffect, useState } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import CustomerBuyerDashboardSwitcher from "../../components/customer-buyer-dashboard-switcher";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import { Container } from "../../constants";
import {
  CustomerBuyerDashboardGrid,
  CustomerBuyerDashboardTitle,
  CustomerBuyerDashboardWrap,
} from "./CustomerBuyerDashboardEl";
import CustomerAddressTabContent from "./utils/CustomerAddressTabContent";
import CustomerBasicsInfoTabContent from "./utils/CustomerBasicsInfoTabContent";
import CustomerOrdersTabContent from "./utils/CustomerOrdersTabContent";
import CustomerPaymentTabContent from "./utils/CustomerPaymentTabContent";
// import CustomerSettingsTabContent from "./utils/CustomerSettingsTabContent";
import CustomerWishlistTabContent from "./utils/CustomerWishlistTabContent";
import containerVariants from "../../animation";
import { useSelector } from "react-redux";

const CustomerBuyerDashboard = () => {
  const [activeTab, setActiveTab] = useState("basics");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);
  const user = useSelector((state) => state?.buyer?.data?.user_data);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="zoom-in-right"
    >
      <CustomerBuyerDashboardWrap>
        <Container>
          <PagesBreadCrumb prev="home" curr={user?.name} />
          <CustomerBuyerDashboardTitle>
            {user?.name}
          </CustomerBuyerDashboardTitle>

          <CustomerBuyerDashboardGrid id="customer-grid">
            <CustomerBuyerDashboardSwitcher
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {activeTab === "basics" && <CustomerBasicsInfoTabContent />}
            {activeTab === "address" && <CustomerAddressTabContent />}
            {activeTab === "payment" && <CustomerPaymentTabContent />}
            {activeTab === "wishlist" && <CustomerWishlistTabContent />}
            {activeTab === "orders" && <CustomerOrdersTabContent />}
            {/* {activeTab === "settings" && <CustomerSettingsTabContent />} */}
          </CustomerBuyerDashboardGrid>
        </Container>
      </CustomerBuyerDashboardWrap>
    </motion.div>
  );
};

export default CustomerBuyerDashboard;
