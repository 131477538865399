import { Modal } from "antd";
import SuccessImg from "../../assets/images/success-.png";
import ErrorImg from "../../assets/images/error.png";
import WarnImg from "../../assets/images/warn.png";
import {
  AlertPopupWrap,
  AlertPopupErrBtn,
  AlertPopupErrMsg,
  AlertPopupSuccessBtn,
} from "./AlertPopupEl";
import { useHistory } from "react-router-dom";

const AlertPopup = ({
  is_error,
  is_warn,
  is_success,
  showPopupModal,
  setShowPopupModal,
}) => {
  const { push } = useHistory();
  return (
    <>
      <Modal
        title={null}
        centered
        visible={showPopupModal}
        onOk={() => setShowPopupModal(false)}
        onCancel={() => setShowPopupModal(false)}
        footer={null}
        width={300}
        bodyStyle={{ borderRadius: 20 }}
      >
        <AlertPopupWrap>
          {is_success && <img src={SuccessImg} alt="success" />}
          {is_error && <img src={ErrorImg} alt="error" />}
          {is_warn && <img src={WarnImg} alt="warn" />}
          {is_success && (
            <h3 style={{ textAlign: "center", margin: "15px 0" }}>
              Your checkout journey is completed successfully
            </h3>
          )}
          {is_error && (
            <AlertPopupErrMsg>
              <h3>Ooops!</h3>
              <p>Something went wrong, Let's try again one more time.</p>
            </AlertPopupErrMsg>
          )}
          {is_warn && <h3>Something went wrong!</h3>}

          {is_success && (
            <AlertPopupSuccessBtn type="button" onClick={() => push("/")}>
              Continue Shopping
            </AlertPopupSuccessBtn>
          )}

          {is_error && (
            <AlertPopupErrBtn type="button">Try Again</AlertPopupErrBtn>
          )}
        </AlertPopupWrap>
      </Modal>
    </>
  );
};

export default AlertPopup;
