import guestTypes from "./guest.types";

const initialState = { loading: false, cartItems: [], error: false };

export const addItemToShoppingCart = (cartItems, itemToAdd) => {
  const exitsItem = cartItems.find(
    item => item.product_id === itemToAdd.product_id
  );

  if (exitsItem) {
    return cartItems.map(item =>
      item.product_id === itemToAdd.product_id
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
  }
  return [...cartItems, { ...itemToAdd, quantity: 1 }];
};

export const decreaseCartItemQty = (cartItems, cartItem) => {
  const exitsItem = cartItems.find(
    item => item.product_id === cartItem.product_id
  );

  if (exitsItem.quantity === 1) {
    return cartItems.filter(item => item.product_id !== cartItem.product_id);
  }

  return cartItems.map(item =>
    item.product_id === cartItem.product_id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : item
  );
};

const guestReducer = (state = initialState, action) => {
  switch (action.type) {
    case guestTypes.GUEST_ADD_ITEM_LOCAL:
      return {
        ...state,
        cartItems: addItemToShoppingCart(state.cartItems, action.payload),
      };

    case guestTypes.GUEST_DECREASE_ITEM_LOCAL:
      return {
        ...state,
        cartItems: decreaseCartItemQty(state.cartItems, action.payload),
      };

    case guestTypes.GUEST_REMOVE_ITEM_FROM_CART_LOCAL:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          item => item.product_id !== action.payload
        ),
      };

    case guestTypes.GUEST_REMOVE_ALL_ITEMS_LOCAL:
      return {
        ...state,
        cartItems: [],
      };

    case guestTypes.GUEST_CART_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case guestTypes.GUEST_CART_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case guestTypes.GUEST_CART_ADD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default guestReducer;
