import api from "../../api";
import passwordTypes from "./password.types";

export const forgetPasswordAction = (email, setForgetStepTwo, setErr) => async dispatch => {
  dispatch({
    type: passwordTypes.FORGET_PASS_REQUEST,
  });

  try {
    const { data } = await api.post("/give-me-password", { email });
    setForgetStepTwo(true)
    dispatch({
      type: passwordTypes.FORGET_PASS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    setErr(err.response?.data?.msg)
    dispatch({
      type: passwordTypes.FORGET_PASS_FAILED,
      payload: err.response.message,
    });
  }
};
