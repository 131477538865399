import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { borderTop, Container } from "../../constants";
import { ProductsCategoriesTitle } from "../products-categories/ProductsCategoriesSectionEl";
import SkeletonComp from "../Skelaton/Skelaton";
import SliderProductItem from "../slider-product-item";

const ProductsCarousel = ({ data, path }) => {
  const responsive = {
    0: { items: 1 },
    768: { items: 1 },
    800: { items: 2 },
    992: { items: 3 },
    1024: { items: 5 },
  };

  if (!data) {
    return (
      <div
        className="Skeleton"
        style={{ display: "flex", justifyContent: "center", padding: "30px" }}
      >
        <div style={{ flex: 1 }}>
          <SkeletonComp style={{ border: "1px solid" }} />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
      </div>
    );
  }

  const items = data.map((item, idx) => (
    <SliderProductItem
      key={idx}
      {...item}
      style={{ margin: "0 10px !important", width: "95%" }}
      isNew={idx % 2 === 0}
      path={path}
    />
  ));

  return (
    <div
      style={{
        borderTop: `1px solid ${borderTop}`,
      }}
    >
      <Container>
        <ProductsCategoriesTitle>Top Sellers Products</ProductsCategoriesTitle>
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          disableDotsControls
          autoPlay
          autoPlayInterval={4500}
          infinite
          renderNextButton={() => (
            <HiArrowNarrowRight
              style={{ fontSize: 25, cursor: "pointer", marginTop: 0 }}
            />
          )}
          renderPrevButton={() => (
            <HiArrowNarrowLeft
              style={{ fontSize: 25, cursor: "pointer", marginTop: 0 }}
            />
          )}
        />
      </Container>
    </div>
  );
};

export default ProductsCarousel;
