import { useEffect, useState } from "react";
import faker from "faker";
import { Checkbox, message, Modal, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "../../constants";
import { FaRegTrashAlt } from "react-icons/fa";
import { ReactComponent as TradeSVG } from "../../assets/images/trade-assurance.svg";
import {
    addItemToCartLocal,
    guestDecreaseItemQty,
    guestRemoveItemFromCartLocal,
    removeAllItemsFromGuestCartAction,
} from "../../redux/guest/guest.actions";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import CustomLink from "../../components/custom-link";
import CustomButton from "../../components/custom-button";
import {
    TableDeleteModalBtnWrap,
    TableDeleteModalIcon,
    TableDeleteModalMsg,
} from "../../components/shopping-cart-table.index/ShoppingCartTableEl";
import api from "../../api";
import { getItemsFromCartAction } from "../../redux/cart/cart.actions";
import { BiLoaderAlt } from "react-icons/bi";
import { clearLocalCart, deleteLocalItem, getLocalCart, localQuantityMinus, localQuantityPlus } from "../../redux/localCart/localCart.actions";

const LocalCart = () => {
    const token = useSelector(({ buyer }) => buyer.data.access_token);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const [currId, setCurrId] = useState("");
    const [isAcceptedTradTerms, setIsAcceptedTradTerms] = useState(false);
    const cartItems = useSelector((state) => state?.localCart?.cartItems)

    console.log("LOCAL CART ITEMS", cartItems)
    const [clearLoading, setClearLoading] = useState(false);
    const [removeItemLoading, setRemoveItemLoading] = useState({
        status: false,
        id: 20000,
    });
    const [changeQuanLoading, setChngeQuanLoading] = useState({
        status: false,
        id: 20000,
    });

    console.log("REMOVE ITEM LOADING", removeItemLoading);
    const handleClearCart = () => {
        setClearLoading(true);
        api.post(`/clear-cart`, { token }).then(() => {
            dispatch(getItemsFromCartAction(token));
            setClearLoading(false);
            message.success("Cart has been cleared successfully");
            // setCartItems([]);
        });
    };

    const handleDeleteItem = (id, index) => {
        setRemoveItemLoading({ status: true, id });
        api.post(`/remove/cart/item`, { pro_id: id, token }).then(() => {
            setRemoveItemLoading({ status: false, id });
            dispatch(getItemsFromCartAction(token));
            message.success("Item has been removed successfully");
            // setCartItems(
            //     cartItems.filter(
            //         (item) => item?.item?.id !== cartItems[index]?.item?.id
            //     )
            // );
            console.log(cartItems[index], "REMOVED ITEM", index);
        });
    };

    const handleQuantityPlus = (id, quantity, index) => {
        setChngeQuanLoading({ status: true, id });
        api
            .post(`/cart/update-quantity/${id}`, { token, new_qty: quantity + 1 })
            .then(() => {
                // console.log(cartItems[index], "CART ITEM UU");
                cartItems[index].quantity = quantity + 1;
                setChngeQuanLoading({ status: false, id });
                dispatch(getItemsFromCartAction(token));
                message.success("Item quantity has been edited successfully");
            });
    };

    const handleQuantityMinus = (id, quantity, index) => {
        setChngeQuanLoading({ status: true, id });
        api
            .post(`/cart/update-quantity/${id}`, { token, new_qty: quantity - 1 })
            .then(() => {
                cartItems[index].quantity = quantity - 1;
                setChngeQuanLoading({ status: false, id });
                dispatch(getItemsFromCartAction(token));
                message.success("Item quantity has been edited successfully");
            });
    };

    const renderProductModal = (id) => {
        return (
            <Modal
                title={null}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}
                centered
            >
                <TableDeleteModalIcon>
                    <FaRegTrashAlt />
                </TableDeleteModalIcon>
                <TableDeleteModalMsg>
                    This action will delete your product from your Chopping Cart,
                    <br />
                    Are You Sure?
                </TableDeleteModalMsg>

                <TableDeleteModalBtnWrap>
                    <CustomButton
                        text="Cancel"
                        onClick={() => setShowModal(false)}
                        style={{
                            marginLeft: 10,
                            padding: "5px 20px",
                            borderRadius: 5,
                        }}
                        isHover
                    />
                    <CustomButton
                        text="Confirm"
                        onClick={() => {
                            dispatch(guestRemoveItemFromCartLocal(id));
                            setShowModal(false);
                        }}
                        style={{
                            marginLeft: 10,
                            padding: "5px 20px",
                            borderRadius: 5,
                        }}
                    />
                </TableDeleteModalBtnWrap>
            </Modal>
        );
    };
    const renderDeleteAllProductsModal = () => {
        return (
            <Modal
                title={null}
                visible={showDeleteAllModal}
                onCancel={() => setShowDeleteAllModal(false)}
                footer={null}
                centered
            >
                <TableDeleteModalIcon>
                    <FaRegTrashAlt />
                </TableDeleteModalIcon>
                <TableDeleteModalMsg>
                    This action will delete all products from your Chopping Cart,
                    <br />
                    Are You Sure?
                </TableDeleteModalMsg>

                <TableDeleteModalBtnWrap>
                    <CustomButton
                        text="Cancel"
                        onClick={() => setShowDeleteAllModal(false)}
                        style={{
                            marginLeft: 10,
                            padding: "5px 20px",
                            borderRadius: 5,
                        }}
                        isHover
                    />
                    <CustomButton
                        text="Confirm"
                        onClick={() => {
                            dispatch(removeAllItemsFromGuestCartAction());
                            setShowDeleteAllModal(false);
                        }}
                        style={{
                            marginLeft: 10,
                            padding: "5px 20px",
                            borderRadius: 5,
                        }}
                    />
                </TableDeleteModalBtnWrap>
            </Modal>
        );
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [dispatch]);

    return (
        <>
            {/* <ShoppingCartTable title="Shopping Cart" /> */}
            <Container>
                {cartItems?.length >= 1 ? (
                    <>
                        {" "}
                        <h3 style={{ marginBottom: 10 }}>
                            Shopping Cart ({cartItems?.length >= 1 ? cartItems?.length : 0}{" "}
                            item
                            {cartItems?.length > 1 && "(s)"} )
                        </h3>
                        <div className="test-table">
                            <div style={{ overflowX: "auto" }}>
                                <table>
                                    <tr>
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                        <th>Vendor</th>
                                        <th>Actions</th>
                                    </tr>
                                    {cartItems?.map((item, index) => {
                                        const actualPrice =
                                            parseInt(item?.item?.offer_price) === 0
                                                ? item?.item?.price
                                                : parseInt(item?.item?.offer_price);
                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        <Link to={`/product-details/${item.product_id}`}>
                                                            <img
                                                                src={`${item?.item?.path}/${item?.item?.image}`}
                                                                alt=""
                                                                width={70}
                                                                height={70}
                                                            />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={`/product-details/${item.product_id}`}>
                                                            {item?.item?.name}
                                                        </Link>
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {/* <Checkbox
                                  onChange={(e) => {
                                    setIsAcceptedTradTerms(e.target.checked);
                                    setCurrId(item.product_id);
                                  }}
                                  style={{ margin: "5px 5px 0 0" }}
                                />
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <TradeSVG
                                    style={{ width: "1rem", marginRight: 7 }}
                                  />{" "}
                                  Trade Assurance
                                </p> */}
                                                            </div>
                                                            {isAcceptedTradTerms ? (
                                                                <div
                                                                    style={{
                                                                        fontSize: "0.7rem",
                                                                        fontWeight: 500,
                                                                        marginTop: 5,
                                                                    }}
                                                                >
                                                                    By choosing trade assurance option, <br /> You
                                                                    will agree{" "}
                                                                    <Link
                                                                        to="/terms-and-conditions"
                                                                        style={{ textDecoration: "underline" }}
                                                                    >
                                                                        Terms and Conditions
                                                                    </Link>{" "}
                                                                    by choosing trade assurance option.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td>{item?.item?.productPrice}</td>
                                                    <td>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <button
                                                                    style={{ all: "unset" }}
                                                                    onClick={() => {
                                                                        dispatch(localQuantityPlus(
                                                                            {
                                                                                quantity: item.quantity,
                                                                                index
                                                                            }))

                                                                        message.success('Item quantity has been edited')

                                                                        setTimeout(() => {
                                                                            dispatch(getLocalCart(item?.item?.id))
                                                                        }, 20)
                                                                    }}
                                                                >
                                                                    <AiOutlinePlus
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            // marginRight: 10,
                                                                            backgroundColor: "#0077f9",
                                                                            width: 20,
                                                                            height: 20,
                                                                            color: "#fff",
                                                                            display: "block",
                                                                            padding: 3,
                                                                        }}
                                                                    />
                                                                </button>
                                                            </div>
                                                            <span style={{ margin: "0 15px" }}>
                                                                {changeQuanLoading.status === true &&
                                                                    changeQuanLoading.id === item?.item?.id ? (
                                                                    <BiLoaderAlt className="spin" />
                                                                ) : (
                                                                    item.quantity
                                                                )}
                                                            </span>

                                                            <div>
                                                                <button
                                                                    style={{ all: "unset" }}
                                                                    disabled={item.quantity <= 1}
                                                                    onClick={() => {
                                                                        dispatch(localQuantityMinus(
                                                                            {
                                                                                quantity: item.quantity,
                                                                                index
                                                                            }))

                                                                        message.success('Item quantity has been edited')

                                                                        setTimeout(() => {
                                                                            dispatch(getLocalCart(item?.item?.id))
                                                                        }, 20)
                                                                    }}
                                                                >
                                                                    <AiOutlineMinus
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            // marginLeft: 10,
                                                                            backgroundColor:
                                                                                item.quantity <= 1
                                                                                    ? "#b9b9b9"
                                                                                    : "#0077f9",
                                                                            width: 20,
                                                                            height: 20,
                                                                            color: "#fff",
                                                                            display: "block",
                                                                            padding: 3,
                                                                        }}
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{(item?.item?.productPrice * item.quantity).toFixed(2)}</td>
                                                    <td>
                                                        <Link to={`/product-details/${item.product_id}`}>
                                                            {faker.datatype.number(1000)}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Tooltip
                                                            placement="bottom"
                                                            title="Remove from Cart!"
                                                        >
                                                            {removeItemLoading.status === true &&
                                                                removeItemLoading.id === item?.item?.id ? (
                                                                <BiLoaderAlt
                                                                    className="spin"
                                                                    style={{
                                                                        color: "#dc0707",
                                                                        fontSize: "1.2rem",
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <FaRegTrashAlt
                                                                    style={{
                                                                        color: "#dc0707",
                                                                        fontSize: "1.2rem",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={token ? () => {
                                                                        handleDeleteItem(item?.item?.id, index)
                                                                    } :
                                                                        () => {
                                                                            dispatch(deleteLocalItem(item?.item?.id))
                                                                            message.success('Item has been removed')
                                                                        }
                                                                    }
                                                                />
                                                            )}
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "50px 0",
                        }}
                    >
                        <img
                            src="https://i.imgur.com/dCdflKN.png"
                            width={120}
                            height={120}
                            alt="empty cart"
                        />
                        <h1 style={{ marginTop: 25, fontSize: "2rem" }}>
                            Your Cart is Empty!
                        </h1>
                        <CustomLink
                            to="/"
                            text="Continue Shopping"
                            style={{
                                borderRadius: 5,
                                padding: "5px 10px",
                                textTransform: "capitalize",
                            }}
                        />
                    </div>
                )}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "50px 0",
                    }}
                >
                    {cartItems?.length > 0 && (
                        <button
                            // onClick={() => setShowDeleteAllModal(true)}
                            style={{
                                backgroundColor: "#dc0707",
                                border: "none",
                                padding: "10px 15px",
                                borderRadius: 5,
                                color: "#fff",
                                cursor: "pointer",
                                fontWeight: 500,
                                width: "130px",
                            }}
                            onClick={() => {
                                dispatch(clearLocalCart())
                                message.success('Cart has been cleared successfully')
                            }}
                        >
                            {clearLoading ? (
                                <BiLoaderAlt className="spin" />
                            ) : (
                                "Clear All Items"
                            )}
                        </button>
                    )}
                    {cartItems?.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <CustomLink
                                to="/checkout/save-address"
                                text="Processed to checkout"
                                style={{
                                    marginLeft: 10,
                                    padding: 10,
                                    borderRadius: 5,
                                    fontSize: ".8rem",
                                    textTransform: "capitalize !important",
                                }}
                            />
                        </div>
                    )}
                </div>

                {renderProductModal(currId)}
                {renderDeleteAllProductsModal()}
            </Container>
        </>
    );
};

export default LocalCart;
