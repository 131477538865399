import vendorTypes from "./vendor.types";

const initialState = { loading: false, data: {}, error: false };

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case vendorTypes.VENDOR_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: false,
      };
    case vendorTypes.VENDOR_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case vendorTypes.VENDOR_REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    case vendorTypes.VENDOR_CLEAR_FIELDS:
      return {
        ...state,
        loading: false,
        data: {},
        error: {},
      };

    case vendorTypes.VENDOR_LOGOUT:
      return {
        loading: false,
        data: null,
        info: {},
        error: action.payload,
      };
    case vendorTypes.SET_REGISTRATION_ACTIVE_TAB:
      return {
        loading: false,
        data: null,
        info: {},
        error: action.payload,
        isVendor: true,
      };
    default:
      return state;
  }
};

export default vendorReducer;
