import { useSelector } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import { LoadingSpinnerWrap } from "./LoadingSpinnerEl";

const LoadingSpinner = () => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <LoadingSpinnerWrap>
      <RingLoader color={mainColor} size={200} />
    </LoadingSpinnerWrap>
  );
};

export default LoadingSpinner;
