import { useState } from "react";
import { Modal, InputNumber, Rate, message } from "antd";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  ProductModalContent,
  ProductModalInfo,
  ProductModalInfoCartBlock,
  ShopProductFooter,
  ShopProductHotSpan,
  ShopProductImgWrap,
  ShopProductItemWrap,
  ShopProductName,
  ShopProductNewSpan,
  ShopProductOfferSpan,
  ShopProductRating,
  ShopProductSaleSpan,
  SVGWrapper,
} from "./ShopProductItemEl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../custom-button";
import CartCustomButton from "../card-button/cardButton";
import { addItemToCartLocal } from "../../redux/guest/guest.actions";
import CartModal from "../CartModal";

// Styles
const btnStyle = {
  zIndex: 500,
  padding: "6px 25px",
  display: "block",
  textTransform: "capitalize",
  fontWeight: 600,
  marginRight: 15,
};

const outOfStock = {
  backgroundColor: "#ababab",
  color: "#fff",
  border: "none",
  cursor: "not-allowed",
};

const ShopProductItem = ({
  id,
  name,
  base_image,
  image1,
  isSale,
  isHot,
  isNew,
  isOffer,
  images,
  price,
  // in_stock,
  quantity,
  style,
  offer_price,
}) => {
  const path = useSelector(
    (state) => state.homepage?.data?.featuredProducts?.path
  );

  const in_stock = true;

  const [showProductModal, setShopProductModal] = useState(false);

  // Redux Stuff
  const { isGrid, mainColor } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();

  const onCustomerAddToCart = () => {
    message.success("Product added to your cart successfully");
  };

  const productPrice = parseInt(offer_price) === 0 ? price : offer_price;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <ShopProductItemWrap isGrid={isGrid} style={{ ...style }}>
        <CartModal
          product={{
            image: image1,
            mainPrice: price,
            offerPrice: offer_price,
            name: name?.en,
            id,
            path,
            productPrice,
          }}
          visible={visible}
          setVisible={setVisible}
        />
        <ShopProductImgWrap to={`/product-details/${id}`}>
          <img
            isGrid={isGrid}
            style={isGrid ? { width: "100%" } : { width: "250px" }}
            src={`${path}/${image1}`}
            alt=""
          />
          {isSale && <ShopProductSaleSpan>Sale</ShopProductSaleSpan>}
          {isNew && <ShopProductNewSpan>New</ShopProductNewSpan>}
          {isOffer && <ShopProductOfferSpan>- 3%</ShopProductOfferSpan>}
          {isHot && <ShopProductHotSpan>Hot</ShopProductHotSpan>}
        </ShopProductImgWrap>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 10,
            marginLeft: isGrid ? 0 : 20,
            flex: 1,
            paddingRight: 15,
          }}
        >
          <ShopProductName to={`/product-details/${id}`}>
            {name.length > 25 ? `${name?.en.substring(0, 25)}...` : name?.en}
          </ShopProductName>

          {!isGrid && (
            <p style={{ maxWidth: 400 }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              accusantium laborum officia nam repudiandae similique dolores
              corporis fugiat, saepe odio.
            </p>
          )}
          <ShopProductFooter>
            <span>{productPrice} EGP</span>

            {/* {isGrid && <MdAddShoppingCart />} */}
          </ShopProductFooter>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <CartCustomButton
              text={!in_stock ? "Out of Stock" : "Add To Cart"}
              style={!in_stock && outOfStock}
              disabled={!in_stock ? true : false}
              onClick={() => {
                setVisible(true);
                // onCustomerAddToCart();
                // dispatch(addItemToCartAction(id, quantity, "api"));
              }}
            />
            <SVGWrapper
              mainColor={mainColor}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {/* <AiOutlineHeart style={{ marginRight: 10 }} />
              <AiFillEye onClick={() => setShopProductModal(true)} /> */}
            </SVGWrapper>
          </div>
          {/* {!isGrid && (
        )} */}
        </div>
      </ShopProductItemWrap>

      <Modal
        style={{ zIndex: 9999 }}
        // title="Vertically centered modal dialog"
        centered
        visible={showProductModal}
        onOk={() => setShopProductModal(false)}
        onCancel={() => setShopProductModal(false)}
        width="60%"
        footer={null}
      >
        <ProductModalContent>
          <ImageGallery
            items={images}
            thumbnailPosition="bottom"
            showPlayButton={false}
            showFullscreenButton={false}
            useBrowserFullscreen={false}
            showThumbnails={false}
          />
          <ProductModalInfo>
            <h2>
              {name?.en.length > 25
                ? `${name?.en.substring(0, 25)}...`
                : name?.en}
            </h2>
            <h2>$88.00</h2>
            <ShopProductRating>
              <Rate disabled defaultValue={5} />
              <span
                style={{
                  fontSize: ".8rem",
                  color: mainColor,
                  marginLeft: "10px",
                  fontWeight: 600,
                }}
              >
                188
              </span>
            </ShopProductRating>
            <ul style={{ margin: "10px 20px" }}>
              <li>Available: In Stock</li>
              <li>Product code: : CA78963</li>
              <li>Type: WOMEN’S CLOTHING</li>
              <li>Vendor: DKLS9X2</li>
            </ul>
            <ProductModalInfoCartBlock>
              <InputNumber
                size="large"
                min={1}
                max={100000}
                defaultValue={3}
                onChange={(value) => console.log(value)}
              />
              <CustomButton
                type="button"
                text="add to cart"
                style={{ ...btnStyle, padding: "10px 40px" }}
                onClick={() => console.log("add to cart")}
                disabled={false}
                isHover
              />
            </ProductModalInfoCartBlock>
          </ProductModalInfo>
        </ProductModalContent>
      </Modal>
    </>
  );
};

export default ShopProductItem;
