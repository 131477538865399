import { Link } from "react-router-dom";
import styled from "styled-components";
import { mainColor, screenLg } from "../../../constants";

export const UpperHeaderWrap = styled.div`
  background-color: ${mainColor};
  padding: 7px 0;
  color: #fff !important;

  @media screen and (max-width: ${screenLg}) {
    display: none;
  }
`;

export const UpperHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #fff;
  }
`;

export const ContactPhone = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;

  svg {
    margin-right: 10px;
  }
`;

export const MenusItem = styled.div`
  margin: 0 15px;
`;

export const ForBuyerMenuGrid = styled.div`
  min-width: 400px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  padding: 20px;
`;

export const ForBuyerMenuColumn = styled.div`
  h5 {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
`;

export const ForBuyerMenuLink = styled(Link)`
  display: block;
  width: fit-content;
  color: #555;
  margin-bottom: 5px;
  transition: 0.3s ease-in-out;

  &:hover {
    color: ${mainColor};
  }
`;
