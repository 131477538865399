import React from "react";
import { useForm } from "react-hook-form";
import { Container, PageTitle, ContainerWrapper } from "../../constants";
import Button from "../button";
import { FormRow, InputWrapper } from "../form-elements/constants";
import formFields from "./utils/formFields";
import FormHandler from "../form-elements/form-handler";

const RequestBidForm = () => {
  const { control, handleSubmit } = useForm();

  const onSubmit = data => {
    console.log("data data data", data);
  };

  return (
    <>
      <PageTitle>Request Bid</PageTitle>
      <ContainerWrapper>
        <Container width="50%">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormHandler control={control} formFields={formFields} />
            <FormRow justifyContent="flex-end">
              <InputWrapper>
                <Button type="submit" text="Submit" width="100%" />
              </InputWrapper>
            </FormRow>
          </form>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default RequestBidForm;
