import passwordTypes from "./password.types";

const initialState = { loading: false, data: {}, error: false };

const passwordReducer = (state = initialState, action) => {
  switch (action.type) {
    case passwordTypes.FORGET_PASS_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: false,
      };
    case passwordTypes.FORGET_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case passwordTypes.FORGET_PASS_FAILED:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default passwordReducer;
