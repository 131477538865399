const styles = {
  color: "red",
  fontSize: ".7rem",
  marginBottom: "7px",
  fontWeight: 600,
};

const FormErrors = ({ message }) => {
  return <div style={styles}>{message}</div>;
};

export default FormErrors;
