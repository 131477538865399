import { Link } from "react-router-dom";
import styled from "styled-components";
import { mainColor } from "../../../constants";

export const PopularSearchesWrap = styled.div`
  height: fit-content;
  margin: 15px 0;
`;

export const PopularSearchesTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 1.1rem;
`;

export const PopularSearchNameWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  border: 1px solid #d4d4d4;
`;

export const PopularSearchName = styled(Link)`
  color: ${mainColor};
  padding: 8px 0;
`;
export const PopularSearchMore = styled.button`
  all: unset;
  font-weight: 600;
  margin-top: 6px;
  display: flex;
  align-items: baseline;
  .anticon.anticon-down {
    margin-left: 10px;
    font-size: 10px;
  }
`;
