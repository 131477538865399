import styled from "styled-components";
import { mainColor } from "../../constants";

export const SearchPageWrap = styled.div`
  /* margin: 50px 0;
  padding-top: 50px; */
`;

export const SearchPageTitle = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: ${mainColor};
  font-size: 1.2rem;
  color: gray;

  span {
    margin-right: 10px;
    color: ${mainColor} !important;
    font-size: 2rem;
  }
`;

export const SearchPageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;
