import api from "../../api";
import guestTypes from "./guest.types";

export const guestGetItemsFromCartAction = () => {
  return async dispatch => {
    dispatch({
      type: guestTypes.GUEST_GET_CART_ITEMS_REQUEST,
    });
    try {
      const { data } = await api.get(`/checkout/cart?token=api`);

      dispatch({
        type: guestTypes.GUEST_GET_CART_ITEMS_SUCCESS,
        payload: data?.data,
      });
    } catch (err) {
      dispatch({
        type: guestTypes.GUEST_GET_CART_ITEMS_FAILED,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const guestAddItemToCartAction = cartItems => {
  return async dispatch => {
    dispatch({
      type: guestTypes.GUEST_CART_ADD_REQUEST,
    });
    try {
      const { data } = await api.post(`/checkout/cart/add-bulk?token=true`, {
        items: cartItems,
      });

      dispatch({
        type: guestTypes.GUEST_CART_ADD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: guestTypes.GUEST_CART_ADD_FAILED,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const guestRemoveItemFromCartAction = productId => {
  return async dispatch => {
    dispatch({
      type: guestTypes.GUEST_CART_REMOVE_ITEM_REQUEST,
    });
    try {
      const { data } = await api.get(
        `/checkout/cart/remove-item/${productId}?token=api`,
        { product_id: productId }
      );

      dispatch({
        type: guestTypes.GUEST_CART_REMOVE_ITEM_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: guestTypes.GUEST_CART_REMOVE_ITEM_FAILED,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const addItemToCartLocal = item => ({
  type: guestTypes.GUEST_ADD_ITEM_LOCAL,
  payload: item,
});

export const guestDecreaseItemQty = item => ({
  type: guestTypes.GUEST_DECREASE_ITEM_LOCAL,
  payload: item,
});

export const removeAllItemsFromGuestCartAction = () => ({
  type: guestTypes.GUEST_REMOVE_ALL_ITEMS_LOCAL,
});

export const guestRemoveItemFromCartLocal = product_id => ({
  type: guestTypes.GUEST_REMOVE_ITEM_FROM_CART_LOCAL,
  payload: product_id,
});
