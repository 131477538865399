import { message } from "antd";
import addressTypes from "./address.types";

const initialState = {
  loading: false,
  isSuccess: false,
  data: [],
};

export const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET_WISHLIST

    // case addressTypes.GET_WISHLIST_ERROR:
    //   return {
    //     ...state,
    //   };
    case addressTypes.GET_ADDRESSES:
      console.log("ACTION PAYLOAD", action?.payload);
      return {
        ...state,
        loading: false,
        data: action?.payload,
        error: null,
      };

    case addressTypes.GET_WISHLIST_ERROR:
      return {
        ...state,
      };

    // ADD_TO_WISHLIST

    case addressTypes.ADD_TO_WISHLIST_REQUEST:
      return {
        loading: true,
        isSuccess: false,
        data: state.data,
        error: null,
      };
    case addressTypes.ADD_TO_WISHLIST_SUCCESS:
      message.success(action.payload.message);
      return {
        loading: false,
        isSuccess: true,
        ...action.payload,
        error: null,
      };

    case addressTypes.ADD_TO_WISHLIST_ERROR:
      return {
        loading: false,
        isSuccess: false,
      };

    default:
      return state;
  }
};
