import GoogleLogin from "react-google-login";
import CustomButton from "../custom-button";
import { AiOutlineGoogle } from "react-icons/ai";
import { useSelector } from "react-redux";

const GoogleLoginComp = () => {
  const { mainColor } = useSelector(({ ui }) => ui);

  // Some Styles
  const btnStyle = {
    border: "none",
    textAlign: "center",
    backgroundColor: mainColor,
    color: "#fff",
    padding: "10px 20px",
    margin: "30px 0",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontWeight: 600,
  };

  const responseGoogle = response => {
    console.log(response);
  };

  return (
    <div>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_AUTH_PUBLIC}
        render={renderProps => (
          <CustomButton
            text="Google"
            type="submit"
            style={btnStyle}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <AiOutlineGoogle style={{ marginRight: 10, fontSize: 20 }} />
          </CustomButton>
        )}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};

export default GoogleLoginComp;
