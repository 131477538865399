import styled from "styled-components";
import { screenLg } from "../../../constants";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const UpperWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${screenLg}) {
    flex-direction: column;
  }
`;

export const ProductNavBodyWrapper = styled.div`
  padding: 18px 8px;
`;
