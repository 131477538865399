const commonStyles = {
  label: { fontWeight: 400 },
  formRow: { margin: "46px 0" },
};
const formFields = [
  {
    name: "rating",
    labelText: "Your rating of this product",
    type: "rate",
    style: commonStyles,
  },
  {
    name: "title",
    labelText: "Title",
    placeholder: "Write your title here",
    type: "text",
    style: commonStyles,
  },
  {
    name: "comment",
    labelText: "Details",
    placeholder: "Write your review here",
    type: "textarea",
    style: commonStyles,
  },
];

export default formFields;
