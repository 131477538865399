import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  mainColor,
  screenMd,
  screenMLg,
  screenSm,
  screenXSm,
  secColor,
} from "../../constants";

export const YouMayLikeBoxGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  height: 250px;

  @media screen and (max-width: ${screenMLg}) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
  }
`;

export const YouMayLikeBoxWrap = styled(Link)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center center;
  max-width: 100%;
  padding: 10px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #bbbbbb6b;
  }

  img {
    height: 100%;
    object-fit: contain;
    max-height: 100px;
  }

  @media screen and (max-width: ${screenMd}) {
    margin-bottom: 20px;
  }
`;

export const ProductName = styled(Link)`
  color: #fff;
  font-weight: 500;
`;

export const ShopNowBox = styled(Link)`
  z-index: 999;
  background-color: ${({ mainColor }) => mainColor};
  color: ${secColor};
  padding: 7px 20px;
  margin-bottom: 20px;
  display: block;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid ${({ mainColor }) => mainColor};
  font-size: 0.7rem;
  width: fit-content;
  transition: 0.4s ease-in-out;
  margin-top: 10px;

  &:hover {
    border: 2px solid ${({ mainColor }) => mainColor};
    background-color: ${secColor};
    color: ${({ mainColor }) => mainColor};
  }
`;

export const TopRatedEl = styled.div`
  position: absolute;
  top: 12px;
  left: -65px;
  background-color: ${mainColor};
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  transform: rotate(-45deg);
  color: ${secColor};

  @media screen and (max-width: ${screenXSm}) {
    top: 20px !important;
    left: -61px !important;
  }

  @media screen and (max-width: ${screenSm}) {
    top: 20px !important;
    left: -61px !important;
  }
`;

export const TopFeaturedEl = styled.div`
  position: absolute;
  top: 12px;
  left: -65px;
  background-color: ${mainColor};
  width: 200px;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  transform: rotate(-45deg);
  color: ${secColor};

  @media screen and (max-width: ${screenXSm}) {
    top: 20px !important;
    left: -61px !important;
  }

  @media screen and (max-width: ${screenSm}) {
    top: 20px !important;
    left: -61px !important;
  }
`;
