import { DatePicker } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const CustomDate = ({ name, control, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <DatePicker {...field} {...rest} />;
      }}
    />
  );
};

export default CustomDate;
