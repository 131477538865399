import { Col, Row } from "antd";
import React, { useEffect } from "react";
import ContactUsBanner from "../../components/contact-us/banner";
import ContactUsDetails from "../../components/contact-us/details";
import ContactUsForm from "../../components/contact-us/form";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import { Container, ContainerWrapper } from "../../constants";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Container>
        <PagesBreadCrumb prev="home" curr="Contact Us" />
      </Container>
      <ContactUsBanner />

      <ContainerWrapper>
        <Container>
          <Row justify="space-between" gutter={{ lg: 36 }}>
            <Col lg={{ span: 12 }}>
              <ContactUsDetails />
            </Col>
            <Col lg={{ span: 12 }}>
              <ContactUsForm />
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default ContactUs;
