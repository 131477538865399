import { motion } from "framer-motion";
import TabsNavigator from "../../components/tabs-navigator";
import { RegistrationFormWrap } from "./RegistrationEl";
import containerVariants from "../../animation";
import { useSelector } from "react-redux";

const Registration = () => {
  const { mainColor } = useSelector(({ ui }) => ui);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   AOS.init({ duration: 2000 });
  // }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
    >
      <RegistrationFormWrap
        mainColor={mainColor}
        style={{
          border: "0px solid #6f5dff",
          padding: "55px 35px 35px",
          borderRadius: "10px",
          boxShadow: "0px 0px 15px -2px #6f5dff",
        }}
      >
        <TabsNavigator />
      </RegistrationFormWrap>
    </motion.div>
  );
};

export default Registration;
