import productTypes from "./product.types";

const initialState = {
  loading: true,
  data: {
    product: null,
    price: 0,
    formattedPrice: 0,
    images: [],
    brand: null,
  },

  error: false,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productTypes.FETCH_PRODUCT_REQUEST:
      return {
        loading: true,
        data: {},
        error: false,
      };
    case productTypes.FETCH_PRODUCT_SUCCESS:
      const variantAttributes = getVariantProductValues(action.payload);
      return {
        loading: false,
        data: { product: action.payload, ...variantAttributes },
        error: false,
      };

    case productTypes.FETCH_PRODUCT_ERROR:
      return {
        loading: false,
        data: {},
        error: action.payload,
      };
    case productTypes.EXTRACT_VARIANT_VALUES:
      return {
        loading: false,
        data: {
          ...state.data,
          ...getVariantProductValues(state.data.product, action.payload),
        },
        error: false,
      };
    default:
      return state;
  }
};

const getVariantProductValues = (product, selectedVariant = []) => {
  if (!product) return {};

  const isSimple = product.type === "simple";
  const isConfigurable = product.type === "configurable";
  const brand = product.specs.find((spec) => spec.code === "brand");
  if (isSimple)
    return {
      formattedPrice: product.formated_price,
      price: product.price,
      images: formatImagesName(product.images),
      brand,
    };
  if (isConfigurable) {
    let currentVariantIndex = -1;
    const selectedVariantString =
      selectedVariant.length > 0
        ? selectedVariant.join(",")
        : product.variants.index[0].join(",");
    product.variants.index.forEach((variantIndexArray, index) => {
      const variantIndexString = variantIndexArray.join(",");
      if (selectedVariantString === variantIndexString)
        currentVariantIndex = index;
    });
    const finalPrice =
      product.variants.variant_prices[currentVariantIndex].final_price;

    const variantImages = product.variants.variant_images[currentVariantIndex];
    const images =
      variantImages && variantImages.length > 0
        ? variantImages
        : product.images;
    return {
      price: finalPrice.price,
      formattedPrice: finalPrice.formated_price,
      images: formatImagesName(images),
      brand,
    };
  }
};

const formatImagesName = (images) => {
  return images.map((image) => ({
    ...image,
    original: image.original_image_url,
    thumbnail: image.small_image_url,
  }));
};
