import { useEffect } from "react";
import { MdLocalShipping, MdLabel } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiBarChartAlt } from "react-icons/bi";
import { Container } from "../../constants";
import AOS from "aos";
import {
  HomepageQuickFeaturesContent,
  HomepageQuickFeaturesItem,
  HomepageQuickFeaturesItemIcon,
  HomepageQuickFeaturesItemInfo,
  HomepageQuickFeaturesWrap,
} from "./HomepageQuickFeaturesEl";

const HomepageQuickFeatures = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <HomepageQuickFeaturesWrap>
      <Container>
        <HomepageQuickFeaturesContent>
          <HomepageQuickFeaturesItem>
            <HomepageQuickFeaturesItemIcon>
              <MdLocalShipping />
            </HomepageQuickFeaturesItemIcon>
            <HomepageQuickFeaturesItemInfo>
              <h3>Free Shipping</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo
              </p>
            </HomepageQuickFeaturesItemInfo>
          </HomepageQuickFeaturesItem>

          <HomepageQuickFeaturesItem>
            <HomepageQuickFeaturesItemIcon>
              <AiOutlineShoppingCart />
            </HomepageQuickFeaturesItemIcon>
            <HomepageQuickFeaturesItemInfo>
              <h3>Flexible Buying</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo
              </p>
            </HomepageQuickFeaturesItemInfo>
          </HomepageQuickFeaturesItem>

          <HomepageQuickFeaturesItem>
            <HomepageQuickFeaturesItemIcon>
              <MdLabel />
            </HomepageQuickFeaturesItemIcon>
            <HomepageQuickFeaturesItemInfo>
              <h3>Tons of Offers</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo
              </p>
            </HomepageQuickFeaturesItemInfo>
          </HomepageQuickFeaturesItem>

          <HomepageQuickFeaturesItem>
            <HomepageQuickFeaturesItemIcon>
              <BiBarChartAlt />
            </HomepageQuickFeaturesItemIcon>
            <HomepageQuickFeaturesItemInfo>
              <h3>Increase your sales</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo
              </p>
            </HomepageQuickFeaturesItemInfo>
          </HomepageQuickFeaturesItem>
        </HomepageQuickFeaturesContent>
      </Container>
    </HomepageQuickFeaturesWrap>
  );
};

export default HomepageQuickFeatures;
