import faker from "faker";
import { Table, Checkbox, Tooltip, Modal } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Container } from "../../constants";
import {
    ShoppingCartTableWrap,
    TableDeleteIcon,
    TableDeleteModalIcon,
    TableDeleteModalMsg,
    TableDeleteModalBtnWrap,
} from "./ShoppingCartTableEl";

import { ReactComponent as TradeSVG } from "../../assets/images/trade-assurance.svg";
import { IoTrashOutline } from "react-icons/io5";
import CustomButton from "../custom-button";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { guestRemoveItemFromCartLocal } from "../../redux/guest/guest.actions";
import { getWishlistAction } from "../../redux/wishlist/wishlist.actions";
import { wishlistAddRemove } from "../../constants/helperFunctions";
import { deleteLocalWishlistItem } from "../../redux/localWishlist/localWishlist.actions";

const LocalFavTable = ({ title }) => {
    const [showModal, setShowModal] = useState(false);
    const [isAcceptedTradTerms, setIsAcceptedTradTerms] = useState(false);
    const token = useSelector(({ buyer }) => buyer.data.access_token);
    const { loading, cartItems } = useSelector(({ guest }) => guest);
    const wishListItems = useSelector(state => state?.localWishlist?.wishlist)
    const path = useSelector(state => state?.homepage?.data?.featuredProducts?.path)

    console.log("PATH", path)

    console.log("WISHLIST", wishListItems)

    const dispatch = useDispatch();

    const removeCallBack = () => {
        dispatch(getWishlistAction(token))
    }


    const onAcceptTradeTerms = (e) => {
        setIsAcceptedTradTerms(e.target.checked);
    };

    // grand_total

    const columns = [
        {
            title: "Product Image",
            dataIndex: "image",
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            render: (val) => (
                <div>
                    <img src={val} alt="" width={70} height={70} />
                </div>
            ),
        },
        {
            title: "Product Name",
            dataIndex: "name",
            render: (text) => (
                <div>
                    <h3>{text}</h3>
                    <div style={{ display: "flex", alignItems: "center" }}>

                    </div>
                    {isAcceptedTradTerms ? (
                        <div
                            style={{
                                fontSize: "0.7rem",
                                fontWeight: 500,
                                marginTop: 5,
                            }}
                        >
                            By choosing trade assurance option, <br /> You will agree{" "}
                            <Link
                                to="/terms-and-conditions"
                                style={{ textDecoration: "underline" }}
                            >
                                Terms and Conditions
                            </Link>{" "}
                            by choosing trade assurance option.
                        </div>
                    ) : null}
                </div>
            ),
        },
        {
            title: "Price",
            dataIndex: "price",
        },
        {
            title: "Vendor",
            dataIndex: "vendor",
            render: (text) => (
                <Tooltip placement="bottom" title={`Vendor ${text}`}>
                    <Link to="/" style={{ textDecoration: "underline" }}>
                        {text}
                    </Link>
                </Tooltip>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (text) => (
                <TableDeleteIcon
                    onClick={() => {
                        // setShowModal(true);
                    }}
                >
                    <Tooltip placement="bottom" title="Remove from Cart!">
                        <IoTrashOutline onClick={() => dispatch(deleteLocalWishlistItem(text))} />
                    </Tooltip>
                </TableDeleteIcon>
            ),
        },
    ];

    const data = wishListItems?.map(({ id, name, price, image }) => ({
        key: id,
        image: `${path}/${image}`,
        name: name,
        price: price,
        total: price * 1,
        vendor: 'Ahmed',
        actions: id,
    }));

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <>
            <ShoppingCartTableWrap>
                <Container>
                    <h3 style={{ marginBottom: 10 }}>
                        {title} ({cartItems?.length > 1 ? cartItems?.length : 0} item(s))
                    </h3>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: 30,
                        }}
                    >
                    </div>
                </Container>
            </ShoppingCartTableWrap>

            <Modal
                title={null}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}
                centered
            >
                <TableDeleteModalIcon>
                    <FaRegTrashAlt />
                </TableDeleteModalIcon>
                <TableDeleteModalMsg>
                    This action will delete your product from your Chopping Cart,
                    <br />
                    Are You Sure?
                </TableDeleteModalMsg>

                <TableDeleteModalBtnWrap>
                    <CustomButton
                        text="Cancel"
                        onClick={() => setShowModal(false)}
                        style={{
                            marginLeft: 10,
                            padding: "5px 20px",
                            borderRadius: 5,
                        }}
                        isHover
                    />
                    <CustomButton
                        text="Confirm"
                        onClick={() => {
                            dispatch(guestRemoveItemFromCartLocal(256));
                            setShowModal(false);
                        }}
                        style={{
                            marginLeft: 10,
                            padding: "5px 20px",
                            borderRadius: 5,
                        }}
                    />
                </TableDeleteModalBtnWrap>
            </Modal>
        </>
    );
};

export default LocalFavTable;
