import { useSelector } from "react-redux";
import { CustomLinkWrap } from "./CustomLinkEl";

const CustomLink = ({ to, text, style, onClick, children }) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <CustomLinkWrap
      to={to}
      style={{ ...style }}
      mainColor={mainColor}
      onClick={onClick}
    >
      <span style={{ display: "flex", alignItems: "center" }}>
        {" "}
        {text} {children}
      </span>
    </CustomLinkWrap>
  );
};

export default CustomLink;
