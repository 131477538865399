import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import ShopProductItem from "../../components/shop-product-item";
import { Container } from "../../constants";
import {
  SearchPageGrid,
  SearchPageTitle,
  SearchPageWrap,
} from "./SearchPageEl";
import { useDispatch } from "react-redux";
import { fetchAllProductsAction } from "../../redux/products/products.actions";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../components/loading-spinner";

const SearchPage = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(({ products }) => products);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // AOS.init({ duration: 2000 });
    dispatch(fetchAllProductsAction(name));
  }, [dispatch, name]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <h3>{error}</h3>;
  }
  return (
    <div>
      <SearchPageWrap>
        <Container>
          <PagesBreadCrumb prev="home" curr={`Search for ${name}`} />
          <SearchPageTitle>
            <span>Result of:</span> {name} ({data?.length} Products Found)
          </SearchPageTitle>
          {data?.length === 0 ? (
            <img
              src="https://docmode.org/wp-content/uploads/2019/10/451d27c21601d96114f0eea20d9707e2.png"
              alt=""
              style={{
                width: "540px",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
            />
          ) : (
            <SearchPageGrid>
              {data?.map((item, idx) => (
                <ShopProductItem key={idx} {...item} />
              ))}
            </SearchPageGrid>
          )}
        </Container>
      </SearchPageWrap>
    </div>
  );
};

export default SearchPage;
