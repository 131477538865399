import { useEffect } from "react";
import { motion } from "framer-motion";
import HomepageBestSellerSection from "../components/homepage-best-sellers-section";
import HomepageGreatDeal from "../components/homepage-great-deal";
import HomepageHeroSection from "../components/homepage-hero";
import HomepageTopRatedSection from "../components/homepage-top-rated-sections";
import HomepageTrendingSection from "../components/homepage-trending-section";
import ProductsCategoriesSection from "../components/products-categories";
import ProductsCarousel from "../components/products-carousel";
import NewCollections from "../components/new-collection";
import containerVariants from "../animation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetHomepageDataAction } from "./../redux/homepage/homepage.actions";
import AdOne from "../assets/images/Ads 1.jpg";
import AdTwo from "../assets/images/Ads 2.jpg";
import homeCarouselData from "../dummay-data/home.data";
import HomeCardComponent from "../components/home-card-component/home-card-component";
import { Container, mainColor } from "../constants";
import HomepageQuickFeatures from "../components/homepage-quick-features";
import HomepageOurCategoriesSection from "../components/homepage-our-categoires-section";
import AdvertisementSeparator from "./../components/advertisement-separator/index";
import RecentDevices from "../components/RecentDevices";

const HomePage = () => {
  const { data, error } = useSelector(({ homepage }) => homepage);
  const categories = useSelector(
    (state) => state.homepage?.data?.categories_subcategories_childcategories
  );
  const featuredProducts = useSelector(
    (state) => state.homepage?.data?.featuredProducts
  );
  const newProducts = useSelector((state) => state.homepage?.data?.newProducts);
  const topProducts = useSelector((state) => state.homepage?.data?.topProducts);
  const sliders = useSelector((state) => state.homepage?.data?.sliders);
  const catPath = useSelector(
    (state) => state?.homepage?.data?.categories?.path
  );

  const devices = featuredProducts?.items?.slice(0, 5);
  const topRatedProducts = useSelector(
    (state) => state.homepage?.data?.topProducts?.items
  );

  console.log("FEATURED PRODUCS", featuredProducts);
  const dispatch = useDispatch();

  console.log("CATEGORIES", categories);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // dispatch(GetHomepageDataAction());
  }, [dispatch]);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
    >
      {error && <h1>{error}</h1>}
      <HomepageHeroSection data={sliders} />

      <Container>
        <h1
          style={{
            color: "#333",
            marginBottom: "-30px",
            textAlign: "center",
            marginTop: "40px",
            fontFamily: "Jost",
            fontWeight: "400",
            fontSize: "32px",
          }}
        >
          Categories
        </h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 15,
            margin: "50px 0",
          }}
        >
          {categories?.map((item, idx) => (
            <HomeCardComponent key={idx} path={catPath} item={item} />
          ))}
        </div>
      </Container>
      {/* <ProductsCategoriesSection data={data?.new_products} /> */}
      <HomepageTopRatedSection
        data={topProducts?.items}
        path={topProducts?.path}
      />
      <AdvertisementSeparator imgSrc={AdOne} />
      <HomepageBestSellerSection
        data={featuredProducts?.items}
        path={featuredProducts?.path}
      />
      {/* <HomepageTrendingSection
        data={
          data?.trending_products
            ? data?.trending_products
            : data?.trending_products
        }
      /> */}
      <AdvertisementSeparator imgSrc={AdTwo} />
      <ProductsCarousel data={newProducts?.items} path={newProducts?.path} />
      {/* <HomepageOurCategoriesSection /> */}

      {/* <NewCollections /> */}
      <Container>
        <RecentDevices products={devices} />
      </Container>
      <HomepageGreatDeal />

      <HomepageQuickFeatures />
    </motion.div>
  );
};

export default HomePage;
