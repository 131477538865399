import api from "../../api";
import productTypes from "./product.types";

export const fetchProductAction = productId => {
  return async dispatch => {
    dispatch({
      type: productTypes.FETCH_PRODUCT_REQUEST,
    });

    try {
      const { data } = await api.get(`/api/get-product/1`);

      dispatch({
        type: productTypes.FETCH_PRODUCT_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: productTypes.FETCH_PRODUCT_ERROR,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.message,
      });
    }
  };
};

export const extractVariantValues = selectedVariants => {
  return {
    type: productTypes.EXTRACT_VARIANT_VALUES,
    payload: selectedVariants,
  };
};
