import customerTypes from "./customer.types";

const initialState = { loading: false, data: {}, error: false };

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerTypes.CUSTOMER_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
        error: false,
      };
    case customerTypes.CUSTOMER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
      };
    case customerTypes.CUSTOMER_REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };
    case customerTypes.CUSTOMER_LOGOUT:
      return {
        ...state,
        loading: false,
        data: {},
        error: {},
      };

    default:
      return state;
  }
};

export default customerReducer;
