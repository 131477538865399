import { FiEdit2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import {
  CustomerBasicsInfoTabContentWrap,
  CustomerBasicsInfoTabContentHeader,
} from "../CustomerBuyerDashboardEl.js";

const CustomerBasicsInfoTabContent = () => {
  const user = useSelector((state) => state?.buyer?.data?.user_data);
  return (
    <CustomerBasicsInfoTabContentWrap>
      <CustomerBasicsInfoTabContentHeader>
        <h4>Basics Info</h4>
      </CustomerBasicsInfoTabContentHeader>
      <div>
        <div>
          <h5>Name:</h5> <span>{user?.name}</span>
          {/* <h5>Second Name:</h5> <span>Stark</span> */}
        </div>
        <FiEdit2 />
      </div>
      {/* <div>
        <div>
          <h5>Username:</h5> <span>Toni Stark</span>
        </div>
        <FiEdit2 />
      </div> */}
      <div>
        <div>
          <h5>Email:</h5> <span>{user?.email}</span>
        </div>
        <FiEdit2 />
      </div>
      <div>
        <div>
          <h5>Mobile Number:</h5> <span>{user?.mobile}</span>
        </div>
        <FiEdit2 />
      </div>
      {/* <div>
        <div>
          <h5>Password:</h5> <span>***********</span>
        </div>
        <FiEdit2 />
      </div> */}
      {/* <div>
        <div>
          <h5>Two-Step Verification:</h5>{" "}
          <span>Manage your two step verification authentication</span>
        </div>
        <FiEdit2 />
      </div> */}
    </CustomerBasicsInfoTabContentWrap>
  );
};

export default CustomerBasicsInfoTabContent;
