import React from "react";
import { useSelector } from "react-redux";
import RichTextContainer from "../../rich-text-container";
import { DescriptionWrapper } from "./DescriptionEl";

const ProductDescription = () => {
  const {
    data: { product: productData },
  } = useSelector(({ product }) => product);

  return (
    <DescriptionWrapper>
      <RichTextContainer richText="<div />" />
    </DescriptionWrapper>
  );
};

export default ProductDescription;
