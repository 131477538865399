import React from 'react'
import { IoIosCart } from 'react-icons/io'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import api from '../api'
import { useDispatch, useSelector } from 'react-redux';
import { Rate, message } from 'antd';
import CartModal from './CartModal';
import { getWishlistAction } from '../redux/wishlist/wishlist.actions';
import { wishlistAddRemove } from '../constants/helperFunctions';
import { Link } from 'react-router-dom';
import { deleteLocalWishlistItem, localWishlistAdd } from '../redux/localWishlist/localWishlist.actions';

function ProductCard({ item }) {
    const image = item?.image1;
    const mainPrice = item?.price;
    const offerPrice = item?.offer_price;
    const name = item?.name?.en;
    const id = item?.id;
    const fav = item?.is_favorite;
    const path = useSelector(
        (state) => state.homepage?.data?.featuredProducts?.path
    );
    const wishListItems = useSelector(state => state?.localWishlist?.wishlist)
    const wishlistIds = wishListItems?.map(item => item.id)
    const localFav = wishlistIds?.includes(id) ? true : false;
    console.log("WISHLIST IDS", wishlistIds)
    const productPrice = parseInt(offerPrice) === 0 ? mainPrice : parseInt(offerPrice)
    const product = { image, mainPrice, offerPrice, name, id, fav, path, price: productPrice }
    const token = useSelector(({ buyer }) => buyer.data.access_token);
    const dispatch = useDispatch();
    const [visible, setVisible] = React.useState();
    const [isFav, setIsFav] = React.useState(token ? fav === 0 ? false : true : false)
    console.log("FAV", isFav)
    const callBack = () => {
        setIsFav(!isFav)
        dispatch(getWishlistAction(token))
    }
    const wishlistAddRemoveClick = () => {
        token ?
            wishlistAddRemove(id, callBack, token) :
            message.error("Please login first")
    }
    return (
        <div className='device-card'>
            <CartModal product={{ image, mainPrice, offerPrice, name, id, fav, path, productPrice }} visible={visible} setVisible={setVisible} />
            <div className='flex-center img-wrapper'>
                <img src={`${path}/${image}`} alt='icons' />
            </div>
            <Link style={{ color: 'red' }} to={`/product-details/${id}`}>
                <h3>{name}</h3>
            </Link>
            {
                parseInt(offerPrice) === 0 ?
                    <h5>{mainPrice} EGP</h5> :
                    <h5>{offerPrice} EGP <span className='old-price'>{mainPrice} EGP</span></h5>
            }
            <Rate style={{ zoom: '60%', marginLeft: '15px' }} value={5} disabled />
            <div className='icons'>
                <IoIosCart onClick={() => setVisible(true)} className='cart-icon' />
                {
                    token ?
                        isFav ?
                            <AiFillHeart onClick={() => token ? wishlistAddRemoveClick() : dispatch(localWishlistAdd(product))} className='cart-icon' />
                            :
                            <AiOutlineHeart onClick={() => token ? wishlistAddRemoveClick() : dispatch(localWishlistAdd(product))} className='cart-icon' /> :
                        localFav ?
                            <AiFillHeart onClick={() => {
                                dispatch(deleteLocalWishlistItem(id))
                                message.success("Item has been removed from wishlist")
                            }} className='cart-icon' />
                            :
                            <AiOutlineHeart onClick={() => {
                                dispatch(localWishlistAdd(product))
                                message.success('Item has been added to wishlist')
                            }} className='cart-icon' />
                }
            </div>
        </div>
    )
}

export default ProductCard