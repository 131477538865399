import styled from "styled-components";

export const AlertPopupWrap = styled.div`
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  img {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
`;

export const AlertPopupErrBtn = styled.button`
  all: unset;
  text-align: center;
  width: fit-content;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 2px solid ${({ mainColor }) => mainColor};
  background-color: #e74c3c;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
`;

export const AlertPopupSuccessBtn = styled.button`
  all: unset;
  text-align: center;
  width: fit-content;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  /* border: 2px solid ${({ mainColor }) => mainColor}; */
  background-color: #3bb54a;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
`;

export const AlertPopupErrMsg = styled.div`
  text-align: center;

  h3 {
    font-size: 2rem;
  }

  p {
    padding: 0 20px;
    margin-bottom: 11px;
    color: #878787;
  }
`;
