import { Link } from "react-router-dom";
import styled from "styled-components";
import { mainColor, screenMd, secColor } from "../../constants";

export const AppFooterWrap = styled.footer`
  /* border: 1px solid red; */
`;

export const AppFooterSubscribeNow = styled.div`
  border-top: 1px solid ${mainColor};
  border-bottom: 1px solid ${mainColor};
  padding: 25px 0;
`;

export const AppFooterSubscribeNowContent = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 1rem;
    font-weight: 700;
    /* color: ${mainColor}; */
  }

  form {
    position: relative;
    min-width: 500px;
    display: flex;
    /* align-items: center; */
    padding: 6px 10px;
    border-radius: 5px;

    input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 10px;
    }
  }

  svg {
    margin-right: 10px;
    font-size: 1.2rem;
    color: ${mainColor};
  }

  a {
    color: ${mainColor};
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  @media screen and (max-width: ${screenMd}) {
    flex-direction: column;

    form {
      min-width: 100% !important;
      margin: 15px 0;
    }
  }
`;

export const FooterWrapper = styled.footer`
  margin-top: 30px;
  background-color: ${({ mainColor }) => mainColor};
  padding: 50px 0 10px 0;
`;

export const FooterRow = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    margin-right: 5px;
  }
`;

export const LinkItem = styled(Link)`
  color: ${secColor};
  margin-bottom: 10px;
  font-size: 1.1em;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const ColumnTitle = styled.h3`
  color: #fff;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.2rem;
`;

export const FooterMiddleSide = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 30px;
  /* background-color: ${mainColor + "9d"}; */
  background-color: ${({ mainColor }) => mainColor};
  border-radius: 5px;
  border: 2px solid #fff;
`;

export const FooterMiddleSideTimes = styled.div`
  padding: 20px 0;

  h1 {
    color: #fff;
  }

  p {
    margin: 0;
    color: #fff;
    font-size: 0.8rem;
  }

  p:first-of-type {
    margin-bottom: 7px;
  }

  a {
    background: transparent;
    border: 1px solid;
    border-color: #fff;
    color: rgba(255, 255, 255, 1);
    padding: 7px 20px;
    margin-top: 11px;
    display: inline-block;
    border-radius: 20px;
    transition: 0.4s ease-in-out;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
`;
export const FooterMiddleSideAddress = styled.div`
  padding: 20px 0;

  p {
    margin: 0;
    color: #fff;
    font-size: 1rem;
  }

  p:first-of-type {
    margin-bottom: 7px;
  }

  a {
    color: red;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
`;

export const FooterMiddleSideAddressSocial = styled.div`
  display: flex;
  margin-top: 10px;

  svg {
    color: #fff;
    font-size: 1.2rem;
    margin-right: 10px;
  }
`;

export const FooterMiddleSideNewsLetter = styled.div`
  padding: 20px 0;

  display: flex;

  h1 {
    color: #fff;
    text-align: center;
    line-height: 1;
  }

  svg {
    color: #9babcd;
    font-size: 1.2rem;
    margin-right: 10px;
  }
`;

export const FooterCopyRights = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-top: 1px solid #fff;
  padding-top: 20px;
  margin-top: 20px;

  a {
    color: #fff;
    font-weight: bold;
  }

  p:first-of-type {
    margin: 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: ${screenMd}) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

export const PaymentMethodsList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;

  img {
    margin-left: 15px;
    object-fit: contain;
  }

  p {
    margin: 0;
  }

  @media screen and (max-width: ${screenMd}) {
    flex-direction: column;

    img {
      width: 60px !important;
    }
  }
`;
