import { Container } from "../../constants";
import {
  CategoriesAllHeroText,
  CategoriesAllHeroWrap,
} from "./CategoriesAllHeroEl";

const CategoriesAllHero = () => {
  return (
    <CategoriesAllHeroWrap backgroundImg="https://images.pexels.com/photos/287237/pexels-photo-287237.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
      <Container>
        <CategoriesAllHeroText>Dental devices</CategoriesAllHeroText>
      </Container>
    </CategoriesAllHeroWrap>
  );
};

export default CategoriesAllHero;
