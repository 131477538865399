const vendorTypes = {
  VENDOR_REGISTER_REQUEST: "VENDOR_REGISTER_REQUEST",
  VENDOR_REGISTER_SUCCESS: "VENDOR_REGISTER_SUCCESS",
  VENDOR_REGISTER_FAILED: "VENDOR_REGISTER_FAILED",
  SET_REGISTRATION_ACTIVE_TAB: "SET_REGISTRATION_ACTIVE_TAB",

  VENDOR_CLEAR_FIELDS: "VENDOR_CLEAR_FIELDS",
  VENDOR_LOGOUT: "VENDOR_LOGOUT",
};

export default vendorTypes;
