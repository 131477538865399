import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const LoginSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

export const VendorSignupSchema = yup.object().shape({
  // name: yup.string().required('Facility type is a required field'),
  // email: yup.string().required('facility email is a required field').email('facility email must be a valid email'),
  // mobile: yup.string().matches(phoneRegExp, 'phone number is not valid').required('contac phone is a required field'),
  // password: yup.string().required(),
  // password_confirmation: yup
  //   .string()
  //   .required('password confirmation is a required field')
  //   .oneOf([yup.ref('password')], "password confirmation doesn't match password"),
  // address: yup.string().required('Address is a required Field'),
  // country: yup.string().required('Country is a required Field'),
  // city: yup.string().required('Establishment Representatives is a required Field'),
  // company_name: yup.string().required('facility name is a required Field'),
  // // company_papers: yup.mixed().nullable().required('Credentials Establishment is a required Field'),
  terms: yup.boolean().oneOf([true], 'You must accept terms and conditions'),
});

export const BuyerSignupSchema = yup.object().shape({
  name: yup.string().required('Full name is a required field'),
  email: yup.string().required('facility email is a required field').email('facility email must be a valid email'),
  mobile: yup.string().matches(phoneRegExp, 'phone number is not valid').required('contac phone is a required field'),
  password: yup.string().required(),
  password_confirmation: yup
    .string()
    .required('password confirmation is a required field')
    .oneOf([yup.ref('password')], "password confirmation doesn't match password"),
  terms: yup.boolean().oneOf([true], 'You must accept terms and conditions'),
  // recaptch: yup.string().required("Please, Check I am not a robot!"),
});

export const CustomerSignupSchema = yup.object().shape({
  first_name: yup.string().required('First name is a required field'),
  last_name: yup.string().required('Last name is a required field'),
  username: yup.string().required('Username is a required field'),
  email: yup.string().required().email(),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  password: yup.string().required(),
  password_confirmation: yup
    .string()
    .required('Password confirmation is a required field')
    .oneOf([yup.ref('password')], "Password confirmation doesn't match password"),
  terms: yup.boolean().oneOf([true], 'You must accept terms and conditions'),
});

export const CheckoutContactFormSchema = yup.object().shape({
  full_name: yup.string().required('Full name is a required field'),
  email: yup.string().required().email(),
  country: yup.string().required('Country is a required field'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  zip: yup.string().required('Zip code is a required field'),
  state: yup.string().required('State is a required field'),
  street_address: yup.string().required('Street Address is a required field'),
});
export const AddReviewSchema = yup.object().shape({
  rating: yup.string().required(),
  title: yup.string().required(),
  comment: yup.string().required(),
});
