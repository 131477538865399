import React from "react";
import { NavItem, NavWrapper } from "./NavEl";
import navItems from "./utils";
import { useSelector } from "react-redux";

const ProductDetailsMainNav = ({ currentTab, setCurrentTab }) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  const onNavClick = e => {
    setCurrentTab(e.target.value);
  };
  const renderNavItems = () =>
    navItems.map(item => (
      <NavItem
        mainColor={mainColor}
        className={item.value === currentTab && "active"}
        key={item.value}
        value={item.value}
        onClick={onNavClick}
      >
        {item.title}
      </NavItem>
    ));

  return <NavWrapper>{renderNavItems()}</NavWrapper>;
};

export default ProductDetailsMainNav;
