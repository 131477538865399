import { useSelector } from "react-redux";
import { Rate, Tooltip } from "antd";
import { Container } from "../../constants";
import productsCompare from "./data";
import "./ProductsCompareEl.js";
import {
  CompareProductsWrap,
  PricingButton,
  ProductsCompareWrap,
} from "./ProductsCompareEl.js";
import CartModal from "../../components/CartModal";
import React from "react";

const ProductsCompare = () => {
  const compareItems = useSelector((state) => state?.localCompare?.compareList);
  const path = useSelector(
    (state) => state?.homepage?.data?.featuredProducts?.path
  );
  const { mainColor } = useSelector(({ ui }) => ui);
  const [visible, setVisible] = React.useState();

  return (
    <ProductsCompareWrap>
      <div className="background">
        <Container>
          <h1 style={{ textAlign: "center", marginBottom: 20 }}>
            Compare between Products
          </h1>
          <div className="panel pricing-table">
            <div className="pricing-plan">
              <img
                src="https://thumbs.dreamstime.com/b/shopping-cart-logo-web-icons-trolley-icon-shopping-icon-cart-icon-shop-icon-shopping-cart-button-shopping-cart-shopping-cart-icon-182252719.jpg"
                alt=""
                className="pricing-img"
              />
              <h2 className="pricing-header">Product Name</h2>
              <ul className="pricing-features">
                <li className="pricing-features-item">Price</li>
                <li className="pricing-features-item">Count in Stock</li>
                <li className="pricing-features-item">reviews</li>
              </ul>
              <span className="pricing-price">Shipping</span>
              <PricingButton mainColor={mainColor} outlined>
                Action
              </PricingButton>
            </div>

            <CompareProductsWrap className="panel">
              {compareItems.map(
                (
                  {
                    image,
                    name,
                    price,
                    countInStock,
                    reviews,
                    isShippingFree,
                    shippingPrice,
                    id,
                  },
                  idx
                ) => (
                  <div className="pricing-plan" key={idx}>
                    <CartModal
                      product={{ image, id, path, productPrice: price, name }}
                      visible={visible}
                      setVisible={setVisible}
                    />
                    <img
                      src={`${path}/${image}`}
                      alt=""
                      className="pricing-img"
                    />
                    <Tooltip placement="bottom" title={name?.en}>
                      <h2 className="pricing-header">{name}</h2>
                    </Tooltip>
                    <ul className="pricing-features">
                      <li className="pricing-features-item">{price}</li>
                      <li className="pricing-features-item">{1}</li>
                      <li className="pricing-features-item">
                        <Rate small value={4} />
                      </li>
                    </ul>
                    <span className="pricing-price">
                      {isShippingFree ? "FREE" : shippingPrice}
                      FREE
                    </span>
                    {/* <PricingButton mainColor={mainColor}>
                      add to cart
                    </PricingButton> */}
                    {/* <PricingButton mainColor={mainColor} outlined>
                      Move to Cart
                    </PricingButton> */}
                    <div
                      style={{
                        backgroundColor: mainColor,
                        color: "#fff",
                        padding: "5px 0px",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                        borderRadius: "4px",
                        marginTop: "15px",
                      }}
                      onClick={() => setVisible(true)}
                    >
                      Move to Cart
                    </div>
                  </div>
                )
              )}
            </CompareProductsWrap>
          </div>
        </Container>
      </div>
    </ProductsCompareWrap>
  );
};

export default ProductsCompare;
