const navItems = [
  {
    title: "Product Description",
    value: "description",
  },
  {
    title: "Reviews",
    value: "reviews",
  },
  {
    title: "Questions and Answers",
    value: "faqs",
  },
  {
    title: "Request Best Price",
    value: "options",
  },
];

export default navItems;
