import { Select } from "antd";
import { Bar } from "react-chartjs-2";
import { Progress } from "antd";

import {
  VendorOrdersTabContentHeader,
  VendorOrdersTabContentWrap,
} from "../VendorBuyerDashboardEl";
import { mainColor } from "../../../constants";

const rand = () => Math.round(Math.random() * 20 - 10);
const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      type: "line",
      label: "Dataset 1",
      borderColor: "rgb(54, 162, 235)",
      borderWidth: 2,
      fill: false,
      data: [rand(), rand(), rand(), rand(), rand(), rand()],
    },
    // {
    //   type: "bar",
    //   label: "Dataset 2",
    //   backgroundColor: "rgb(255, 99, 132)",
    //   data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
    //   borderColor: "white",
    //   borderWidth: 2,
    // },
    // {
    //   type: "bar",
    //   label: "Dataset 3",
    //   backgroundColor: "rgb(75, 192, 192)",
    //   data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
    // },
  ],
};

const VendorRevenueTabContent = () => {
  const { Option } = Select;

  const handleChange = value => {
    console.log(`selected ${value}`);
  };

  return (
    <VendorOrdersTabContentWrap>
      <VendorOrdersTabContentHeader>
        <h4>Revenue</h4>
        <Select
          defaultValue="past-3-month"
          style={{ width: 150 }}
          onChange={handleChange}
        >
          <Option value="past-3-month">Past 3 Month</Option>
          <Option value="past-6-month">Past 6 Month</Option>
          <Option value="past-12-month">Past 12 Month</Option>
        </Select>
      </VendorOrdersTabContentHeader>
      <Bar data={data} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h4>Product 1</h4>
        <Progress
          strokeColor={{
            "0%": mainColor,
            "100%": "#6be239",
          }}
          percent={75}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h4>Product 2</h4>
        <Progress
          strokeColor={{
            "0%": mainColor,
            "100%": "#6be239",
          }}
          percent={42}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h4>Product 3</h4>
        <Progress
          strokeColor={{
            "0%": mainColor,
            "100%": "#6be239",
          }}
          percent={93}
        />
      </div>
    </VendorOrdersTabContentWrap>
  );
};

export default VendorRevenueTabContent;
