import { AiOutlineShareAlt, AiFillStar } from "react-icons/ai";
import { MdAddShoppingCart, MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  CustomerWishlistTabContentHeader,
  CustomerWishlistTabContentRow,
  CustomerWishlistTabContentRowLeft,
  CustomerWishlistTabContentRowLeftStars,
  CustomerWishlistTabContentWrap,
} from "../CustomerBuyerDashboardEl";

const CustomerWishlistTabContent = () => {
  const wishListItems = useSelector((state) => state?.wishlist?.wishlist);
  return (
    <CustomerWishlistTabContentWrap>
      <CustomerWishlistTabContentHeader>
        <h4>Wishlist</h4>
      </CustomerWishlistTabContentHeader>
      {wishListItems?.map((item, index) => {
        return (
          <CustomerWishlistTabContentRow>
            <CustomerWishlistTabContentRowLeft to="/product-details/10">
              <img
                src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                alt=""
              />
              <div>
                <h5>{item?.name?.en}</h5>
                <span>$50.00</span>
                <CustomerWishlistTabContentRowLeftStars>
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                </CustomerWishlistTabContentRowLeftStars>
              </div>
            </CustomerWishlistTabContentRowLeft>

            <div>
              <MdAddShoppingCart />
              <AiOutlineShareAlt />
              <MdDelete />
            </div>
          </CustomerWishlistTabContentRow>
        );
      })}
    </CustomerWishlistTabContentWrap>
  );
};

export default CustomerWishlistTabContent;
