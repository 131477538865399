import ProductDescription from "../description";
import ProductReviews from "../reviews";
import ProductFAQs from "../FAQs";
import ProductTable from "../../product-table/productable";

const ProductNavBody = ({ currentTab }) => {
  const renderCurrentTab = () => {
    if (currentTab === "description") return <ProductDescription />;
    if (currentTab === "reviews") return <ProductReviews />;
    if (currentTab === "faqs") return <ProductFAQs />;
    if (currentTab === "options") return <ProductTable />;
    return <ProductDescription />;
  };
  return renderCurrentTab();
};

export default ProductNavBody;
