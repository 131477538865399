import { Col, Row } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Container, PageTitle, ContainerWrapper } from "../../constants";
import Button from "../button";
import { FormRow } from "../form-elements/constants";
import FormHandler from "../form-elements/form-handler";
import formFields from "./utils/reviewFields";

const ReviewSupplierForm = () => {
  const { control, handleSubmit } = useForm();
  const { mainColor } = useSelector(({ ui }) => ui);

  const onSubmit = data => {
    console.log("data data data", data);
  };

  return (
    <>
      <PageTitle mainColor={mainColor}>Review Supplier</PageTitle>
      <ContainerWrapper>
        <Container>
          <Row justify="center">
            <Col xs={{ span: 24 }} xl={{ span: 16 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormHandler
                  control={control}
                  formFields={formFields}
                  styles={{ inputWrapper: {} }}
                />
                <FormRow justifyContent="center">
                  <Button type="submit" text="Submit" />
                </FormRow>
              </form>
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default ReviewSupplierForm;
