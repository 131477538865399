import {
  AiOutlineUser,
  AiOutlineHeart,
  AiOutlineSetting,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { MdPayment } from "react-icons/md";
import { BiSpreadsheet } from "react-icons/bi";
import { CgToolbox } from "react-icons/cg";

const vendorSwitcherData = [
  {
    title: "Basics Info",
    name: "basics",
    icon: <AiOutlineUser />,
  },
  {
    title: "Address",
    name: "address",
    icon: <GoLocation />,
  },
  {
    title: "Payment",
    name: "payment",
    icon: <MdPayment />,
  },
  {
    title: "Wishlist",
    name: "wishlist",
    icon: <AiOutlineHeart />,
  },
  {
    title: "Orders",
    name: "orders",
    icon: <BiSpreadsheet />,
  },
  {
    title: "Products",
    name: "products",
    icon: <CgToolbox />,
  },
  {
    title: "Revenue",
    name: "revenue",
    icon: <BiSpreadsheet />,
  },
  {
    title: "Requests",
    name: "requests",
    icon: <CgToolbox />,
  },

  {
    title: "Settings",
    name: "settings",
    icon: <AiOutlineSetting />,
  },
];

export default vendorSwitcherData;
