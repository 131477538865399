import styled from "styled-components";
import { mainColor, screenMd } from "../../constants";

export const CategoriesAllPageGrid = styled.div``;

export const CategoriesAllMobileFilter = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 500;

  svg {
    margin-right: 10px;
    color: ${mainColor};
    font-size: 30px;
  }

  @media screen and (min-width: ${screenMd}) {
    display: none;
  }
`;

export const CategoriesAllPageGridContent = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 250px;
  gap: 30px;

  @media screen and (max-width: ${screenMd}) {
    grid-template-columns: 1fr !important;
  }
`;
