import styled from "styled-components";

export const SizeCircleItem = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid ${({ mainColor }) => mainColor};
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${({ isSelected, mainColor }) => (isSelected ? "#fff" : mainColor)};
  font-size: 1rem;
  text-transform: uppercase;
  background-color: ${({ isSelected, mainColor }) =>
    isSelected ? mainColor : "transparent"};
`;
export const ColorCircleItem = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ bg }) => bg};
  display: block;
  cursor: pointer;
`;

export const CircleButton = styled.button`
  all: unset;
  width: 30px;
  height: 30px;
  line-height: 30px;

  border-radius: 50%;
  background: ${({ mainColor }) => mainColor};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
`;
