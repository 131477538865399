import { Rate } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import moment from "moment";
import {
  ReviewDescription,
  ReviewLocation,
  ReviewTitleContent,
  ReviewTitleName,
  ReviewWrapper,
  UserAreaAntWrapper,
  UserAreaContent,
  UserAreaContentName,
  UserAreaWrapper,
} from "./ReviewEl";

const Review = ({ review }) => {
  return (
    <ReviewWrapper>
      <UserAreaWrapper>
        <UserAreaContent>
          <UserAreaAntWrapper>
            <Avatar size="large">{review.name.charAt(0)}</Avatar>
          </UserAreaAntWrapper>
          <UserAreaContentName>{review.name}</UserAreaContentName>
        </UserAreaContent>
        <ReviewTitleContent>
          <UserAreaAntWrapper>
            <Rate value={review.rating} disabled />
          </UserAreaAntWrapper>
          <ReviewTitleName>{review.title}</ReviewTitleName>
        </ReviewTitleContent>
      </UserAreaWrapper>

      <ReviewLocation>
        Reviewed on {moment(review.created_at).format("MMMM DD, YYYY")}
      </ReviewLocation>
      <ReviewDescription>{review.comment}</ReviewDescription>
    </ReviewWrapper>
  );
};

export default Review;
