import styled from 'styled-components';
import { screenMLg } from '../../constants';

export const HomePageHeroWrap = styled.div`
   /* border: 1px solid red; */
`;

export const HomePageHeroContent = styled.div`
   display: grid;
   grid-template-columns: 250px 2fr;
   gap: 10px;
   height: 400px
   @media screen and (max-width: ${screenMLg}) {
      grid-template-columns: 1fr !important;
   }
`;
