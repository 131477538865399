import { useEffect } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import SendInquiryForm from "../../components/send-inquiry-form";
import containerVariants from "../../animation";

const SendInquiry = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="zoom-in"
    >
      <SendInquiryForm />
    </motion.div>
  );
};

export default SendInquiry;
