import styled from "styled-components";
import { borderTop, screenMd } from "../../constants";

export const HomepageGreatDealWrap = styled.div`
  border-top: 1px solid ${borderTop};
  margin: 50px 0;
  padding-top: 50px;
`;

export const HomepageGreatDealContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  img {
    width: 200px;
    height: 150px;
    border-radius: 5px;
    margin-left: 20px;
  }

  h1 {
    font-size: 3rem;
    margin: 0;
    color: ${({ mainColor }) => mainColor};
    font-weight: 700;
  }

  @media screen and (max-width: ${screenMd}) {
    flex-direction: column;
    align-items: flex-start !important;

    h1 {
      margin-bottom: 20px;
    }

    a {
      margin: 10px 0;
      width: 100%;
      margin-left: 0 !important;

      img {
        width: 100%;
        height: 200px !important;
      }
    }
  }
`;
