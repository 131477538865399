import { useEffect, useState } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import CorporateBuyerDashboardSwitcher from "../../components/corporate-buyer-dashboard-switcher";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import { Container } from "../../constants";
import {
  CorporateBuyerDashboardGrid,
  CorporateBuyerDashboardTitle,
  CorporateBuyerDashboardWrap,
} from "./CorporateBuyerDashboardEl";
import CorporateAddressTabContent from "./utils/CorporateAddressTabContent";
import CorporateBasicsInfoTabContent from "./utils/CorporateBasicsInfoTabContent";
import CorporateOffersTabContent from "./utils/CorporateOffersTabContent";
import CorporateOrdersTabContent from "./utils/CorporateOrdersTabContent";
import CorporatePaymentTabContent from "./utils/CorporatePaymentTabContent";
import CorporatePurchasedTabContent from "./utils/CorporatePurchasedTabContent";
import CorporateSettingsTabContent from "./utils/CorporateSettingsTabContent";
import CorporateWishlistTabContent from "./utils/CorporateWishlistTabContent";
import containerVariants from "../../animation";

const CorporateBuyerDashboard = () => {
  const [activeTab, setActiveTab] = useState("basics");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="flip-right"
    >
      <CorporateBuyerDashboardWrap>
        <Container>
          <PagesBreadCrumb prev="home" curr="Corporate Buyer Dashboard" />
          <CorporateBuyerDashboardTitle>
            Corporate Buyer Dashboard
          </CorporateBuyerDashboardTitle>

          <CorporateBuyerDashboardGrid id="corporate-grid">
            <CorporateBuyerDashboardSwitcher
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {activeTab === "basics" && <CorporateBasicsInfoTabContent />}
            {activeTab === "address" && <CorporateAddressTabContent />}
            {activeTab === "payment" && <CorporatePaymentTabContent />}
            {activeTab === "wishlist" && <CorporateWishlistTabContent />}
            {activeTab === "orders" && <CorporateOrdersTabContent />}
            {activeTab === "purchased" && <CorporatePurchasedTabContent />}
            {activeTab === "offers" && <CorporateOffersTabContent />}
            {activeTab === "settings" && <CorporateSettingsTabContent />}
          </CorporateBuyerDashboardGrid>
        </Container>
      </CorporateBuyerDashboardWrap>
    </motion.div>
  );
};

export default CorporateBuyerDashboard;
