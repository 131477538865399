import { Link } from "react-router-dom";
import styled from "styled-components";
import { screenMd } from "../../constants";

export const CategoriesAllPageMightAlsoLikeWrap = styled.div`
  height: fit-content;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 15px;
  padding-bottom: 5px;

  @media screen and (max-width: ${screenMd}) {
    display: none;
  }
`;

export const CategoriesAllPageMightAlsoLikeTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 40px;
`;

export const CategoriesAllPageMightAlsoLikeProduct = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const CategoriesAllPageMightAlsoLikeImg = styled(Link)`
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;

  img {
    height: 80px;
    width: 80px;
  }
`;

export const CategoriesAllPageMightAlsoLikeName = styled(Link)`
  font-size: 0.8rem;
  color: #333;
  font-weight: 600;
`;

export const CategoriesAllPageMightAlsoLikeCategory = styled.div`
  font-size: 0.6rem;
`;

export const CategoriesAllPageMightAlsoLikePrice = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 10px;
  color: #333;
`;
