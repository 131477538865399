import styled from "styled-components";
import { Link } from "react-router-dom";

export const CustomLinkWrap = styled(Link)`
  background-color: ${({ mainColor }) => mainColor};
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  border: 2px solid ${({ mainColor }) => mainColor};
  transition: 0.3s ease-in-out;
  width: fit-content;

  &:hover {
    background-color: #fff !important;
    border: 2px solid ${({ mainColor }) => mainColor};
    color: ${({ mainColor }) => mainColor} !important;
  }

  svg {
    font-size: 1.5rem;
    margin-left: 5px;
  }
`;
