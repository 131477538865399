import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import {
  CorporateAddressTabContentHeader,
  CorporateAddressTabContentRow,
  CorporateAddressTabContentWrap,
} from "../CorporateBuyerDashboardEl";

const CorporateAddressTabContent = () => {
  return (
    <CorporateAddressTabContentWrap>
      <CorporateAddressTabContentHeader>
        <h4>Address</h4>
        <AiOutlinePlus />
      </CorporateAddressTabContentHeader>
      <CorporateAddressTabContentRow>
        <div>
          <h5>Address No.1:</h5>{" "}
          <span>
            8543 NW 66 ST Miami, FL 43195 AP-056018 <br />
            MIAMI, FLORIDA 33135-2678 <br />
            United States
          </span>
        </div>

        <div>
          <MdDelete />
          <FiEdit2 />
        </div>
      </CorporateAddressTabContentRow>
      <CorporateAddressTabContentRow>
        <div>
          <h5>Address No.2:</h5>{" "}
          <span>
            8543 NW 66 ST Miami, FL 43195 AP-056018 <br />
            MIAMI, FLORIDA 33135-2678 <br />
            United States
          </span>
        </div>

        <div>
          <MdDelete />
          <FiEdit2 />
        </div>
      </CorporateAddressTabContentRow>
      <CorporateAddressTabContentRow>
        <div>
          <h5>Address No.3:</h5>{" "}
          <span>
            8543 NW 66 ST Miami, FL 43195 AP-056018 <br />
            MIAMI, FLORIDA 33135-2678 <br />
            United States
          </span>
        </div>

        <div>
          <MdDelete />
          <FiEdit2 />
        </div>
      </CorporateAddressTabContentRow>
      <CorporateAddressTabContentRow>
        <div>
          <h5>Address No.4:</h5>{" "}
          <span>
            8543 NW 66 ST Miami, FL 43195 AP-056018 <br />
            MIAMI, FLORIDA 33135-2678 <br />
            United States
          </span>
        </div>

        <div>
          <MdDelete />
          <FiEdit2 />
        </div>
      </CorporateAddressTabContentRow>
      <CorporateAddressTabContentRow>
        <div>
          <h5>Address No.5:</h5>{" "}
          <span>
            8543 NW 66 ST Miami, FL 43195 AP-056018 <br />
            MIAMI, FLORIDA 33135-2678 <br />
            United States
          </span>
        </div>

        <div>
          <MdDelete />
          <FiEdit2 />
        </div>
      </CorporateAddressTabContentRow>
    </CorporateAddressTabContentWrap>
  );
};

export default CorporateAddressTabContent;
