import api from "../../api";
import userTypes from "../user/user.types";
import customerTypes from "./customer.types";

export const customerRegisterAction =
  (customerData, userInfo) => async dispatch => {
    dispatch({
      type: customerTypes.CUSTOMER_REGISTER_REQUEST,
    });

    try {
      const { data } = await api.post("/customer/register", customerData);
      dispatch({
        type: customerTypes.CUSTOMER_REGISTER_SUCCESS,
        payload: { ...data, userInfo },
      });

      const { email, password } = customerData;

      await api
        .post(`/customer/login?token=api`, { email, password })
        .then(({ data }) =>
          dispatch({
            type: userTypes.Login_USER_SUCCESS,
            payload: data,
          })
        )
        .catch(err => {
          dispatch({
            type: userTypes.Login_USER_ERROR,
            payload:
              err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message,
          });
        });
    } catch (err) {
      dispatch({
        type: customerTypes.CUSTOMER_REGISTER_FAILED,
        payload:
          err.response && err.response.data.errors
            ? err.response.data.errors
            : err.message,
      });
    }
  };

export const logoutCustomerAction = () => ({
  type: customerTypes.CUSTOMER_LOGOUT,
});

export const vendorClearMessages = () => ({
  type: customerTypes.CUSTOMER_CLEAR_FIELDS,
});
