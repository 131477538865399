import React from "react";
import "./index.css";
import CustomButton from "../custom-button";
import { Link } from "react-router-dom";

const data = [
  {
    img_url:
      "https://api.xpoya.com/storage/product/232/5KjlENnm0IRIIObJThuznnWyg9pZI1Ke93QB4px6.jpg",
    price: "30,512.00",
    condition: "New",
    sellerInformation: {
      title: "Shop Name",
      sku: "56168416584",
      item_id: "56168416584",
      seller_note: "string string string ",
    },
    readyToBuy: "Add To Cart",
  },
  {
    img_url:
      "https://api.xpoya.com/storage/product/232/5KjlENnm0IRIIObJThuznnWyg9pZI1Ke93QB4px6.jpg",
    price: "30,512.00",
    condition: "New",
    sellerInformation: {
      title: "Shop Name",
      sku: "56168416584",
      item_id: "56168416584",
      seller_note: "string string string ",
    },
    readyToBuy: "Add To Cart",
  },
];

const ProductTable = () => {
  return (
    <form>
      <table id="customers">
        <thead>
          <th style={{ textAlign: "left" }}>Item</th>
          <th style={{ textAlign: "left" }}>Condition</th>
          <th style={{ textAlign: "left" }}>Seller Information</th>
        </thead>

        {data.map(product => {
          return (
            <tbody>
              <td>
                <div className="img-div">
                  <img
                    src={product.img_url}
                    alt="product"
                    width="100%"
                    height="auto"
                  />
                  <div className="price" style={{ marginLeft: 25 }}>
                    {product.price} EGP
                  </div>
                </div>
              </td>
              <td>
                <div>{product.condition}</div>
              </td>
              <td>
                <div className="seller-information">
                  <h3>{product.sellerInformation.title}</h3>
                  <div>
                    <span>SKU</span>: {product.sellerInformation.sku}
                  </div>
                  <div>
                    <span>Item Id</span>: {product.sellerInformation.item_id}
                  </div>
                  <div>
                    <span>Seller Note</span>:{" "}
                    {product.sellerInformation.seller_note}
                  </div>
                </div>
              </td>
            </tbody>
          );
        })}
      </table>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div
          to="/terms-and-conditions"
          style={{ fontSize: ".9rem", fontWeight: 500, lineHeight: 1.2 }}
        >
          By submitting this request <br /> You agree on term,
          <Link to="/terms-and-conditions">
            {" "}
            Conditions and Privacy policy.
          </Link>
        </div>
        <CustomButton
          type="submit"
          text="Submit Request"
          style={{ padding: "10px 30px", borderRadius: 5, fontWeight: "bold" }}
          onClick={() => {}}
        />
      </div>
    </form>
  );
};

export default ProductTable;
