import { Select } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RenderCheckoutSwitcher } from "..";
import CustomButton from "../../../components/custom-button";

const { Option } = Select;

const CheckoutShippingMethod = () => {
  const { savedAddressData } = useSelector(({ checkout }) => checkout);
  const { push } = useHistory();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  if (!savedAddressData) {
    push("/checkout/save-address");
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  return (
    <div className="formwrapper">
      <RenderCheckoutSwitcher step1 step2 />

      <h1 style={{ marginTop: 30 }}>
        Please select your favorite shipping method
      </h1>
      <Select
        defaultValue="shipping_method"
        style={{ width: 250 }}
        onChange={handleChange}
      >
        <Option value="shipping_method">Government Shipping</Option>
      </Select>

      <div>
        <CustomButton
          onClick={() => push("/checkout/payment-method")}
          text="Continue"
          style={{ padding: "13px 30px", marginTop: 40, fontWeight: 500 }}
        />
      </div>
    </div>
  );
};

export default CheckoutShippingMethod;
