import { Pagination } from "antd";
import { useSelector } from "react-redux";
import CategoriesAllPageNav from "../categories-all-page-main-nav";
import ShopProductItem from "../shop-product-item";
import categoriesAllMain from "./categories-all-main-data";
import { ProductGridWrap } from "./CategoriesAllPageMainEl";

const CategoriesAllPageMain = ({ products }) => {
  const { isGrid } = useSelector(({ ui }) => ui);
  const featuredProducts = useSelector(
    (state) => state.homepage?.data?.featuredProducts
  );

  console.log("FEATURED FROM CAT", featuredProducts?.items);

  // useEffect(() => {
  //   AOS.init({ duration: 2000 });
  // }, []);

  return (
    <div>
      <CategoriesAllPageNav />
      <ProductGridWrap isGrid={isGrid}>
        {products?.map((item, idx) => (
          <ShopProductItem key={idx} {...item} />
        ))}
      </ProductGridWrap>

      <div
        style={{
          width: "auto",
          margin: "50px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          total={85}
          // showTotal={(total, range) =>
          //   `${range[0]}-${range[1]} of ${total} items`
          // }
          defaultPageSize={20}
          defaultCurrent={1}
        />
      </div>
    </div>
  );
};

export default CategoriesAllPageMain;
