import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FormRow } from "../../components/form-elements/constants";
import FormHandler from "../../components/form-elements/form-handler";
import Button from "../../components/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, ContainerWrapper } from "../../constants";
import {
  DontHaveAccount,
  ForgotPasswordInput,
  ForgotPasswordLink,
  LoginForm,
  LoginTitle,
  ResetPasswordContent,
} from "./LoginEl";
import formFields from "./utils";
import { Alert, Col, Row, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAction } from "../../redux/user/user.actions";
import { LoginSchema } from "../../validationSchema";
import CustomButton from "../../components/custom-button";
import { LoadingOutlined } from "@ant-design/icons";
import { forgetPasswordAction } from "../../redux/password/password.actions";
import { HiOutlineMail } from "react-icons/hi";
import "./forgot-popup.css";
import React from "react";
import axios from "axios";
import api from "../../api";
import { SmileOutlined } from "@ant-design/icons";
import { notification, message } from "antd";
import { getWishlistAction } from "../../redux/wishlist/wishlist.actions";
import { getItemsFromCartAction } from "../../redux/cart/cart.actions";

const Login = () => {
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  const [forgetPassModal, setForgetPassModal] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [code, setCode] = React.useState("");
  const { mainColor } = useSelector(({ ui }) => ui);
  const { error, loading } = useSelector(({ user }) => user);
  const [err, setErr] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [forgetStepTwo, setForgetStepTwo] = React.useState(false);
  const { loading: passLoading, data: passData } = useSelector(
    ({ password }) => password
  );
  const callBack = (res) => {
    // push("/");
    console.log("THE LOGIN RESPONSE", res);
    message.success("Logged in successfully");
    dispatch(getWishlistAction(res?.access_token));
    dispatch(getItemsFromCartAction(res?.access_token));
    // window.location.reload();
  };

  // React.useEffect(() => {
  //   if (token) {
  //     dispatch(getWishlistAction(token));
  //     dispatch(getItemsFromCartAction(token));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);
  const openNotification = () => {
    notification.open({
      message: "Password Change",
      description: "Password has been changed successfully",
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(LoginSchema) });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const { email, password } = data;
    dispatch(loginUserAction({ email, password }, callBack));
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const { push } = useHistory();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(forgetPasswordAction(forgetEmail, setForgetStepTwo, setEmailErr));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  useEffect(() => {
    if (token) {
      push("/");
    }
  }, [token, push]);

  const handlePasswordResetSubmit = (e) => {
    e.preventDefault();
    api
      .post(`/forget-password`, {
        email: forgetEmail,
        code,
        password,
        password_confirmation: passwordConfirmation,
      })
      .then(() => {
        setForgetPassModal(false);
        openNotification();
      })
      .catch((err) => setErr(err.response?.data?.msg));
  };

  console.log("THE ERROR", emailErr);

  return (
    <ContainerWrapper>
      <Container>
        <Row justify="center">
          <Col
            lg={{ span: 8 }}
            style={{
              border: "0px solid #6f5dff",
              padding: "35px",
              borderRadius: "10px",
              boxShadow: "0px 0px 15px -2px #6f5dff",
            }}
          >
            <LoginTitle mainColor={mainColor}>Login</LoginTitle>
            {error && <Alert message={error} type="error" />}

            <LoginForm onSubmit={handleSubmit(onSubmit)}>
              <FormHandler
                register={register}
                formFields={formFields}
                errors={errors}
              />
              <FormRow margin="0">
                <DontHaveAccount mainColor={mainColor} to="/registration">
                  Dont't have an account?
                </DontHaveAccount>
                <ForgotPasswordLink
                  mainColor={mainColor}
                  onClick={() => setForgetPassModal(true)}
                >
                  Forgot Password?
                </ForgotPasswordLink>
              </FormRow>
              <FormRow margin="12px 0">
                <Button
                  type="submit"
                  text="Login"
                  width="100%"
                  loading={loading}
                />
              </FormRow>
            </LoginForm>
          </Col>
        </Row>

        {/* <Modal centered visible={showModalSucc} onCancel={() => setshowModalSucc(false)} footer={null} width={700} bodyStyle={{ height: 'unset' }}>
          <div style={{ margin: '0 auto', textAlign: 'center', border: '1px solid #e0e0e0', padding: '20px 10px 25px', boxShadow: '0px 0px 8px #e0e0e0', borderRadius: '8px' }}>
            <div>
              <h3 style={{ fontSize: '25px' }}>Verify Your Email</h3>
              <div style={{ marginBottom: '20px', color: '#a2a3aa', fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                success <AiOutlineCheckCircle />
              </div>
              <CustomButton type="primary" text="verify email" style={{ padding: '8px', borderRadius: '5px' }} />
            </div>
          </div>
        </Modal> */}

        <div className="forget-popup-container">
          <Modal
            style={{
              boxShadow: "0px 0px 8px #eee",
              textAlign: "center !important",
              padding: "5px",
              textAlignLast: "center",
            }}
            title="Please, Enter your Email address!"
            centered
            visible={forgetPassModal}
            onOk={() => setForgetPassModal(false)}
            onCancel={() => setForgetPassModal(false)}
            footer={null}
            className="forgot-popup"
          >
            {passData?.error && (
              <Alert
                message={passData?.error}
                type="error"
                style={{
                  margin: "10px auto",
                  width: "fit-content",
                  fontWeight: "bold",
                }}
              />
            )}
            {passData?.message && (
              <Alert
                message={passData?.message}
                type="success"
                style={{
                  margin: "10px auto",
                  width: "fit-content",
                  fontWeight: "bold",
                }}
              />
            )}
            {!forgetStepTwo ? (
              <form onSubmit={submitHandler}>
                <ResetPasswordContent>
                  <div style={{ padding: "10px" }}>
                    <HiOutlineMail
                      style={{
                        fontSize: "80px",
                        display: "inline-block",
                        width: "100%",
                      }}
                    />
                    {emailErr && <Alert message={emailErr} type="error" />}
                    <ForgotPasswordInput
                      style={{
                        display: "inline-block",
                        with: "100%",
                        marginLeft: "25px",
                      }}
                      type="email"
                      placeholder="Enter Email Address"
                      value={forgetEmail}
                      onChange={(e) => setForgetEmail(e.target.value)}
                      required
                    />
                  </div>
                  <CustomButton
                    type="submit"
                    text={
                      passLoading ? <Spin indicator={antIcon} /> : "Send Code"
                    }
                    style={{
                      width: "50%",
                      margin: "20px auto 20px auto",
                      padding: "7px 0",
                    }}
                  />
                </ResetPasswordContent>
              </form>
            ) : (
              <form onSubmit={handlePasswordResetSubmit}>
                <ResetPasswordContent>
                  <div style={{ padding: "10px" }}>
                    <HiOutlineMail
                      style={{
                        fontSize: "80px",
                        display: "inline-block",
                        width: "100%",
                      }}
                    />
                    {err && <Alert message={err} type="error" />}
                    <ForgotPasswordInput
                      style={{
                        display: "inline-block",
                        with: "100%",
                        marginLeft: "25px",
                      }}
                      type="email"
                      placeholder="Enter Email Address"
                      value={forgetEmail}
                      onChange={(e) => setForgetEmail(e.target.value)}
                      required
                      disabled
                    />
                    {forgetStepTwo ? (
                      <div style={{ marginLeft: "15px", width: "100.5%" }}>
                        <ForgotPasswordInput
                          style={{
                            display: "inline-block",
                            with: "100%",
                            marginLeft: "25px",
                          }}
                          type="text"
                          placeholder="Code"
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                        <ForgotPasswordInput
                          style={{
                            display: "inline-block",
                            with: "100%",
                            marginLeft: "25px",
                          }}
                          type="password"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <ForgotPasswordInput
                          style={{
                            display: "inline-block",
                            with: "100%",
                            marginLeft: "0px",
                          }}
                          type="password"
                          placeholder="Confirm Password"
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                          required
                        />
                      </div>
                    ) : null}
                  </div>

                  <CustomButton
                    type="submit"
                    text={
                      passLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        "Reset Password"
                      )
                    }
                    style={{
                      width: "50%",
                      margin: "20px auto 20px auto",
                      padding: "7px 0",
                    }}
                  />
                </ResetPasswordContent>
              </form>
            )}
          </Modal>
        </div>
      </Container>
    </ContainerWrapper>
  );
};

export default Login;
