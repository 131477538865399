import { useEffect } from "react";
import { motion } from "framer-motion";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import ProductsCategoriesCart from "../../components/products-categories-cart";
import { Container } from "../../constants";
import containerVariants from "../../animation";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesAction } from "../../redux/categories/categories.actions";
import LoadingSpinner from "../../components/loading-spinner";

const ProductsCategoriesPage = () => {
  // const { loading, data, error } = useSelector(({ categories }) => categories);
  const categories = useSelector(
    (state) => state.homepage?.data?.categories_subcategories_childcategories
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(fetchCategoriesAction());
  }, [dispatch]);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
    >
      <Container>
        <PagesBreadCrumb prev="Categories" />
        {categories?.map((item, idx) => (
          <ProductsCategoriesCart key={idx} {...item} />
        ))}
      </Container>
    </motion.div>
  );
};

export default ProductsCategoriesPage;
