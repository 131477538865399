import { Link } from "react-router-dom";
import styled from "styled-components";
import { borderTop, screenLg } from "../../constants";

export const HomepageOurCategoriesSectionWrap = styled.div`
  margin-top: 50px;
  border-top: 1px solid ${borderTop};
`;

export const HomepageOurCategoriesSectionGrid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 10px;

  @media screen and (max-width: ${screenLg}) {
    grid-template-columns: 1fr !important;
  }
`;

export const HomepageOurCategoriesSectionGridLeft = styled(Link)`
  height: 650px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  img {
    object-fit: cover;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    transition: 0.4s ease-in-out;
    width: 100%;
    height: 100%;
  }

  div {
    z-index: 9999;
    position: absolute;
    left: 50%;
    top: 10px;
    font-weight: 600;
    font-size: 2rem;
    color: #fff;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    transform: translate(-50%, 10px);
  }

  @media screen and (max-width: ${screenLg}) {
    height: 450px;
  }
`;

export const HomepageOurCategoriesSectionGridRight = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`;

export const HomepageOurCategoriesSectionItemWrap = styled(Link)`
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #bfbfbf75;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

export const HomepageOurCategoriesSectionItemImg = styled.div`
  position: relative;
  height: 100%;

  img {
    transition: 0.4s ease-in-out;
    width: 100%;
    height: 120px;
    object-fit: contain;
  }

  h4 {
    width: fit-content;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 10px);
    font-weight: 600;
    font-size: 0.8rem;
    color: #00a0ff;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
  }
`;
