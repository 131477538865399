const guestTypes = {
  GUEST_GET_CART_ITEMS_REQUEST: "GUEST_GET_CART_ITEMS_REQUEST",
  GUEST_GET_CART_ITEMS_SUCCESS: "GUEST_GET_CART_ITEMS_SUCCESS",
  GUEST_GET_CART_ITEMS_FAILED: "GUEST_GET_CART_ITEMS_FAILED",

  GUEST_CART_ADD_REQUEST: "GUEST_CART_ADD_REQUEST",
  GUEST_CART_ADD_SUCCESS: "GUEST_CART_ADD_SUCCESS",
  GUEST_CART_ADD_FAILED: "GUEST_CART_ADD_FAILED",

  GUEST_CART_REMOVE_ITEM_REQUEST: "GUEST_CART_REMOVE_ITEM_REQUEST",
  GUEST_CART_REMOVE_ITEM_SUCCESS: "GUEST_CART_REMOVE_ITEM_SUCCESS",
  GUEST_CART_REMOVE_ITEM_FAILED: "GUEST_CART_REMOVE_ITEM_FAILED",

  GUEST_ADD_ITEM_LOCAL: "GUEST_ADD_ITEM_LOCAL",
  GUEST_DECREASE_ITEM_LOCAL: "GUEST_DECREASE_ITEM_LOCAL",
  GUEST_REMOVE_ALL_ITEMS_LOCAL: "GUEST_REMOVE_ALL_ITEMS_LOCAL",
  GUEST_REMOVE_ITEM_FROM_CART_LOCAL: "GUEST_REMOVE_ITEM_FROM_CART_LOCAL",
};

export default guestTypes;
