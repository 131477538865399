import localCompareTypes from "./localCompare.types"

export const getCompare = (data) => dispatch => {
    dispatch({
        type: localCompareTypes.GET_COMAPARE,
        payload: data
    })
}
export const localCompareAdd = (data) => dispatch => {
    dispatch({
        type: localCompareTypes.ADD_ITEM,
        payload: data
    })
}
export const deleteLocalCompareItem = (data) => dispatch => {
    dispatch({
        type: localCompareTypes.DELETE_ITEM,
        payload: data
    })
}
export const clearLocalCompare = (data) => dispatch => {
    dispatch({
        type: localCompareTypes.CLEAR_COMPARE,
        payload: data
    })
}