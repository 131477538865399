import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Select, Tooltip } from "antd";
import Logo from "../../assets/images/dento-logo.png";
import { Container } from "../../constants";
import {
  AppMainHeaderBottom,
  AppMainHeaderBottomLeft,
  AppMainHeaderBottomRight,
  AppMainHeaderContent,
  AppMainHeaderWrap,
  MainHeaderRightBox,
  MainHeaderRightBoxCount,
  MainHeaderRightBoxUser,
  MainHeaderRightBoxUserLinks,
  MainHeaderRightWrap,
  ProductsSearchIcon,
  ProductsSearchInput,
  SearchForm,
} from "./AppMainHeaderEl";
import { AiOutlineSearch } from "react-icons/ai";
import {
  DownOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IoStatsChart } from "react-icons/io5";
import { Dropdown, Menu, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserAction } from "../../redux/user/user.actions";
import {
  changeRegistrationActiveTab,
  logoutVendorAction,
} from "../../redux/vendor/vendor.action";
import { logoutBuyerAction } from "../../redux/buyer/buyer.action";
import { logoutCustomerAction } from "../../redux/customer/customer.actions";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const BuyerMenu = (
  <Menu>
    <Menu.Item key={1}>
      <div>Star Buyer</div>
    </Menu.Item>
    <Menu.Item key={2}>
      <div>Corporate</div>
    </Menu.Item>
  </Menu>
);

const LangMenu = (
  <Menu>
    <Menu.Item key={1}>
      <div>English</div>
    </Menu.Item>
    <Menu.Item key={2}>
      <div>Arabic</div>
    </Menu.Item>
  </Menu>
);

const currencyMenu = (
  <Menu>
    <Menu.Item key={1}>
      <div>EGP</div>
    </Menu.Item>
    <Menu.Item key={2}>
      <div>AED</div>
    </Menu.Item>
  </Menu>
);

const AppMainHeader = () => {
  const [searchProduct, setSearchProduct] = useState("");
  const { mainColor } = useSelector(({ ui }) => ui);
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  console.log("THE TOKENNN", token);
  const { data: customerData } = useSelector(({ customer }) => customer);
  const { data: vendorData } = useSelector(({ vendor }) => vendor);
  const { data: buyerData } = useSelector(({ buyer }) => buyer);
  const { cartItems } = useSelector(({ guest }) => guest);
  const { push } = useHistory();
  const wishListLength = useSelector(
    (state) => state?.wishlist?.wishlist?.length
  );
  const localWishlistLength = useSelector(
    (state) => state?.localWishlist?.wishlist?.length
  );
  const localCompareLength = useSelector(
    (state) => state?.localCompare?.compareList?.length
  );
  const cartLength = useSelector(
    (state) => state?.cart?.cartItems?.card?.length
  );
  const localCartLength = useSelector(
    (state) => state?.localCart?.cartItems?.length
  );
  console.log("WISHLIST LENGTH", wishListLength);

  console.log("TOKENNNN", token);

  const dispatch = useDispatch();

  const logoutSuccess = () => {
    message.warning("You have been logged out successfully");
  };

  const cartProductsCount = cartItems?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  );

  const handleSearchProduct = () => {
    if (!searchProduct) {
      return;
    }

    push(`/products/search/${searchProduct}`);
  };

  return (
    <AppMainHeaderWrap mainColor={mainColor}>
      <Container>
        <AppMainHeaderBottom>
          <AppMainHeaderBottomLeft>
            <Link to="/">Home</Link>
            <Link to="/categories">Categories</Link>
            <Link to="/categories">News</Link>
          </AppMainHeaderBottomLeft>

          <AppMainHeaderBottomRight>
            <Link to="/order-tracking">Order Tracking</Link>
            {!token && (
              <Link
                to="/registration"
                onClick={() => dispatch(changeRegistrationActiveTab())}
              >
                Become a Vendor
              </Link>
            )}
            {/* <Dropdown
              overlay={BuyerMenu}
              placement="bottomRight"
              style={{ marginRight: 12 }}
            >
              <div
                className="ant-dropdown-link"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  marginLeft: 15,
                  fontWeight: 100,
                }}
              >
                Buyer <DownOutlined />
              </div>
            </Dropdown>
            <Dropdown
              overlay={LangMenu}
              placement="bottomRight"
              style={{ marginRight: 12 }}
            >
              <div
                className="ant-dropdown-link"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  marginLeft: 15,
                  fontWeight: 100,
                }}
              >
                EN <DownOutlined />
              </div>
            </Dropdown>
            <Dropdown
              overlay={currencyMenu}
              placement="bottomRight"
              style={{ marginRight: 12 }}
            >
              <div
                className="ant-dropdown-link"
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  marginLeft: 15,
                  fontWeight: 100,
                }}
              >
                Currency <DownOutlined />
              </div>
            </Dropdown> */}
          </AppMainHeaderBottomRight>
        </AppMainHeaderBottom>

        <AppMainHeaderContent>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
          {/* Products Search */}
          <SearchForm onSubmit={handleSearchProduct}>
            <Select
              showSearch
              style={{
                width: 200,
                outline: "none",
                border: "none",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value="categories">Categories</Option>
              <Option value="products">Products</Option>
              <Option value="vendor">Vendor</Option>
            </Select>

            <ProductsSearchInput
              type="text"
              value={searchProduct}
              onChange={(e) => setSearchProduct(e.target.value)}
              placeholder="Search..."
            />
            <ProductsSearchIcon onClick={handleSearchProduct}>
              <AiOutlineSearch />
            </ProductsSearchIcon>
          </SearchForm>
          <MainHeaderRightWrap>
            <MainHeaderRightBox to="/compare">
              <IoStatsChart />
              <MainHeaderRightBoxCount>
                {localCompareLength}
              </MainHeaderRightBoxCount>
            </MainHeaderRightBox>

            <MainHeaderRightBox to="/wishlist">
              <HeartOutlined />
              <MainHeaderRightBoxCount>
                {token ? wishListLength : localWishlistLength}
              </MainHeaderRightBoxCount>
            </MainHeaderRightBox>

            <MainHeaderRightBox to="/shopping-cart">
              <ShoppingCartOutlined />
              <MainHeaderRightBoxCount>
                {token ? cartLength : localCartLength}
              </MainHeaderRightBoxCount>
            </MainHeaderRightBox>

            <MainHeaderRightBoxUser>
              <Link to="/customer-buyer-dashboard">
                <UserOutlined
                  style={{
                    marginRight: "5px",
                    color: "#fff",
                    textDecoration: "none",
                  }}
                />
              </Link>
              <MainHeaderRightBoxUserLinks>
                {/* app@app.app */}
                {vendorData?.message || buyerData?.message ? (
                  <Tooltip
                    color="orange"
                    placement="bottom"
                    title={
                      customerData?.message
                        ? ""
                        : "Your account is pending now while approve from Admin!"
                    }
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(logoutCustomerAction());
                        dispatch(logoutUserAction());
                        dispatch(logoutVendorAction());
                        dispatch(logoutBuyerAction());
                        logoutSuccess();
                      }}
                    >
                      {/* Logout {vendorData?.message || customerData?.message || buyerData?.message ? '(Pending)' : null} */}
                      Logout{" "}
                      {vendorData?.message ||
                      // customerData?.message ||
                      buyerData?.message
                        ? // <span style={{ color: "orange", fontWeight: 700 }}>
                          //   (Pending)
                          // </span>
                          null
                        : null}
                    </div>
                  </Tooltip>
                ) : token ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(logoutCustomerAction());
                      dispatch(logoutUserAction());
                      dispatch(logoutVendorAction());
                      dispatch(logoutBuyerAction());
                      logoutSuccess();
                    }}
                  >
                    {/* Logout {vendorData?.message || customerData?.message || buyerData?.message ? '(Pending)' : null} */}
                    Logout{" "}
                    {vendorData?.message ||
                    // customerData?.message ||
                    buyerData?.message ? (
                      <span style={{ color: "orange", fontWeight: 700 }}>
                        (Pending)
                      </span>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <Link to="/login">Login</Link>
                    <Link to="/registration">Register</Link>{" "}
                  </>
                )}
              </MainHeaderRightBoxUserLinks>
            </MainHeaderRightBoxUser>
          </MainHeaderRightWrap>
        </AppMainHeaderContent>
      </Container>
    </AppMainHeaderWrap>
  );
};

export default AppMainHeader;
