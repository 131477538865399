import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomInput from "../form-elements/custom-input";
import CustomButton from "../custom-button";
import ReCAPTCHA from "react-google-recaptcha";
import vendorFields from "./utils/vendorFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VendorSignupSchema } from "../../validationSchema";
import { ErrorMessage } from "@hookform/error-message";
import FormErrors from "../form-errors";
// import MediaUploader from "../media-uploader";
import GoogleLoginComp from "../google-login";
import FacebookLoginComp from "../facebook-login";
import { useSelector, useDispatch } from "react-redux";
import {
  // vendorClearMessages,
  vendorRegisterAction,
} from "../../redux/vendor/vendor.action";
import api from "../../api";
import { useHistory } from "react-router-dom";

// Some Styles
const btnStyle = {
  border: "none",
  textAlign: "center",
  color: "#fff",
  padding: "10px 20px",
  margin: "30px 0",
  display: "block",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontWeight: 600,
  width: "100%",
};

const VendorSignupForm = () => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const [apiErrors, setApiErrors] = useState({});
  const { loading, data, error } = useSelector(({ vendor }) => vendor);
  const [apiLoading, setApiLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState("");
  const [isARobot, setIsARobot] = useState(true);
  const [isARobotMsg, setIsARobotMsg] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUploadFileMsg, setIsUploadFileMsg] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const history = useHistory();
  const [agree, setAgree] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(VendorSignupSchema) });
  const [recaptchaError, setRecaptchaError] = useState("");

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    // if (isARobot) {
    //   setIsARobotMsg(true);
    //   return;
    // }
    if (!isARobot) {
      if (!agree) {
        message.error("Please accept the terms and conditions");
        return;
      }
      if (!isUploadFile) {
        setIsUploadFileMsg(true);
        return;
      }

      const info = new FormData();
      info.append("name", data.name);
      info.append("company_name", data.company_name);
      info.append("contact_person_title", data.contact_person_title);
      info.append("email", data.email);
      info.append("password", data.password);
      info.append("password_confirmation", data.password_confirmation);
      info.append("mobile", data.mobile);
      info.append("documentation", uploadedFiles);
      setApiLoading(true);
      api
        .post(`vendor-register`, info)
        .then(() => {
          history.push("/");
          message.success("Please wait for admin approval");
          setApiLoading(false);
        })
        .catch((err) => {
          setApiErrors(err?.response?.data);
          setApiLoading(false);
        });
    } else {
      setRecaptchaError("Please pass the recaptcha first");
    }
  };

  const onChange = (value) => {
    if (value) {
      setIsARobot(false);
      setIsARobotMsg(false);
    }
  };

  let keys;
  if (error) {
    keys = Object.values(error);
  }

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <>
      {keys &&
        keys.map((el) => (
          <Alert
            message={<b>{el}</b>}
            type="error"
            style={{ marginBottom: 10 }}
          />
        ))}

      {data?.error && (
        <Alert
          message={data?.error}
          type="error"
          style={{
            margin: "10px auto",
            width: "fit-content",
            fontWeight: "bold",
          }}
        />
      )}
      {data?.message && (
        <Alert
          message={data?.message}
          type="success"
          style={{
            margin: "10px auto",
            width: "fit-content",
            fontWeight: "bold",
          }}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 30 }}>
        {vendorFields.map(
          ({ name, type, placeholder, labelText, id, htmlFor }, idx) => (
            <div style={{ marginBottom: 20 }} key={idx}>
              <CustomInput
                type={type}
                placeholder={placeholder}
                htmlFor={htmlFor}
                id={id}
                labelText={labelText}
                {...register(name)}
                control={control}
              />
              {apiErrors[name]?.map((item, index) => {
                return (
                  <div className="error-message" key={index}>
                    {item}
                  </div>
                );
              })}
            </div>
          )
        )}

        <input
          id="company_paper"
          type="file"
          accept="application/zip"
          onChange={(e) => {
            setUploadedFiles(e.target.files[0]);
            setIsUploadFile(true);
          }}
          // required
          style={{ marginTop: 10 }}
        />
        {isUploadFileMsg && (
          <p
            style={{
              width: "100%",
              marginTop: ".25rem",
              fontSize: "80%",
              color: "red",
              fontWeight: 700,
            }}
          >
            Company papers is a required field
          </p>
        )}

        <div className="form-group">
          <div
            className="form-check"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              {...register("terms")}
              onChange={() => setAgree(!agree)}
            />
            <label
              className="form-check-label"
              htmlFor="terms"
              style={{ marginLeft: 10 }}
            >
              I agree to terms and conditions and{" "}
              <Link
                to="/privacy"
                className="d-inline"
                style={{ color: mainColor, fontWeight: "bold" }}
              >
                Privacy Policy of Dento Home
              </Link>
            </label>
          </div>
          <ErrorMessage errors={errors} name="terms" render={FormErrors} />
        </div>

        <div style={{ marginTop: 25 }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTHCA_PUBLIC}
            onChange={onChange}
          />
          <div className="error-message">{recaptchaError}</div>
          {isARobotMsg && (
            <p
              style={{
                width: "100%",
                marginTop: ".25rem",
                fontSize: "80%",
                color: "red",
                fontWeight: 700,
              }}
            >
              I am not a robot is a required field
            </p>
          )}
        </div>

        <CustomButton
          type="submit"
          text={
            apiLoading ? (
              <Spin indicator={antIcon} style={{ color: "#fff" }} size={12} />
            ) : (
              "Submit"
            )
          }
          style={{
            ...btnStyle,
            backgroundColor: mainColor,
            cursor: loading ? "not-allowed" : "pointer",
          }}
          disabled={loading ? true : false}
        />
      </form>
      <div className="social-or-el">Or</div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <GoogleLoginComp />
        <FacebookLoginComp />
      </div>
    </>
  );
};

export default VendorSignupForm;
