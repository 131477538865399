const commonStyles = {
  label: {
    width: "100%",
    padding: "0 0 6px 0",
  },
  inputWrapper: {
    width: "100%",
  },
  formRow: {
    margin: "20px 0",
  },
};
const formFields = [
  {
    name: "order_id",
    labelText: "Order Id",
    type: "text",
    style: commonStyles,
  },
  {
    name: "email",
    labelText: "Billing Email",
    type: "email",
    style: commonStyles,
  },
];

export default formFields;
