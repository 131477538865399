import styled from "styled-components";
import { screenLg } from "../../../constants";

export const GalleryWrapper = styled.div`
  width: 49%;
  height: fit-content;

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border: 2px solid ${({ mainColor }) => mainColor} !important;
  }

  .image-gallery-thumbnail:hover {
    border: 2px solid ${({ mainColor }) => mainColor} !important;
  }

  @media screen and (max-width: ${screenLg}) {
    width: 100%;
  }
`;
