import styled from "styled-components";

export const CategoriesAllPageSimilarProductsWrap = styled.div``;

export const CategoriesAllPageSimilarTitle = styled.div`
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 1.1rem;
`;

export const CategoriesAllPageSimilarProductsItem = styled.div`
  padding: 15px;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const CategoriesAllPageSimilarProductsBox = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  /* padding: 10px; */
`;

export const CategoriesAllPageSimilarProductsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul li {
    svg {
      font-size: 13px;
    }
    margin-right: 0 !important;
  }

  svg {
    font-size: 18px;
    cursor: pointer;
  }
`;
