import styled from "styled-components";

export const HomepageQuickFeaturesWrap = styled.div`
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 40px 0;
  margin-top: 50px;
`;

export const HomepageQuickFeaturesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  /* width: 80%; */
  margin: 0 auto;
`;

export const HomepageQuickFeaturesItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HomepageQuickFeaturesItemIcon = styled.div`
  color: #333;
  font-size: 3rem;
  margin-right: 20px;
`;

export const HomepageQuickFeaturesItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left !important;
`;
