import api from "../../api";
import richTextTypes from "./richText.types";

export const fetchRichTextAction = (pageId) => {
  return async (dispatch) => {
    dispatch({
      type: richTextTypes.FETCH_RICH_TEXT_REQUEST,
    });
    api
      .get(`/pages/${pageId}`)
      .then(({ data }) =>
        dispatch({
          type: richTextTypes.FETCH_RICH_TEXT_SUCCESS,
          payload: data.data,
        })
      )
      .catch((err) => {
        dispatch({
          type: richTextTypes.FETCH_RICH_TEXT_ERROR,
          payload:
            err.response && err.response.data.error
              ? err.response.data.error
              : err.message,
        });
      });
  };
};
