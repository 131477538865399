import styled from 'styled-components/macro';
import { screenSm } from '../../../constants';

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(props) => props.justifyContent ?? 'space-between'};
  margin: ${(props) => props.margin ?? '35px 0'};
  align-items: baseline;
`;

export const FormLabel = styled.label`
  /* flex: ${(props) => props.flex ?? '1'}; */
  flex: 1;
  font-weight: 600;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: left;
  padding-right: 20px;

  @media screen and (max-width: ${screenSm}) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  flex: 4.8;

  & button {
    border: 20px solid;
    width: 100%;
    border-radius: 25px;
  }

  div.ant-radio-group {
    display: flex;
    /* justify-content: space-around; */
  }

  @media screen and (max-width: ${screenSm}) {
    width: 100%;
    padding: 6px 0;
    .ant-radio-group.ant-radio-group-outline {
      justify-content: flex-start;
    }
  }
`;
