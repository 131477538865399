import { Spin } from "antd";
import { ButtonWrap, LoadingSpinnerWrapper } from "./ButtonEl";
import { useSelector } from "react-redux";

const Button = ({
  type,
  text,
  style,
  onClick,
  disabled,
  children,
  loading,
  ...rest
}) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <ButtonWrap
      type={type}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      mainColor={mainColor}
      {...rest}
    >
      {children}
      {text}
      {loading && (
        <LoadingSpinnerWrapper>
          <Spin />
        </LoadingSpinnerWrapper>
      )}
    </ButtonWrap>
  );
};

export default Button;
