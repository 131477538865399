import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets/images";
import { Container } from "../../../constants";
import { ToggleMobileSidebar } from "../main-header/MainHeaderEl";
import { MobileHeaderContent, MobileHeaderWrapper } from "./MobileHeaderEl";

const MobileHeader = ({ setIsMobileSidebarOpen }) => {
  return (
    <MobileHeaderWrapper>
      <Container>
        <MobileHeaderContent>
          <Link to="/">
            <img src="./assets/dent-logo-blue.png" alt="logo" />
          </Link>

          <ToggleMobileSidebar onClick={() => setIsMobileSidebarOpen(true)}>
            <FaBars
              style={{
                fontSize: 30,
              }}
            />
          </ToggleMobileSidebar>
        </MobileHeaderContent>
      </Container>
    </MobileHeaderWrapper>
  );
};

export default MobileHeader;
