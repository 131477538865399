import styled from "styled-components";
import { screenLg } from "../../../constants";

export const FAQsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SearchWrapper = styled.div`
  width: 100%;
  padding: 22px 0;

  .ant-select {
    margin-top: 25px;
    width: 100%;
  }

  @media screen and (min-width: ${screenLg}) {
    .ant-input-group-wrapper {
      width: 70%;
    }
    .ant-select {
      width: 25%;
      margin-left: 25px;
      margin-top: 0px;
    }
  }
`;
export const SearchForm = styled.form``;
export const FAQsContent = styled.div`
  .ant-collapse-item:first-of-type {
    border-top: 1px solid #d9d9d9;
  }
  .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const Answer = styled.p`
  line-height: 1.8;
  font-size: 1rem;
`;
