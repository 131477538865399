import styled from "styled-components";

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ mainColor }) => mainColor};
  padding: 0;
  margin: 0;
`;

export const RichTextWrapper = styled.div`
  padding-bottom: 12px;
`;
