import { useSelector } from "react-redux";
import { CustomerBuyerDashboardSwitcherItemWrap } from "../../pages/customer-buyer-dashboard/CustomerBuyerDashboardEl";
import customerSwitcherData from "./customer-switcher-data";

const CustomerBuyerDashboardSwitcherItem = ({
  activeTab,
  setActiveTab,
  name,
  title,
  icon,
  mainColor,
}) => {
  return (
    <CustomerBuyerDashboardSwitcherItemWrap
      to="customer-grid"
      smooth
      duration={500}
      offset={-150}
      isActiveTab={activeTab === name ? true : false}
      onClick={() => setActiveTab(name)}
      mainColor={mainColor}
    >
      <span>{icon}</span>
      <span>{title}</span>
    </CustomerBuyerDashboardSwitcherItemWrap>
  );
};

const CustomerBuyerDashboardSwitcher = ({ activeTab, setActiveTab }) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <div>
      {customerSwitcherData.map((item, idx) => (
        <CustomerBuyerDashboardSwitcherItem
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          key={idx}
          mainColor={mainColor}
          {...item}
        />
      ))}
    </div>
  );
};

export default CustomerBuyerDashboardSwitcher;
