import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PagesBreadCrumbEl } from "./PagesBreadCrumbEl";

const PagesBreadCrumb = ({ prev, curr }) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <PagesBreadCrumbEl>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
            <HomeOutlined style={{ color: mainColor }} />
          </Link>
        </Breadcrumb.Item>

        {prev === "home" ? null : (
          <Breadcrumb.Item>
            <Link to={prev} style={{ color: mainColor }}>
              {prev}
            </Link>
          </Breadcrumb.Item>
        )}

        <Breadcrumb.Item>{curr}</Breadcrumb.Item>
      </Breadcrumb>
    </PagesBreadCrumbEl>
  );
};

export default PagesBreadCrumb;
