const formFields = [
  {
    name: "type",
    labelText: "Type",
    type: "radio",
    options: [
      { label: "Vendor", value: 1 },
      { label: "Item", value: 2 },
    ],
  },
  {
    name: "subject",
    labelText: "Subject",
    type: "select",
  },
  {
    name: "details",
    labelText: "Details",
    placeholder: "Details",
    type: "textarea",
  },
];

export default formFields;
