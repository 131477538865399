/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import MobileSideBar from "./components/mobile-sidebar";
import TestPage from "./components/TestPage";
import HomePopup from "./components/home-popup/home-popup";
// import "bootstrap/dist/css/bootstrap.min.css";

// Pages
import HomePage from "./pages";
import PageNotFound from "./pages/404";
import CategoriesAllPage from "./pages/categories-all";
import CorporateBuyerDashboard from "./pages/corporate-buyer-dashboard";
import CustomerBuyerDashboard from "./pages/customer-buyer-dashboard";
import ProductsCategoriesPage from "./pages/products-categories";
import Registration from "./pages/registration";
import Login from "./pages/login";
import ReviewSupplier from "./pages/review-supplier";
import Compliant from "./pages/compliant";
import SendInquiry from "./pages/send-inquiry";
import RequestBid from "./pages/request-bid";
import OrderTracking from "./pages/order-tracking";
import ProductDetails from "./pages/product-details";
import SearchPage from "./pages/search-page";
import ShoppingCartPage from "./pages/shopping-page";
import VendorBuyerDashboard from "./pages/vendor-buyer-dashboard";
import WhishListPage from "./pages/whishlist-page";
import { useWindowSize } from "./components/useWindowSize/useWindowSize";
import MobileHeader from "./components/header/mobile-header";
import RichTextPage from "./pages/rich-text-page";
import ContactUsPage from "./pages/contact-us";
import AppMainHeader from "./components/app-main-header";
import AppColorSwitcher from "./components/app-color-switcher";
import AppFooter from "./components/app-footer/index";
import { useDispatch, useSelector } from "react-redux";
import ProductsCompare from "./pages/products-compare";
import CheckoutSaveAddress from "./pages/checkout-page/sub-routes/CheckoutSaveAddress";
import CheckoutShippingMethod from "./pages/checkout-page/sub-routes/CheckoutShippingMethod";
import CheckoutOrderNow from "./pages/checkout-page/sub-routes/CheckoutOrderNow";
import CheckoutPaymentMethod from "./pages/checkout-page/sub-routes/CheckoutPaymentMethod";
import { GetHomepageDataAction } from "./redux/homepage/homepage.actions";
import { getWishlistAction } from "./redux/wishlist/wishlist.actions";
import { getItemsFromCartAction } from "./redux/cart/cart.actions";
import LocalCart from "./pages/shopping-page/localCart";
import api from "./api";
import { clearLocalWishlist } from "./redux/localWishlist/localWishlist.actions";
import { clearLocalCart } from "./redux/localCart/localCart.actions";
import { getAddresses } from "./redux/address/address.actions";

const App = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isIsMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  const { data: customerData } = useSelector(({ customer }) => customer);
  const { data: vendorData } = useSelector(({ vendor }) => vendor);
  const { data: buyerData } = useSelector(({ buyer }) => buyer);
  const { pathname } = useLocation();
  const localWishlistItems = useSelector(
    (state) => state?.localWishlist?.wishlist
  );
  const wishListItemsIds = localWishlistItems?.map((item) => item?.id);
  const wishlistReqData = new FormData();
  wishlistReqData.append("prods_id", wishListItemsIds);
  wishlistReqData.append("token", token);
  const localCartItems = useSelector((state) => state?.localCart?.cartItems);
  const cartIdsAndQuns = localCartItems?.map(
    (item) => item.item.id + "&&" + item?.quantity
  );
  const cartReqData = new FormData();
  cartReqData.append("prods_with_quan", cartIdsAndQuns?.toString());
  cartReqData.append("token", token);

  useEffect(() => {
    if (token) {
      if (localWishlistItems.length > 0) {
        api.post("/add-item-wishlist-guest", wishlistReqData).then(() => {
          dispatch(getWishlistAction(token));
          dispatch(clearLocalWishlist());
        });
      }
      if (localCartItems.length > 0) {
        api.post("/add-to-cart-guest", cartReqData).then(() => {
          dispatch(getItemsFromCartAction(token));
          dispatch(clearLocalCart());
        });
      }
    }
  }, [token]);

  const location = useLocation();
  // const { pathname } = useLocation();

  console.log("TOKEN FROM INSIDE", token);

  const { isMobileOrTablet } = useWindowSize();

  const renderHeader = useCallback(() => {
    if (isMobileOrTablet)
      return <MobileHeader setIsMobileSidebarOpen={setIsMobileSidebarOpen} />;
    // const isMiniHeader = pathname.includes('/review-supplier') || pathname.includes('/compliant') || pathname.includes('/send-inquiry') || pathname.includes('/request-bid') || pathname.includes('/order-tracking');
    // if (isMiniHeader) return <MiniHeader />;

    return <AppMainHeader setIsMobileSidebarOpen={setIsMobileSidebarOpen} />;
  }, [isMobileOrTablet]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const showpopup = useSelector(({ ui }) => ui.showPopup);

  // useEffect(() => {
  //   GetHomepageDataAction(token)
  // }, [])

  // useEffect(() => {
  //   setTimeout(() => setShowModal(showpopup), 5000);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    dispatch(GetHomepageDataAction(token));
    dispatch(getWishlistAction(token));
    dispatch(getItemsFromCartAction(token));
    dispatch(getAddresses(token));
  }, []);
  return (
    <>
      {showModal && (
        <HomePopup showModal={showModal} setShowModal={setShowModal} />
      )}
      <AppColorSwitcher />
      <MobileSideBar
        isOpen={isIsMobileSidebarOpen}
        setIsOpen={setIsMobileSidebarOpen}
      />
      <AnimatePresence location={location} key={location.key}>
        {renderHeader()}
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            path="/categories/main/:mainId"
            component={CategoriesAllPage}
          />
          <Route
            path="/categories/sub/:subId/main/:mainId"
            component={CategoriesAllPage}
          />
          <Route
            path="/categories/child/:childId/main/:mainId/sub/:subId"
            component={CategoriesAllPage}
          />
          <Route
            exact
            path="/login"
            render={() => (token ? <Redirect to="/" /> : <Login />)}
          />
          <Route
            exact
            path="/registration"
            render={
              // () => <Registration />

              // () => (vendorData?.message || customerData?.message ? <Redirect to="/" /> : <Registration />)

              () =>
                vendorData?.message ||
                customerData?.message ||
                buyerData?.message ? (
                  <Redirect to="/" />
                ) : (
                  <Registration />
                )
            }
          />
          <Route exact path="/review-supplier" component={ReviewSupplier} />
          <Route exact path="/compliant" component={Compliant} />
          <Route exact path="/send-inquiry" component={SendInquiry} />
          <Route exact path="/request-bid" component={RequestBid} />
          <Route path="/order-tracking" component={OrderTracking} />
          <Route
            exact
            path="/shopping-cart"
            render={() =>
              vendorData?.message || buyerData?.message ? (
                <Redirect to="/" />
              ) : token ? (
                <ShoppingCartPage />
              ) : (
                <LocalCart />
              )
            }
          />
          <Route exact path="/wishlist" component={WhishListPage} />
          <Route exact path="/compare" component={ProductsCompare} />
          {/* <Route exact path="/checkout" component={CheckoutPage} /> */}

          <Route
            exact
            path="/checkout/save-address"
            render={() =>
              !token ? <Redirect to="/login" /> : <CheckoutSaveAddress />
            }
          />
          <Route
            exact
            path="/checkout/shipping-method"
            render={() =>
              !token ? <Redirect to="/login" /> : <CheckoutShippingMethod />
            }
          />
          <Route
            exact
            path="/checkout/payment-method/:addressId"
            render={() =>
              !token ? <Redirect to="/login" /> : <CheckoutPaymentMethod />
            }
          />
          <Route
            exact
            path="/checkout/place-order/:addressId/:paymentMethod"
            render={() =>
              !token ? <Redirect to="/login" /> : <CheckoutOrderNow />
            }
          />

          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/terms-and-conditions">
            <RichTextPage pageId={4} />
          </Route>
          <Route exact path="/privacy-policy">
            <RichTextPage pageId={11} />
          </Route>
          <Route exact path="/payment-policy">
            <RichTextPage pageId={9} />
          </Route>
          <Route exact path="/refund-policy">
            <RichTextPage pageId={3} />
          </Route>
          <Route exact path="/return-policy">
            <RichTextPage pageId={2} />
          </Route>
          <Route exact path="/shipping-policy">
            <RichTextPage pageId={10} />
          </Route>
          <Route exact path="/terms-of-use">
            <RichTextPage pageId={5} />
          </Route>
          <Route
            exact
            path="/product-details/:productId"
            component={ProductDetails}
          />
          <Route exact path="/categories" component={ProductsCategoriesPage} />
          <Route exact path="/products/search/:name" component={SearchPage} />
          <Route
            exact
            path="/customer-buyer-dashboard"
            component={CustomerBuyerDashboard}
          />
          <Route
            exact
            path="/corporate-buyer-dashboard"
            component={CorporateBuyerDashboard}
          />
          <Route
            exact
            path="/vendor-buyer-dashboard"
            component={VendorBuyerDashboard}
          />
          <Route path="/test" component={TestPage} />
          <Route component={PageNotFound} />
        </Switch>
        <AppFooter />
      </AnimatePresence>
    </>
  );
};

export default App;
