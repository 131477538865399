import axios from "axios"
import loclCartTypes from "./localCart.types"

export const getLocalCart = (data) => dispatch => {
    dispatch({
        type: loclCartTypes.GET_CART,
        payload: data
    })
}
export const localCartAdd = (data) => dispatch => {
    dispatch({
        type: loclCartTypes.ADD_ITEM,
        payload: data
    })
}
export const deleteLocalItem = (data) => dispatch => {
    dispatch({
        type: loclCartTypes.DELETE_ITEM,
        payload: data
    })
}
export const clearLocalCart = (data) => dispatch => {
    dispatch({
        type: loclCartTypes.CLEAR_CART,
        payload: data
    })
}
export const localQuantityPlus = (data) => dispatch => {
    dispatch({
        type: loclCartTypes.QUANTITY_PLUS,
        payload: data
    })
}
export const localQuantityMinus = (data) => dispatch => {
    dispatch({
        type: loclCartTypes.QUANTITY_MINUS,
        payload: data
    })
}