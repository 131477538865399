import checkoutType from "./checkout.type";

const initialState = {
  loading: false,
  data: [],
  error: null,
  savedAddressData: null,
};

export const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkoutType.CHECKOUT_GET_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case checkoutType.CHECKOUT_GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case checkoutType.CHECKOUT_GET_ADDRESSES_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    case checkoutType.CHECKOUT_SAVE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        savedAddressData: null,
        error: null,
      };
    case checkoutType.CHECKOUT_SAVE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedAddressData: action.payload,
        error: null,
      };
    case checkoutType.CHECKOUT_SAVE_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        savedAddressData: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
