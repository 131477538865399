import styled from "styled-components";
import { mainColor } from "../../constants";

export const ShoppingCartTableTitle = styled.h1`
  color: ${({ mainColor }) => mainColor};
  font-weight: 700;
  text-align: center;
  font-size: 3rem;
`;
export const ShoppingCartTableWrap = styled.div`
  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
`;

export const TableQuantity = styled.td`
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    border: 1px solid ${mainColor};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: ${mainColor};
    padding: 4px;
    cursor: pointer;
  }

  svg:not(:first-of-type) {
    margin-left: 5px;
  }
`;

export const TableDeleteIcon = styled.div`
  svg {
    color: #dc0707;
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export const TableDeleteModalIcon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px auto;
  position: absolute;
  background: #fff;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #ddd;

  svg {
    color: #dc0707;
    font-size: 2.2rem;
    cursor: pointer;
  }
`;

export const TableDeleteModalMsg = styled.p`
  padding: 65px 20px 20px 20px;
  text-align: center;
  font-weight: 600;
`;

export const TableDeleteModalBtnWrap = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`;
