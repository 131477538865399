import api from "../api"
import { message } from 'antd'

export const wishlistAddRemove = (id, callBack, token) => {
    api.post(`/wishlist/add-and-remove/${id}`, { token })
        .then(res => {
            console.log(res?.data?.msg)
            message.success(res?.data?.msg)
            callBack()
        })
}