const ourCategories = [
  {
    name: "Hematology and pathology devices",
    imgSrc:
      "https://images.pexels.com/photos/4531304/pexels-photo-4531304.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    name: "Immunology and microbiology devices",
    imgSrc:
      "https://media.istockphoto.com/photos/antibody-immunoglobulin-picture-id1219978307?s=612x612",
  },
  {
    name: "Anesthesiology devices",
    imgSrc:
      "https://media.istockphoto.com/photos/nurse-working-with-technology-in-operating-room-picture-id1139849841?s=612x612",
  },
  {
    name: "Cardiovascular devices",
    imgSrc:
      "https://media.istockphoto.com/photos/adult-and-child-hands-holding-red-heart-on-aqua-background-heart-picture-id1169662101?k=20&m=1169662101&s=612x612&w=0&h=hNnZj1iEmplLOcl-0_T7ibU337bU-JqtfZNaD78CED0=",
  },
  {
    name: "Dental devices",
    imgSrc:
      "https://media.istockphoto.com/photos/set-of-metal-dentists-medical-equipment-tools-picture-id639693680?k=20&m=639693680&s=612x612&w=0&h=h79O48IDQ2can0L1xG2ikjsZj_vaZ40KkHwKT-5_Crs=",
  },
  {
    name: "Ear,nose and throat devices",
    imgSrc:
      "https://media.istockphoto.com/photos/serious-man-looking-at-nose-of-her-visitor-picture-id673337824?k=20&m=673337824&s=612x612&w=0&h=kHoORnzkQM2nj0sFcLBd5bJ5HFnd_8YfcArKwgMc4CQ=",
  },
  {
    name: "Gastroenterology – urology devices",
    imgSrc:
      "https://media.istockphoto.com/photos/support-and-care-of-the-human-stomach-picture-id654036286?k=20&m=654036286&s=612x612&w=0&h=Iqu5jIccmDnAi5QEH6G6Mx-yrECPVR5Co0Y-cRay1jY=",
  },
  {
    name: "General and plastic surgery devices",
    imgSrc:
      "https://media.istockphoto.com/photos/doctor-holding-syringe-picture-id526321005?k=20&m=526321005&s=612x612&w=0&h=taVcG8ME4BYFO-qv6TI5pToe2FeZq_hGpsAHNQ6jc7w=",
  },
  {
    name: "General hospital and personal use devices",
    imgSrc:
      "https://media.istockphoto.com/photos/elderly-patient-with-bp-heart-rate-digital-pulse-check-equipment-for-picture-id1177942982?k=20&m=1177942982&s=612x612&w=0&h=CKvqyy3zk9HLUlxmCNj5XIhjdWJ5KM-tM4mGcTBi5-A=",
  },
  {
    name: "Neurological devices",
    imgSrc:
      "https://media.istockphoto.com/photos/neurosurgeon-pointing-at-lumbar-vertebra-model-picture-id983383674?k=20&m=983383674&s=612x612&w=0&h=By24W0aS7KY2gcfAflqrF9r2sVS3WqvdaGyyjqjm6Gw=",
  },
  {
    name: "Obstetrical and gynecological devices",
    imgSrc:
      "https://media.istockphoto.com/photos/in-control-room-doctor-and-radiologist-discuss-diagnosis-while-and-picture-id1074166486?k=20&m=1074166486&s=612x612&w=0&h=ZGq88ZKoZJVLwlOf5MUTSrKEDpF2VFs8WhyDevppbXA=",
  },
  {
    name: "Ophthalmic devices",
    imgSrc:
      "https://media.istockphoto.com/photos/eye-doctor-with-female-patient-during-an-examination-in-modern-clinic-picture-id1189362136?k=20&m=1189362136&s=612x612&w=0&h=qYC-fuPQn96vMwPpOBWrQKpGB084FW3Ge329QKpGL6c=",
  },
  {
    name: "Orthopedic devices",
    imgSrc:
      "https://media.istockphoto.com/photos/female-doctor-and-senior-man-picture-id1147501337?k=20&m=1147501337&s=612x612&w=0&h=uyTn0pQxluri8G0aWTf2qgbP8ju59W02FdsLvF1aC6Q=",
  },
  {
    name: "Physical therapy and rehabilitation",
    imgSrc:
      "https://media.istockphoto.com/photos/woman-with-orthopedic-problem-exercising-with-ball-while-supporting-picture-id1048265236?k=20&m=1048265236&s=612x612&w=0&h=-p8PhGvUSUPp3mOncv2SemqbShG2veQ0jGBhZH-cq2o=",
  },
];

export default ourCategories;
