// Styled

import { useEffect } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import containerVariants from "../../animation";
import { PageNotFoundWrap } from "./PagNotFoundEl";
import { useSelector } from "react-redux";

const PageNotFound = () => {
  const { mainColor } = useSelector(({ ui }) => ui);
  console.log(mainColor);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="zoom-in-down"
    >
      <PageNotFoundWrap>
        <div id="notfound">
          <div className="notfound-bg" mainColor={mainColor}>
            <div mainColor={mainColor}></div>
            <div mainColor={mainColor}></div>
            <div mainColor={mainColor}></div>
            <div mainColor={mainColor}></div>
          </div>
          <div className="notfound">
            <div className="notfound-404">
              <h1 mainColor={mainColor}>404</h1>
            </div>
            <h2>Page Not Found</h2>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <Link to="/">Homepage</Link>
          </div>
        </div>
      </PageNotFoundWrap>
    </motion.div>
  );
};

export default PageNotFound;
