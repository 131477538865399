import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./index.css";

// const HomeCardComponent = ({ image_url, title }) => {
const HomeCardComponent = ({ item, path }) => {
  const title = item?.title?.en
  console.log("ITEM505050", item)
  return (
    <Link to="/categories" className="container-card">
      <div className="mycard">
        <div
          className="card-header"
          style={{
            backgroundImage: `url(${path}/${item?.image})`,
            backgroundPosition: "right",
            backgroundSize: "cover",
            borderRadius: '4px'
          }}
        >
          <div className="desc-container">
            <span style={{ textAlign: 'center', color: '#fff', fontSize: '14px', fontWeight: '500' }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
            </span>
          </div>
        </div>
        <div className="card-footer">
          <p style={{ color: "#333", fontWeight: 'bold' }}>{title?.en?.length > 10 ? `${title.substring(0, 10)}...` : title}</p>
          <AiOutlineRight style={{ color: "#333" }} />
        </div>
      </div>
    </Link>
  );
};

export default HomeCardComponent;
