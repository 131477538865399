/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  BoxTitle,
  CategoriesAllPageSidebarWrap,
  ColorCircleWrap,
  ColorsOptions,
  OptionsWrapper,
  SidebarBoxWrap,
} from "./CategoriesAllPageSidebarEl";
import { Input, Checkbox, Rate, Tooltip, Slider, Radio } from "antd";
import { ColorCircleItem } from "../common-styled-componnts";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import api from "../../api";
import { useEffect } from "react";

const CategoriesAllPageSidebar = ({ setData }) => {
  const categories = useSelector(
    (state) => state.homepage?.data?.categories_subcategories_childcategories
  );

  const [searchKey, setSearchKey] = useState();
  // const history = useHistory();
  const { mainColor } = useSelector(({ ui }) => ui);
  const [rateVal, setRateVal] = useState(3);
  // eslint-disable-next-line no-unused-vars
  const [isPriceDisabled, setIsPriceDisbled] = useState(false);
  const [subCat, setSubCat] = useState("");
  const { Search } = Input;
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const { mainId, subId, childId } = useParams();

  const onSearch = (value) => console.log(value);

  const [childs, setChilds] = useState([]);

  const onChange = (e) => {
    // console.log(`checked = ${e.target.value}`);
    if (e.target.checked) {
      setChilds(childs.concat([e.target.value]));
    } else {
      setChilds(childs?.filter((child) => child !== e.target.value));
    }
  };

  console.log("CHILDSSS", childs);

  const ratingHandleChange = (value) => {
    setRateVal(value);
  };

  // useEffect(() => {
  //   AOS.init({ duration: 2000 });
  // }, []);

  const currCategory = categories?.find((item) => item.id === parseInt(mainId));

  const currSub = currCategory?.subcategories?.find(
    (item) => item.id === parseInt(subCat)
  );
  console.log(currSub, "subbbbb");

  const getData = (id) => {
    api.get(`/get-products-with-keys?main=${mainId}&sub=${id}`).then((res) => {
      console.log(res?.data, "API DATA");
      setData(res?.data);
    });
  };

  useEffect(() => {
    if (childs?.length >= 1) {
      api
        .get(
          `/get-products-with-keys?main=${mainId}&sub=${currSub?.id}&childs=${childs}&search=${searchKey}`
        )
        .then((res) => {
          console.log(res?.data, "API DATA");
          setData(res?.data);
        });
    }
  }, [childs, searchKey]);

  useEffect(() => {
    if (childs?.length >= 1) {
      api
        .get(
          `/get-products-with-keys?main=${mainId}&sub=${currSub?.id}&childs=${childs}&search=${searchKey}`
        )
        .then((res) => {
          console.log(res?.data, "API DATA");
          setData(res?.data);
        });
    }
  }, [childs, searchKey]);

  useEffect(() => {
    if (searchKey !== "") {
      api
        .get(
          `/get-products-with-keys?main=${mainId}${
            currSub ? `&sub=${currSub?.id}` : ""
          }}${
            childs?.length >= 1 ? `&childs=${childs}` : ""
          }&search=${searchKey}`
        )
        .then((res) => {
          console.log(res?.data, "API DATA");
          setData(res?.data);
        });
    }
  }, [searchKey]);
  console.log("CURRENT CATEGORY", currSub);

  return (
    <CategoriesAllPageSidebarWrap mainColor={mainColor}>
      <SidebarBoxWrap>
        <BoxTitle>Search</BoxTitle>
        <input
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search..."
          enterButton
        />
        <BoxTitle style={{ marginTop: "20px" }}>Subcategories</BoxTitle>
        <OptionsWrapper mainColor={mainColor}>
          <Radio.Group defaultValue={parseInt(subId)}>
            {currCategory?.subcategories?.map((item, index) => {
              return (
                <div key={index} style={{ marginBottom: 7 }}>
                  <Radio
                    value={item?.id}
                    name="fav_language"
                    // onChange={onChange}
                    onClick={() => {
                      setSubCat(item.id);
                      getData(item.id);
                      setChilds([]);
                    }}
                  >
                    {item?.title?.en}
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </OptionsWrapper>
      </SidebarBoxWrap>
      <SidebarBoxWrap>
        {currSub?.childcategory?.length < 1 ? null : (
          <>
            <BoxTitle>Child Categories</BoxTitle>

            <OptionsWrapper mainColor={mainColor}>
              {currSub?.childcategory?.map((item, index) => {
                return (
                  <div key={index} style={{ marginBottom: 7 }}>
                    <Checkbox value={item?.id} onChange={onChange}>
                      {item?.title?.en}
                    </Checkbox>
                  </div>
                );
              })}
            </OptionsWrapper>
          </>
        )}

        <OptionsWrapper mainColor={mainColor}>
          <BoxTitle>Rating</BoxTitle>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
          {/* <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span>
          <span>
            <Rate
              tooltips={desc}
              onChange={ratingHandleChange}
              value={rateVal}
            />
          </span> */}
        </OptionsWrapper>

        <ColorsOptions>
          <BoxTitle>Color</BoxTitle>
          <ColorCircleWrap>
            <Tooltip placement="top" title="#5fec11">
              <ColorCircleItem bg="#5fec11" />
            </Tooltip>
            <Tooltip placement="top" title="#ad2edb">
              <ColorCircleItem bg="#ad2edb" />
            </Tooltip>
            <Tooltip placement="top" title="#0b98ee">
              <ColorCircleItem bg="#0b98ee" />
            </Tooltip>
            <Tooltip placement="top" title="#22d1f2">
              <ColorCircleItem bg="#22d1f2" />
            </Tooltip>
            <Tooltip placement="top" title="#e3ec1d">
              <ColorCircleItem bg="#e3ec1d" />
            </Tooltip>
          </ColorCircleWrap>
        </ColorsOptions>

        {/* <ColorsOptions>
          <BoxTitle>Size</BoxTitle>
          <ColorCircleWrap>
            <Tooltip placement="top">
              <SizeCircleItem>xs</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem>s</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem isSelected>m</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem>l</SizeCircleItem>
            </Tooltip>
            <Tooltip placement="top">
              <SizeCircleItem isSelected>xl</SizeCircleItem>
            </Tooltip>
          </ColorCircleWrap>
        </ColorsOptions> */}
        <ColorsOptions>
          <BoxTitle>Price</BoxTitle>
          <Slider
            range
            defaultValue={[1, 1000]}
            disabled={isPriceDisabled}
            mim={1}
            max={1000}
            trackStyle={{ backgroundColor: "red" }}
          />
        </ColorsOptions>
      </SidebarBoxWrap>
    </CategoriesAllPageSidebarWrap>
  );
};

export default CategoriesAllPageSidebar;
