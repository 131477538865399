import styled from "styled-components";

export const CustomButtonWrap = styled.button`
  text-align: center;
  width: fit-content;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 2px solid ${({ mainColor }) => mainColor};
  background-color: ${({ isHover, mainColor }) =>
    isHover ? "transparent" : mainColor};
  color: ${({ isHover, mainColor }) => (isHover ? mainColor : "#fff")};
  outline: none !important;

  &:hover {
    border: 2px solid ${({ mainColor }) => mainColor};
    background-color: ${({ mainColor }) => mainColor} !important;
    color: #fff !important;
  }
`;
