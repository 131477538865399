import { Controller } from "react-hook-form";

import {
  CustomInputStyle,
  CustomInputWrap,
  InputButtonIcon,
  InputIcon,
} from "./CustomInputEl";

const CustomInput = ({
  type,
  placeholder,
  id,
  style,
  control,
  name,
  icon,
  buttonIcon,
  rows,
  register,
  onChange,
}) => {
  const conditionalProps = {
    ...(type === "textarea" ? { as: "textarea", rows: rows ?? 5 } : { type }),
    ...(icon && { paddingLeft: "30px" }),
  };

  if (register)
    return (
      <CustomInputWrap style={{ ...style }}>
        <CustomInputStyle
          onChange={onChange}
          id={id}
          placeholder={placeholder}
          {...register(name)}
          {...conditionalProps}
        />
        {buttonIcon && <InputButtonIcon>{buttonIcon}</InputButtonIcon>}
        {icon && <InputIcon style={{ zIndex: "-10" }}>{icon}</InputIcon>}
      </CustomInputWrap>
    );

  return (
    <Controller
      name={name}
      id={name}
      control={control}
      render={({ field }) => (
        <CustomInputWrap style={{ ...style }}>
          <CustomInputStyle
            id={id}
            placeholder={placeholder}
            {...field}
            {...conditionalProps}
          />
          {buttonIcon && <InputButtonIcon>{buttonIcon}</InputButtonIcon>}
          {icon && <InputIcon>{icon}</InputIcon>}
        </CustomInputWrap>
      )}
    />
  );
};

export default CustomInput;
