import styled from "styled-components";
import { mainColor, screenLg } from "../../../../constants";
import {
  ColorCircleItem,
  SizeCircleItem,
} from "../../../common-styled-componnts";

export const DetailsSectionWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "space-between"};
  align-items: center;
  :not(:first-of-type) {
    margin-top: 12px;
  }
  margin: ${(props) => props.margin};

  @media screen and (max-width: ${screenLg}) {
    flex-direction: column;
    align-items: flex-start;

    > button {
      width: 100%;
      :not(:first-of-type) {
        margin: 12px 0;
      }
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "flex-start"};
  align-items: ${(props) => props.alignItems ?? "baseline"};
`;

export const FilterName = styled.h5`
  font-size: 1rem;
  font-weight: 500;
  width: 10%;
`;

export const SizeFilter = styled(SizeCircleItem)`
  margin: 0 5px;
  &.active {
    &.active {
      background: ${mainColor};
      color: white;
    }
  }
`;

export const ColorFilter = styled(ColorCircleItem)`
  margin: 0 5px;
  width: 35px;
  height: 35px;
  border: 1px solid #bbb;
  &.active {
    border: 3px solid ${mainColor};
  }
`;
