import userTypes from "./user.types";

const initialState = {
  loading: false,
  data: null,
  token: null,
  error: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.Login_USER_REQUEST:
      return {
        loading: true,
        data: null,
        token: null,
        error: null,
      };
    case userTypes.Login_USER_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        token: action.payload.data,
        error: null,
      };

    case userTypes.Login_USER_ERROR:
      return {
        loading: false,
        data: null,
        token: null,
        error: action.payload,
      };
    case userTypes.USER_LOGOUT:
      return {
        loading: false,
        data: null,
        token: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
