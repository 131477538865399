import styled from "styled-components";
import { mainColor, screenLg } from "../../constants";

export const CategoriesNavigatorWrap = styled.div`
  border-top: 1px solid #1d9079;
  border-bottom: 1px solid #1d9079;

  @media screen and (max-width: ${screenLg}) {
    /* display: none; */
    width: 100%;
  }
`;

export const CategoriesNavigatorContent = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;

  /* width */
  ::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ mainColor }) => mainColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ mainColor }) => mainColor};
  }
`;

export const CategoriesNavigatorItem = styled.div`
  /* flex: 1; */
  text-align: center;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 0.7rem;
  color: ${({ isActive }) => (isActive ? "#fff" : "#000")};
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  background-color: ${({ isActive, mainColor }) => isActive && mainColor};

  color &:hover {
    color: ${({ mainColor }) => mainColor};
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${mainColor};
  }
`;
