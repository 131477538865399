// TODO complete update in buyer form

import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import vendorReducer from "./vendor/vendor.reducer";
import { productsReducer } from "./products/products.reducer";
import UIReducer from "./ui/ui.reducer";
import { homepageReducer } from "./homepage/homepage.reducer";
import { productReducer } from "./product/product.reducer";
import { reviewsReducer } from "./reviews/reviews.reducer";
import { richTextReducer } from "./rich-text/richText.reducer";
import { userReducer } from "./user/user.reducer";
import { wishlistReducer } from "./wishlist/wishlist.reducer";
import customerReducer from "./customer/customer.reducer";
import { categoriesReducer } from "./categories/categories.reducer";
import passwordReducer from "./password/password.reducer";
import buyerReducer from "./buyer/buyer.reducer";
import cartReducer from "./cart/cart.reducer";
import guestReducer from "./guest/guest.reducer";
import compareReducer from "./compare/compare.reducer";
import { checkoutReducer } from "./checkout/checkout.reducer";
import { loclCartReducer } from "./localCart/localCart.reducer";
import { localWishlistReducer } from "./localWishlist/localWishlist.reducer";
import { localCompareReducer } from "./localCompare/localCompare.reducer";
import { addressReducer } from "./address/address.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["homepage", "ui", "wishlist", "compare", "checkout"],
  blacklist: ["user", "vendor", "buyer", "customer"],
};

const userConfig = {
  key: "user",
  storage,
  whitelist: ["data", "token"],
  blacklist: ["error"],
};

const customerConfig = {
  key: "customer",
  storage,
  whitelist: ["data", "token"],
  blacklist: ["error"],
};

const vendorConfig = {
  key: "vendor",
  storage,
  whitelist: ["data", "token", "info"],
  blacklist: ["error"],
};

const buyerConfig = {
  key: "buyer",
  storage,
  whitelist: ["data", "token", "info"],
  blacklist: ["error"],
};
const localCartConfig = {
  key: "local-cart",
  storage,
  whitelist: ["cartItems"],
  blacklist: ["error"],
};
const localWIshlistConfig = {
  key: "local-wishlist",
  storage,
  whitelist: ["wishlist"],
  blacklist: ["error"],
};
const localCompareConfig = {
  key: "local-compare",
  storage,
  whitelist: ["compareList"],
  blacklist: ["error"],
};
const guestConfig = {
  key: "guest",
  storage,
  whitelist: ["cartItems"],
  blacklist: ["error"],
};

const rootReducer = combineReducers({
  vendor: persistReducer(vendorConfig, vendorReducer),
  buyer: persistReducer(buyerConfig, buyerReducer),
  products: productsReducer,
  ui: UIReducer,
  homepage: homepageReducer,
  product: productReducer,
  cart: cartReducer,
  reviews: reviewsReducer,
  richText: richTextReducer,
  user: persistReducer(userConfig, userReducer),
  wishlist: wishlistReducer,
  customer: persistReducer(customerConfig, customerReducer),
  categories: categoriesReducer,
  password: passwordReducer,
  guest: persistReducer(guestConfig, guestReducer),
  compare: compareReducer,
  checkout: checkoutReducer,
  localCart: persistReducer(localCartConfig, loclCartReducer),
  localWishlist: persistReducer(localWIshlistConfig, localWishlistReducer),
  localCompare: persistReducer(localCompareConfig, localCompareReducer),
  address: addressReducer,
});

export default persistReducer(persistConfig, rootReducer);
