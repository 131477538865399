import UITypes from './ui.types';

const initialState = {
  isGrid: true,
  categoriesFilerSidebar: false,
  mainColor: '#0077F9',
  showPopup: true,
};

const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    case UITypes.CHANGE_CATEGORIES_VIEW_TO_GRID:
      return {
        ...state,
        isGrid: true,
      };

    case UITypes.CHANGE_CATEGORIES_VIEW_TO_LIST:
      return {
        ...state,
        isGrid: false,
      };
    case UITypes.SHOW_CATEGORIES_FILTER_SIDEBAR:
      return {
        ...state,
        categoriesFilerSidebar: !state.categoriesFilerSidebar,
      };
    case UITypes.CHANGE_APP_MAIN_COLOR:
      return {
        ...state,
        mainColor: action.payload,
      };
    case UITypes.SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    default:
      return state;
  }
};

export default UIReducer;
