import styled from "styled-components";

export const RegistrationFormWrap = styled.div`
  /* border: 1px solid red; */
  max-width: 600px;
  margin: 50px auto;
  width: 95%;

  .my-facebook-button-class {
    border: none;
    text-align: center;
    background-color: ${({ mainColor }) => mainColor};
    color: #fff;
    padding: 10px 20px;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;
  }
`;

export const SignupText = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${({ mainColor }) => mainColor};
  margin-bottom: 30px;
  font-weight: 700;

  h1 {
    font-weight: 700;
    font-size: 1.5rem;
  }
`;

export const TabNavigatorList = styled.div`
  list-style: none;
  display: flex;
  max-width: 300px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const TabNavigatorListItem = styled.div`
  border-bottom: ${({ isActive, mainColor }) =>
    isActive ? `2px solid ${mainColor}` : "1px solid #ddd"};
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  padding-bottom: 5px;
  color: ${({ isActive, mainColor }) =>
    isActive ? `${mainColor}` : "#676161"};
`;
