import styled from "styled-components";

export const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
    padding: 20px 0 0 0;
  :not(:last-of-type) {
    border-bottom: 1px solid #eef;
    padding: 20px 0px;
  }
`;

export const UserAreaWrapper = styled.div`
  width: 100%;
`;

export const UserAreaContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const UserAreaAntWrapper = styled.div`
  width: 14%;
  .ant-avatar.ant-avatar-circle {
    color: #f56a00;
    background-color: #fde3cf;
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  .ant-rate {
    font-size: 12px;
  }
`;
export const UserAreaContentName = styled.h4`
  font-weight: normal;
  font-size: 1.3rem;
  width: 100%;
`;

export const ReviewTitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;
export const ReviewTitleName = styled.span`
  font-weight: 500;
  width: 100%;
`;

export const ReviewLocation = styled.p`
  line-height: 1.8;
  font-size: 0.9rem;
  margin-top: 4px;
`;
export const ReviewDescription = styled.p`
  line-height: 1.8;
  font-size: 1rem;
`;
