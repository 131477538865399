import UITypes from './ui.types';

export const changeCategoriesPageViewToGrid = () => ({
  type: UITypes.CHANGE_CATEGORIES_VIEW_TO_GRID,
});

export const changeCategoriesPageViewToList = () => ({
  type: UITypes.CHANGE_CATEGORIES_VIEW_TO_LIST,
});

export const toggleCategoriesFilterSidebar = () => ({
  type: UITypes.SHOW_CATEGORIES_FILTER_SIDEBAR,
});
export const changeAppMainColor = (color) => ({
  type: UITypes.CHANGE_APP_MAIN_COLOR,
  payload: color,
});
export const changeShowPopup = (bolValue) => ({
  type: UITypes.SHOW_POPUP,
  payload: bolValue,
});
