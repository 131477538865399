import { useEffect } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import ReviewSupplierForm from "../../components/review-supplier-form";
import containerVariants from "../../animation";

const ReviewSupplier = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="flip-left"
    >
      <ReviewSupplierForm />
    </motion.div>
  );
};

export default ReviewSupplier;
