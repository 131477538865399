import React from 'react';
import api from '../api';

export const useData = endPoint => {
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        (async () => {
            const response = await api.get(`/${endPoint}`);
            setData(response.data)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return data;
}