import React from "react";
import { useForm } from "react-hook-form";
import { FormRow, InputWrapper } from "../../form-elements/constants";
import FormHandler from "../../form-elements/form-handler";
import Button from "../../button";
import formFields from "./utils";
import { FormInfo, FormTitle, FormWrapper } from "./ContactUsFormEl";

const ContactUsForm = () => {
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log("data data data", data);
  };

  return (
    <FormWrapper>
      <FormTitle>Leave Us a Message</FormTitle>
      <FormInfo>Your email address will not be published.</FormInfo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHandler control={control} formFields={formFields} />
        <FormRow margin="24px 0">
          <InputWrapper>
            <Button type="submit" text="Send Message" borderRadius="3rem" />
          </InputWrapper>
        </FormRow>
      </form>
    </FormWrapper>
  );
};

export default ContactUsForm;
