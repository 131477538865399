import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomInput from "../form-elements/custom-input";
import CustomButton from "../custom-button";
import ReCAPTCHA from "react-google-recaptcha";
import customerFields from "./utils/customerFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomerSignupSchema } from "../../validationSchema";
import { ErrorMessage } from "@hookform/error-message";
import FormErrors from "../form-errors";
import GoogleLoginComp from "../google-login";
import FacebookLoginComp from "../facebook-login";
import { useSelector, useDispatch } from "react-redux";
import { customerRegisterAction } from "../../redux/customer/customer.actions";
import { Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CustomerSignupForm = () => {
  const [isARobot, setIsARobot] = useState(true);
  const [isARobotMsg, setIsARobotMsg] = useState(false);
  const { loading, data, error } = useSelector(({ customer }) => customer);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CustomerSignupSchema),
  });
  const { mainColor } = useSelector(({ ui }) => ui);
  const dispatch = useDispatch();

  const keys = Object.values(error);

  // Some Styles
  const btnStyle = {
    border: "none",
    textAlign: "center",
    backgroundColor: mainColor,
    color: "#fff",
    padding: "10px 20px",
    margin: "30px 0",
    display: "block",
    borderRadius: "5px",
    textTransform: "uppercase",
    fontWeight: 600,
    width: "100%",
  };
  const onChange = (value) => {
    if (value) {
      setIsARobot(false);
      setIsARobotMsg(false);
    }
  };

  const onSubmit = (data) => {
    if (isARobot) {
      setIsARobotMsg(true);
      return;
    }

    dispatch(customerRegisterAction(data, data));
    setIsARobot(false);
  };
  useEffect(() => {
    // console.log(errors);
  }, [errors]);

  return (
    <>
      {keys &&
        keys.map((el) => (
          <Alert
            message={<b>{el}</b>}
            type="error"
            style={{ marginBottom: 10 }}
          />
        ))}

      {data?.error && (
        <Alert
          message={data?.error}
          type="error"
          style={{
            margin: "10px auto",
            width: "fit-content",
            fontWeight: "bold",
          }}
        />
      )}
      {data?.message && (
        <Alert
          message={data?.message}
          type="success"
          style={{
            margin: "10px auto",
            width: "fit-content",
            fontWeight: "bold",
          }}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {customerFields.map(
          ({ name, type, placeholder, labelText, id, htmlFor }, idx) => (
            <div style={{ marginBottom: 20 }} key={idx}>
              <CustomInput
                type={type}
                placeholder={placeholder}
                htmlFor={htmlFor}
                id={id}
                labelText={labelText}
                {...register(name)}
                control={control}
                value
              />
              <ErrorMessage errors={errors} name={name} render={FormErrors} />
            </div>
          )
        )}

        <div className="form-group">
          <div
            className="form-check"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              {...register("terms")}
            />
            <label
              className="form-check-label"
              htmlFor="terms"
              style={{ marginLeft: 10 }}
            >
              I agree to terms and conditions and{" "}
              <Link
                to="/privacy"
                className="d-inline"
                style={{ color: mainColor, fontWeight: "bold" }}
              >
                Privacy Policy of XPOYA
              </Link>
            </label>
          </div>
          <ErrorMessage errors={errors} name="terms" render={FormErrors} />
        </div>

        <div style={{ marginTop: 25 }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTHCA_PUBLIC}
            onChange={onChange}
          />
          <ErrorMessage errors={errors} name="not_robot" render={FormErrors} />
          {isARobotMsg && (
            <p
              style={{
                width: "100%",
                marginTop: ".25rem",
                fontSize: "80%",
                color: "red",
                fontWeight: 700,
              }}
            >
              I am not a robot is a required field
            </p>
          )}
        </div>

        <CustomButton
          type="submit"
          text={
            loading ? (
              <Spin indicator={antIcon} style={{ color: "#fff" }} />
            ) : (
              "Submit"
            )
          }
          style={{
            ...btnStyle,
            backgroundColor: mainColor,
            cursor: loading ? "not-allowed" : "pointer",
          }}
          disabled={loading ? true : false}
        />
        <div className="social-or-el">Or</div>
      </form>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GoogleLoginComp />
        <FacebookLoginComp />
      </div>
    </>
  );
};

export default CustomerSignupForm;
