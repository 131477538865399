import { Checkbox } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const CustomCheckbox = ({ name, control, options }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <Checkbox.Group options={options} {...field} />;
      }}
    />
  );
};

export default CustomCheckbox;
