import { Select, Collapse } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import { mainColor } from "../../../constants";

import {
  CollapseRowLeft,
  CollapseRowWrap,
  VendorOrdersTabContentCollapseHeader,
  VendorOrdersTabContentHeader,
  VendorOrdersTabContentWrap,
} from "../VendorBuyerDashboardEl";

const CollapseHeader = () => {
  return (
    <VendorOrdersTabContentCollapseHeader>
      <div>
        <span>CATEGORY TYPE</span>
        <span>
          <b>Dental Device</b>
        </span>
      </div>
      <div>
        <span>TOTAL</span>
        <span>
          <b>$322.92</b>
        </span>
      </div>
      <div>
        <span>SHIP FROM</span>
        <span>
          <b>8540 NW 66 ST Miami, FL 33195</b>
        </span>
      </div>
    </VendorOrdersTabContentCollapseHeader>
  );
};

const CollapseRow = () => {
  return (
    <CollapseRowWrap>
      <CollapseRowLeft>
        <img
          src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt=""
        />
        <Link to="/">Product Name</Link>
      </CollapseRowLeft>
      <div>$993.00</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ color: mainColor }}>
          1200 <b>IN STOCK</b>
        </div>
        <BiDotsVerticalRounded />
      </div>
    </CollapseRowWrap>
  );
};

const VendorProductsTabContent = () => {
  const { Option } = Select;
  const { Panel } = Collapse;

  const handleChange = value => {
    console.log(`selected ${value}`);
  };

  return (
    <VendorOrdersTabContentWrap>
      <VendorOrdersTabContentHeader>
        <h4>Products</h4>
        <Select
          defaultValue="past-3-month"
          style={{ width: 150 }}
          onChange={handleChange}
        >
          <Option value="past-3-month">Past 3 Month</Option>
          <Option value="past-6-month">Past 6 Month</Option>
          <Option value="past-12-month">Past 12 Month</Option>
        </Select>
      </VendorOrdersTabContentHeader>
      <div>
        <Collapse style={{ width: "100%" }}>
          <Panel header={<CollapseHeader />} key="1">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="2">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="3">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="4">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="5">
            <CollapseRow />
          </Panel>
          <Panel header={<CollapseHeader />} key="6">
            <CollapseRow />
          </Panel>
        </Collapse>
      </div>
    </VendorOrdersTabContentWrap>
  );
};

export default VendorProductsTabContent;
