import styled from "styled-components";
import { screenMd } from "../../constants";

export const CategoriesAllHeroWrap = styled.div`
  background-image: url(${props => props.backgroundImg});
  height: 400px;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @media screen and (max-width: ${screenMd}) {
    height: 300px;
    background-size: revert !important;
  }
`;

export const CategoriesAllHeroText = styled.h1`
  width: fit-content;
  margin: 0 auto;
  background-color: rgb(0 0 0 / 0.7);
  color: #fff;
  padding: 10px;
  text-transform: capitalize;
  font-size: 3rem;
  border-radius: 5px;

  @media screen and (max-width: ${screenMd}) {
    font-size: 1rem !important;
  }
`;
