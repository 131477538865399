import { Link } from "react-router-dom";
import styled from "styled-components";
import { borderTop, mainColor, screenLg, secColor } from "../../constants";

export const HomepageTopRatedSectionWrap = styled.section`
  /* margin-top: 50px;
  padding-top: 50px; */
  border-top: 1px solid ${borderTop};
`;

export const HomepageTopRatedSectionHeader = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 2rem;
  }

  @media screen and (max-width: ${screenLg}) {
    margin-bottom: 20px;

    h4 {
      font-size: 1rem !important;
    }
  }
`;

export const HomepageTopRatedSectionGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 15px;
`;

export const HomepageTopRatedSectionSeeAll = styled(Link)`
  background-color: transparent;
  color: ${mainColor};
  border: 2px solid ${mainColor};
  padding: 5px 20px;
  display: block;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  width: fit-content;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${mainColor};
    color: ${secColor};
  }
`;
