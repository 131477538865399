import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Container } from "../../constants";
import {
  CategoriesNavigatorContent,
  CategoriesNavigatorItem,
  CategoriesNavigatorWrap,
} from "./CategoriesNavigatorEl";

const CategoriesNavigator = () => {
  const categories = useSelector(
    (state) => state.homepage?.data?.categories_subcategories_childcategories
  );
  const { mainId } = useParams();
  const { mainColor } = useSelector(({ ui }) => ui);
  const history = useHistory();
  return (
    <CategoriesNavigatorWrap>
      <Container>
        <CategoriesNavigatorContent mainColor={mainColor}>
          {categories?.map((item, index) => {
            return (
              <CategoriesNavigatorItem
                key={index}
                mainColor={
                  parseInt(item?.id) === parseInt(mainId) ? mainColor : ""
                }
                isActive={
                  parseInt(item?.id) === parseInt(mainId) ? true : false
                }
                onClick={() => history.push(`/categories/main/${item?.id}`)}
              >
                {item?.title?.en}
              </CategoriesNavigatorItem>
            );
          })}
        </CategoriesNavigatorContent>
      </Container>
    </CategoriesNavigatorWrap>
  );
};

export default CategoriesNavigator;
