import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomInput from "../form-elements/custom-input";
import CustomButton from "../custom-button";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BuyerSignupSchema } from "../../validationSchema";
import { ErrorMessage } from "@hookform/error-message";
import FormErrors from "../form-errors";
// import MediaUploader from "../media-uploader";
import GoogleLoginComp from "../google-login";
import FacebookLoginComp from "../facebook-login";
import { useSelector, useDispatch } from "react-redux";
import buyerFields, { stepTwoFields } from "./utils/buyerFields";
import { buyerRegisterAction } from "../../redux/buyer/buyer.action";
import React from "react";

// Some Styles
const btnStyle = {
  border: "none",
  textAlign: "center",
  color: "#fff",
  padding: "10px 20px",
  margin: "30px 0",
  display: "block",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontWeight: 600,
  width: "100%",
};

const BuyerSignupForm = ({ email }) => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const { loading, error } = useSelector(({ buyer }) => buyer);
  const [uploadedFiles, setUploadedFiles] = useState("");
  const [isARobot, setIsARobot] = useState(true);
  const [isARobotMsg, setIsARobotMsg] = useState(false);
  console.log("EMAILLL FORM TWO", email);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();
  const [err, setErr] = React.useState();
  console.log("THE ERROR", err);
  const callBack = () => {
    message.warning("Logged in successfully");
  };

  const onSubmit = (data) => {
    // if (isARobot) {
    //   setIsARobotMsg(true);
    //   return;
    // }

    const info = new FormData();
    info.append("email", email);
    info.append("code", data.code);

    dispatch(buyerRegisterAction(info, data, setErr, callBack));
  };

  const onChange = (value) => {
    if (value) {
      setIsARobot(false);
      setIsARobotMsg(false);
    }
  };

  let keys;
  if (error) {
    keys = Object.values(error);
  }

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <>
      <h3>Please enter the code you got on your mail</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {stepTwoFields.map(
          ({ name, type, placeholder, labelText, id, htmlFor }, idx) => (
            <div style={{ marginBottom: 20 }} key={idx}>
              <CustomInput
                type={type}
                placeholder={placeholder}
                htmlFor={htmlFor}
                id={id}
                labelText={labelText}
                {...register(name)}
                control={control}
              />
              <ErrorMessage errors={errors} name={name} render={FormErrors} />
              <div className="error-message">{err?.msg}</div>
            </div>
          )
        )}

        <div className="form-group">
          <div
            className="form-check"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            {/* <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              {...register("terms")}
            /> */}
            {/* <label
              className="form-check-label"
              htmlFor="terms"
              style={{ marginLeft: 10 }}
            >
              I agree to terms and conditions and{" "}
              <Link
                to="/privacy"
                className="d-inline"
                style={{ color: mainColor, fontWeight: "bold" }}
              >
                Privacy Policy of XPOYA
              </Link>
            </label> */}
          </div>
          <ErrorMessage errors={errors} name="terms" render={FormErrors} />
        </div>

        <ErrorMessage
          errors={errors}
          name={"company_papers"}
          render={FormErrors}
        />

        <div style={{ marginTop: 25 }}>
          {/* <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTHCA_PUBLIC}
            onChange={onChange}
          /> */}
          <ErrorMessage errors={errors} name="not_robot" render={FormErrors} />
          {isARobotMsg && (
            <p
              style={{
                width: "100%",
                marginTop: ".25rem",
                fontSize: "80%",
                color: "red",
                fontWeight: 700,
              }}
            >
              I am not a robot is a required field
            </p>
          )}
        </div>

        <CustomButton
          type="submit"
          text={
            loading ? (
              <Spin indicator={antIcon} style={{ color: "#fff" }} />
            ) : (
              "Submit"
            )
          }
          style={{
            ...btnStyle,
            backgroundColor: mainColor,
            cursor: loading ? "not-allowed" : "pointer",
          }}
          disabled={loading ? true : false}
        />
      </form>
      <div className="social-or-el">Or</div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <GoogleLoginComp />
        <FacebookLoginComp />
      </div>
    </>
  );
};

export default BuyerSignupForm;
