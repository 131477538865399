import styled from 'styled-components';

export const CartCustomButtonWrap = styled.button`
  text-align: center;
  width: fit-content;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  /* background-color: ${({ isHover, mainColor }) => (isHover ? 'transparent' : mainColor)}; */
  background-color: #fff;
  /* color: ${({ isHover, mainColor }) => (isHover ? mainColor : '#fff')}; */
  color: ${({ mainColor }) => mainColor};
  position: relative;
  overflow: hidden;
  padding: 10px 20px !important;
  border: 1px solid ${({ mainColor }) => mainColor};
  margin: 2px;

  &:hover {
    color: #fff !important;
  }

  &::before {
    content: '';
    position: absolute;
    /* top: var(--y); */
    /* left: var(--x); */
    top: 0;
    left: 50%;
    width: 0px;
    height: 0px;
    background-color: ${({ mainColor }) => mainColor} !important;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
    transform: translate(-50%, -50%);
  }

  &:hover::before {
    width: 300px;
    height: 300px;
  }

  & span {
    position: relative;
    z-index: 1;
  }
`;
