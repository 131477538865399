import styled from "styled-components";
import { screenLg } from "../../constants";

export const ProductDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductDetailContent = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 30px;
  justify-content: space-between;

  @media screen and (max-width: ${screenLg}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProductsCarouselWrapper = styled.div`
  padding: 12px 0px 48px;
`;
