import { useSelector } from "react-redux";
import { CustomButtonWrap } from "./CustomButtonEl";

const CustomButton = ({
  type,
  text,
  style,
  onClick,
  disabled,
  isHover,
  children,
}) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <CustomButtonWrap
      type={type}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled}
      isHover={isHover}
      mainColor={mainColor}
    >
      {children}
      <span>{text}</span>
    </CustomButtonWrap>
  );
};

export default CustomButton;
