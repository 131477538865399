import { useState } from "react";
import { Modal, InputNumber, Rate, message } from "antd";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  ProductModalContent,
  ProductModalInfo,
  ProductModalInfoCartBlock,
  ShopProductFooter,
  ShopProductHotSpan,
  ShopProductImgWrap,
  ShopProductItemWrap,
  ShopProductName,
  ShopProductNewSpan,
  ShopProductOfferSpan,
  ShopProductRating,
  ShopProductSaleSpan,
  SVGWrapper,
} from "./SliderProductItemEl";
import { AiFillEye, AiOutlineHeart } from "react-icons/ai";
import CustomButton from "../custom-button";
import { mainColor } from "../../constants";
import CartCustomButton from "./../card-button/cardButton";
import { useDispatch } from "react-redux";
import { addItemToCartLocal } from "../../redux/guest/guest.actions";
import ProductCard from "../ProductCard";

// Styles
const btnStyle = {
  zIndex: 500,
  padding: "6px 25px",
  display: "block",
  textTransform: "capitalize",
  fontWeight: 600,
  marginRight: 15,
};

const outOfStock = {
  backgroundColor: "#ababab",
  color: "#fff",
  border: "none",
  cursor: "not-allowed",
};

const SliderProductItem = ({
  id,
  name,
  base_image,
  image_url,
  images,
  price,
  quantity,
  isSale,
  isNew,
  isOffer,
  isHot,
  style,
  in_stock,
  productname,
  offer_price,
  productid,
  is_fav,
  path,
  image1,
}) => {
  const [showProductModal, setShopProductModal] = useState(false);
  // const { token } = useSelector(({ user }) => user);
  // const { data: buyerData } = useSelector(({ buyer }) => buyer);
  // const { data: vendorData } = useSelector(({ vendor }) => vendor);

  const dispatch = useDispatch();

  // CUSTOMER
  const onCustomerAddToCart = () => {
    message.success("Product added to your cart successfully");
  };

  // eslint-disable-next-line
  const onCustomerErr = () => {
    message.error("Please login to add product to your Cart!");
  };

  // VENDOR && BUYER
  // eslint-disable-next-line
  const onApproveFromAdmin = () => {
    message.error("Please wait while approve your account from admin!");
  };

  return (
    <>
      <ProductCard
        image={image1}
        path={path}
        offerPrice={parseInt(offer_price)}
        mainPrice={price}
        name={name?.en}
        price={price}
        id={id}
        fav={is_fav}
      />
      <Modal
        style={{ zIndex: 9999 }}
        // title="Vertically centered modal dialog"
        centered
        visible={showProductModal}
        onOk={() => setShopProductModal(false)}
        onCancel={() => setShopProductModal(false)}
        width="60%"
        footer={null}
      >
        <ProductModalContent style={{ padding: 20 }}>
          <ImageGallery
            items={images}
            thumbnailPosition="bottom"
            showPlayButton={false}
            showFullscreenButton={false}
            useBrowserFullscreen={false}
            showThumbnails={false}
          />
          <ProductModalInfo>
            <h2>{name?.length > 25 ? `${name.substring(0, 25)}...` : name}</h2>
            <h2>$88.00</h2>
            <ShopProductRating>
              <Rate disabled defaultValue={5} />
              <span
                style={{
                  fontSize: ".8rem",
                  color: mainColor,
                  marginLeft: "10px",
                  fontWeight: 600,
                }}
              >
                188
              </span>
            </ShopProductRating>
            <ul style={{ margin: "10px 20px" }}>
              <li>Available: In Stock</li>
              <li>Product code: : CA78963</li>
              <li>Type: WOMEN’S CLOTHING</li>
              <li>Vendor: DKLS9X2</li>
            </ul>
            <ProductModalInfoCartBlock>
              <InputNumber
                size="large"
                min={1}
                max={100000}
                defaultValue={3}
                onChange={(value) => console.log(value)}
              />
              <CustomButton
                type="button"
                text="add to cart"
                style={{ ...btnStyle, padding: "6px 10px" }}
                onClick={() => console.log("add to cart")}
                disabled={false}
                isHover
              />
            </ProductModalInfoCartBlock>
          </ProductModalInfo>
        </ProductModalContent>
      </Modal>
    </>
  );
};

export default SliderProductItem;
