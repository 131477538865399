const formFields = [
  {
    name: "from",
    labelText: "From",
    placeholder: "Email Address",
    type: "email",
  },
  {
    name: "to",
    labelText: "To",
    placeholder: "Email Address",
    type: "email",
  },
  {
    name: "subject",
    labelText: "Supplier Response",
    type: "text",
  },
  {
    name: "purchase_quantity",
    labelText: "Purchase Quantity",
    type: "multi",
    fields: [
      {
        name: "quantity",
        placeholder: "Quantity",
        type: "number",
        style: {
          inputWrapper: {
            width: "30%",
          },
        },
      },
      {
        name: "pieces",
        placeholder: "Pieces",
        type: "select",
        size: "large",
        style: {
          inputWrapper: {
            width: "65%",
          },
        },
      },
    ],
  },

  {
    name: "extra_request",
    labelText: "Extra Request",
    type: "checkbox",
    options: [
      { label: "Vendor", value: 1 },
      { label: "Item", value: 2 },
    ],
  },
  {
    name: "content",
    labelText: "Content",
    type: "textarea",
  },
];

export default formFields;
