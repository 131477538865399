import React, { useCallback } from "react";
import { StarFilled } from "@ant-design/icons";
import { Progress, Rate } from "antd";
import { useSelector } from "react-redux";
import Review from "./review";
import {
  CustomerReviewsWrapper,
  ReviewBarItem,
  ReviewBarItemValue,
  ReviewsAverageDescription,
  ReviewsAverageValue,
  ReviewsAverageWrapper,
  ReviewsBarsWrapper,
  ReviewSpecItem,
  ReviewSpecItemName,
  ReviewSpecWrapper,
  ReviewsWrapper,
  SectionContent,
  RatingsWrapper,
} from "./ReviewsEl";
import ReviewsForm from "./form";

const ProductReviews = () => {
  const {
    data: { product: productData },
    loading: productLoading,
  } = useSelector(({ product }) => product);
  const {
    data: reviewsData,
    loading: reviewsLoading,
    error,
  } = useSelector(({ reviews }) => reviews);

  const renderReviews = () => {
    return reviewsData.map((review) => {
      return <Review key={review.id} review={review} />;
    });
  };

  const renderPercentage = useCallback(() => {
    if (!productData) return null;
    const percentage =
      typeof productData.reviews.percentage === "string"
        ? JSON.parse(productData.reviews.percentage)
        : { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    const sortedPercentage = Object.entries(percentage).sort(
      (a, b) => b[0] - a[0]
    );
    return sortedPercentage.map((entry) => {
      return (
        <ReviewBarItem>
          <StarFilled />
          <ReviewBarItemValue>{entry[0]}</ReviewBarItemValue>
          <Progress percent={entry[1]} showInfo={false} />
        </ReviewBarItem>
      );
    });
  }, [productData]);

  if (reviewsLoading || productLoading) return <p>loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <ReviewsWrapper>
      <ReviewsForm />
      <RatingsWrapper>
        <ReviewsAverageWrapper>
          <SectionContent>
            <ReviewsAverageValue>
              {productData.reviews.average_rating}
            </ReviewsAverageValue>
            <Rate
              value={productData.reviews.average_rating}
              disabled
              style={{ fontSize: "12px" }}
            />
            <ReviewsAverageDescription>
              From {productData.reviews.total} customers
            </ReviewsAverageDescription>
          </SectionContent>
        </ReviewsAverageWrapper>

        <ReviewsBarsWrapper>
          <SectionContent width="100%">{renderPercentage()}</SectionContent>
        </ReviewsBarsWrapper>

        <ReviewsBarsWrapper>
          <ReviewSpecWrapper>
            <ReviewSpecItem>
              <Progress type="circle" percent={50} />
              <ReviewSpecItemName>Price</ReviewSpecItemName>
            </ReviewSpecItem>
            <ReviewSpecItem>
              <Progress type="circle" percent={50} />
              <ReviewSpecItemName>Quality</ReviewSpecItemName>
            </ReviewSpecItem>
            <ReviewSpecItem>
              <Progress type="circle" percent={50} />
              <ReviewSpecItemName>Comfort</ReviewSpecItemName>
            </ReviewSpecItem>
          </ReviewSpecWrapper>
        </ReviewsBarsWrapper>
      </RatingsWrapper>
      <CustomerReviewsWrapper>{renderReviews()}</CustomerReviewsWrapper>
    </ReviewsWrapper>
  );
};

export default ProductReviews;
