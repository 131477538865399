import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "../../constants";
import CustomLink from "../custom-link";
import {
  HomepageGreatDealContent,
  HomepageGreatDealWrap,
} from "./HomepageGreatDealEl";

// Some Styles
const btnStyle = {
  color: "#fff",
  padding: "10px 30px",
  display: "block",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: ".9rem",
};

const HomepageGreatDeal = () => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <HomepageGreatDealWrap>
      <Container>
        <HomepageGreatDealContent mainColor={mainColor}>
          <div>
            <h1>Great Deals</h1>
            <CustomLink to="/" text="View all" style={btnStyle} />
          </div>
          <div>
            <Link to="/">
              <img
                src="https://api.xpoya.com/cache/original/product/175/GVrkRERVMH2rfGsX4KSkgCtqkT4CExyj26HFGtIJ.jpg"
                alt=""
              />
            </Link>
            <Link to="/">
              <img
                src="https://media.istockphoto.com/photos/medical-vital-signs-monitor-in-a-hospital-picture-id901063844?k=20&m=901063844&s=612x612&w=0&h=ZHWhvQQbuh4AzmS5o--tXsGGqGLnHbei23V5J6o36e0="
                alt=""
              />
            </Link>
            <Link to="/">
              <img
                src="https://api.xpoya.com/cache/original/product/169/o9YswSQX9Rmx5sawQZ1CwS1C2GTwxAW158YyZBQd.jpg"
                alt=""
              />
            </Link>
            <Link to="/">
              <img
                src="https://api.xpoya.com/cache/original/product/234/TclzXQKISO2WvRwc3CYK1MCShDU2wVJfZSvu4K2a.jpg"
                alt=""
              />
            </Link>
          </div>
        </HomepageGreatDealContent>
      </Container>
    </HomepageGreatDealWrap>
  );
};

export default HomepageGreatDeal;
