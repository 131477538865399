const commonStyle = {
  label: {
    width: "100%",
    fontWeight: "500",
  },
  inputWrapper: {
    width: "100%",
    padding: "6px 0",
  },
  formRow: {
    margin: "24px 0",
  },
};

const formFields = [
  {
    name: "name",
    labelText: "Name",
    type: "text",
    style: commonStyle,
  },
  {
    name: "email",
    labelText: "Email",
    type: "email",
    style: commonStyle,
  },
  {
    name: "subject",
    labelText: "Subject",
    type: "text",
    style: commonStyle,
  },
  {
    name: "message",
    labelText: "Your Message",
    placeholder: "Type here...",
    type: "textarea",
    style: commonStyle,
  },
];

export default formFields;
