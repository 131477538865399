import {
  AiOutlineUser,
  AiOutlineHeart,
  AiOutlineSetting,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { MdPayment, MdLocalOffer } from "react-icons/md";
import { BiSpreadsheet } from "react-icons/bi";
import { IoNewspaperOutline } from "react-icons/io5";

const corporateSwitcherData = [
  {
    title: "Basics Info",
    name: "basics",
    icon: <AiOutlineUser />,
  },
  {
    title: "Address",
    name: "address",
    icon: <GoLocation />,
  },
  {
    title: "Payment",
    name: "payment",
    icon: <MdPayment />,
  },
  {
    title: "Wishlist",
    name: "wishlist",
    icon: <AiOutlineHeart />,
  },
  {
    title: "Orders",
    name: "orders",
    icon: <BiSpreadsheet />,
  },
  {
    title: "Purchased",
    name: "purchased",
    icon: <IoNewspaperOutline />,
  },
  {
    title: "Offers",
    name: "offers",
    icon: <MdLocalOffer />,
  },
  {
    title: "Settings",
    name: "settings",
    icon: <AiOutlineSetting />,
  },
];

export default corporateSwitcherData;
