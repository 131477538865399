import CustomLink from "../custom-link";
import {
  ProductsCategoriesCartImg,
  ProductsCategoriesCartSubCategories,
  ProductsCategoriesCartSubCategoriesHeader,
  ProductsCategoriesCartSubCategoriesList,
  ProductsCategoriesCartSubCategoriesListItem,
  ProductsCategoriesCartWrap,
} from "./ProductsCategoriesCartEl";
import faker from "faker";
import { useSelector } from "react-redux";

// Some Styles
const btnStyle = {
  color: "#fff",
  padding: "10px 20px",
  marginLeft: 20,
  display: "block",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: ".8rem",
};

const ProductsCategoriesCart = ({ id, title, image, subcategories }) => {
  const { mainColor } = useSelector(({ ui }) => ui);
  const path = useSelector((state) => state?.homepage?.data?.categories?.path);

  return (
    <ProductsCategoriesCartWrap mainColor={mainColor}>
      <ProductsCategoriesCartImg to={`/categories/main/${id}`}>
        <img src={`${path}/${image}`} alt="" />
      </ProductsCategoriesCartImg>
      <ProductsCategoriesCartSubCategories>
        <ProductsCategoriesCartSubCategoriesHeader>
          <h3>
            {title?.en?.length > 25
              ? `${title?.en?.substring(0, 25)}...`
              : title?.en}
          </h3>
          <CustomLink
            to={`/categories/main/${id}`}
            text="see all"
            style={{ ...btnStyle, backgroundColor: mainColor }}
          />
        </ProductsCategoriesCartSubCategoriesHeader>
        <ProductsCategoriesCartSubCategoriesList>
          {subcategories?.map((item, index) => {
            return (
              <ProductsCategoriesCartSubCategoriesListItem
                key={index}
                to={`/categories/sub/${item?.id}/main/${id}`}
              >
                {item?.title?.en}
              </ProductsCategoriesCartSubCategoriesListItem>
            );
          })}
        </ProductsCategoriesCartSubCategoriesList>
      </ProductsCategoriesCartSubCategories>
    </ProductsCategoriesCartWrap>
  );
};

export default ProductsCategoriesCart;
