import { Container } from "../../constants";
import { HomepageTopRatedSectionWrap } from "./HomepageTopRatedSectionEl";
import { ProductsCategoriesTitle } from "../products-categories/ProductsCategoriesSectionEl";
import AliceCarousel from "react-alice-carousel";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import SliderProductItem from "../slider-product-item";
import SkeletonComp from "../Skelaton/Skelaton";

const HomepageTopRatedSection = ({ data, path }) => {
  const responsive = {
    0: { items: 1 },
    768: { items: 1 },
    800: { items: 2 },
    992: { items: 3 },
    1024: { items: 5 },
  };

  if (!data) {
    return (
      <div
        className="Skeleton"
        style={{ display: "flex", justifyContent: "center", padding: "30px" }}
      >
        <div style={{ flex: 1 }}>
          <SkeletonComp style={{ border: "1px solid" }} />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
        <div style={{ flex: 1 }}>
          <SkeletonComp />
        </div>
      </div>
    );
  }

  const items = data.map((item, idx) => (
    <SliderProductItem
      key={idx}
      {...item}
      style={{ margin: "0 10px !important", width: "95%" }}
      isSale={idx % 2 === 1}
      path={path}
    />
  ));

  return (
    <HomepageTopRatedSectionWrap>
      <Container>
        {/* <HomepageTopRatedSectionHeader>
          <h4>Top Rated Products</h4>
          <HomepageTopRatedSectionSeeAll to="/categories">
            see all
          </HomepageTopRatedSectionSeeAll>
        </HomepageTopRatedSectionHeader>

        <HomepageTopRatedSectionGrid>
          {topRated.map((item, idx) => (
            <TopRatedItem key={idx} {...item} />
          ))}
        </HomepageTopRatedSectionGrid> */}
        <ProductsCategoriesTitle>Top Rated Products</ProductsCategoriesTitle>
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          disableDotsControls
          autoPlay
          autoPlayInterval={4300}
          infinite
          renderNextButton={() => (
            <HiArrowNarrowRight
              style={{ fontSize: 25, cursor: "pointer", marginTop: 50 }}
            />
          )}
          renderPrevButton={() => (
            <HiArrowNarrowLeft
              style={{ fontSize: 25, cursor: "pointer", marginTop: 50 }}
            />
          )}
        />
      </Container>
    </HomepageTopRatedSectionWrap>
  );
};

export default HomepageTopRatedSection;
