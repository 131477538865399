import { AiOutlineShareAlt, AiFillStar } from "react-icons/ai";
import { MdAddShoppingCart, MdDelete } from "react-icons/md";
import {
  VendorWishlistTabContentHeader,
  VendorWishlistTabContentRow,
  VendorWishlistTabContentRowLeft,
  VendorWishlistTabContentRowLeftStars,
  VendorWishlistTabContentWrap,
} from "../VendorBuyerDashboardEl";

const VendorWishlistTabContent = () => {
  return (
    <VendorWishlistTabContentWrap>
      <VendorWishlistTabContentHeader>
        <h4>Wishlist</h4>
      </VendorWishlistTabContentHeader>
      <VendorWishlistTabContentRow>
        <VendorWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <VendorWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </VendorWishlistTabContentRowLeftStars>
          </div>
        </VendorWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </VendorWishlistTabContentRow>
      <VendorWishlistTabContentRow>
        <VendorWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <VendorWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </VendorWishlistTabContentRowLeftStars>
          </div>
        </VendorWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </VendorWishlistTabContentRow>
      <VendorWishlistTabContentRow>
        <VendorWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <VendorWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </VendorWishlistTabContentRowLeftStars>
          </div>
        </VendorWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </VendorWishlistTabContentRow>
      <VendorWishlistTabContentRow>
        <VendorWishlistTabContentRowLeft to="/">
          <img
            src="https://images.pexels.com/photos/4269355/pexels-photo-4269355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt=""
          />
          <div>
            <h5>Product Name</h5>
            <span>$50.00</span>
            <VendorWishlistTabContentRowLeftStars>
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </VendorWishlistTabContentRowLeftStars>
          </div>
        </VendorWishlistTabContentRowLeft>

        <div>
          <MdAddShoppingCart />
          <AiOutlineShareAlt />
          <MdDelete />
        </div>
      </VendorWishlistTabContentRow>
    </VendorWishlistTabContentWrap>
  );
};

export default VendorWishlistTabContent;
