import richTextTypes from "./richText.types";

const initialState = {
  loading: true,
  data: {},
  error: false,
};

export const richTextReducer = (state = initialState, action) => {
  switch (action.type) {
    case richTextTypes.FETCH_RICH_TEXT_REQUEST:
      return {
        loading: true,
        data: {},
        error: false,
      };
    case richTextTypes.FETCH_RICH_TEXT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: false,
      };

    case richTextTypes.FETCH_RICH_TEXT_ERROR:
      return {
        loading: false,
        data: {},
        error: action.payload,
      };

    default:
      return state;
  }
};
