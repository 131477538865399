const UITypes = {
  CHANGE_CATEGORIES_VIEW_TO_GRID: 'CHANGE_CATEGORIES_VIEW_TO_GRID',
  CHANGE_CATEGORIES_VIEW_TO_LIST: 'CHANGE_CATEGORIES_VIEW_TO_LIST',
  CHANGE_APP_MAIN_COLOR: 'CHANGE_APP_MAIN_COLOR',
  // CategoriesAllPage types
  SHOW_CATEGORIES_FILTER_SIDEBAR: 'SHOW_CATEGORIES_FILTER_SIDEBAR',
  SHOW_POPUP: 'SHOW_POPUP',
};

export default UITypes;
