import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  borderTop,
  mainColor,
  screenLg,
  screenMLg,
  secColor,
} from "../../constants";

export const HomepageBestSellerSectionWrap = styled.div`
  border-top: 1px solid ${borderTop};
  /* margin-top: 200px;
  padding-top: 50px; */
`;

export const HomepageBestSellerSectionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  @media screen and (max-width: ${screenLg}) {
    grid-template-columns: 1fr;
  }
`;

export const HomepageBestSellerSectionContentLeft = styled(Link)`
  background-image: url(${({ bg }) => bg});
  height: 650px;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
`;

export const HomepageBestSellerSectionCategory = styled.div`
  background-color: ${mainColor};
  width: fit-content;
  color: ${secColor};
  padding: 5px 10px;
  margin: 20px;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 600;
`;

export const HomepageBestSellerSectionTitle = styled.div`
  color: ${mainColor};
  font-size: 3rem;
  font-weight: 700;
  margin: 20px;
  line-height: 1;
`;

export const HomepageBestSellerSectionSeeAll = styled(Link)`
  background-color: transparent;
  color: ${mainColor};
  border: 2px solid ${mainColor};
  padding: 5px 20px;
  display: block;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  width: fit-content;
  position: absolute;
  bottom: 20px;
  left: 20px;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${mainColor};
    color: ${secColor};
  }
`;

export const HomepageBestSellerSectionContentRight = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  height: fit-content;

  @media screen and (max-width: ${screenMLg}) {
    height: auto !important;
  }
`;

export const BestSellerProductItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: 180px;
    border-radius: 5px;
  }

  a {
    color: ${mainColor};
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
  }
`;
