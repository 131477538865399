import styled, { css } from "styled-components";
import { screenLg } from "../../../constants";

export const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-top: 72px;

  @media screen and (max-width: ${screenLg}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ActiveNavItem = css`
  color: ${({ mainColor }) => mainColor};
  ::after {
    transform: scaleX(1);
  }
`;
export const NavItem = styled.button`
  all: unset;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 12px;
  color: #676161;
  font-weight: 700;
  position: relative;

  ::after {
    content: "";
    border-bottom: 6px solid ${({ mainColor }) => mainColor};
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  :hover {
    ${ActiveNavItem}
  }
  &.active {
    ${ActiveNavItem}
  }
  :not(:first-of-type) {
    margin: 0 20px;
  }

  @media screen and (max-width: ${screenLg}) {
    padding: 16px 0;
    :not(:first-of-type) {
      margin: 0;
    }
  }
`;
