import { useEffect, useState } from "react";
import AOS from "aos";
import { motion } from "framer-motion";
import VendorBuyerDashboardSwitcher from "../../components/vendor-buyer-dashboard-switcher";
import PagesBreadCrumb from "../../components/pages-bread-crumb";
import { Container } from "../../constants";
import {
  VendorBuyerDashboardGrid,
  VendorBuyerDashboardTitle,
  VendorBuyerDashboardWrap,
} from "./VendorBuyerDashboardEl";
import VendorAddressTabContent from "./utils/VendorAddressTabContent";
import VendorBasicsInfoTabContent from "./utils/VendorBasicsInfoTabContent";
import VendorOrdersTabContent from "./utils/VendorOrdersTabContent";
import VendorPaymentTabContent from "./utils/VendorPaymentTabContent";
import VendorSettingsTabContent from "./utils/VendorSettingsTabContent";
import VendorWishlistTabContent from "./utils/VendorWishlistTabContent";
import VendorProductsTabContent from "./utils/VendorProductsTabContent";
import VendorRevenueTabContent from "./utils/VendorRevenueTabContent";
import VendorRequestsTabContent from "./utils/VendorRequestsTabContent";
import containerVariants from "../../animation";

const VendorBuyerDashboard = () => {
  const [activeTab, setActiveTab] = useState("basics");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visiable"
      exit="exit"
      data-aos="zoom-in-down"
    >
      <VendorBuyerDashboardWrap>
        <Container>
          <PagesBreadCrumb prev="home" curr="Vendor Buyer Dashboard" />
          <VendorBuyerDashboardTitle>
            Vendor Buyer Dashboard
          </VendorBuyerDashboardTitle>

          <VendorBuyerDashboardGrid id="vendor-grid">
            <VendorBuyerDashboardSwitcher
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {activeTab === "basics" && <VendorBasicsInfoTabContent />}
            {activeTab === "address" && <VendorAddressTabContent />}
            {activeTab === "payment" && <VendorPaymentTabContent />}
            {activeTab === "wishlist" && <VendorWishlistTabContent />}
            {activeTab === "products" && <VendorProductsTabContent />}
            {activeTab === "orders" && <VendorOrdersTabContent />}
            {activeTab === "revenue" && <VendorRevenueTabContent />}
            {activeTab === "requests" && <VendorRequestsTabContent />}
            {activeTab === "settings" && <VendorSettingsTabContent />}
          </VendorBuyerDashboardGrid>
        </Container>
      </VendorBuyerDashboardWrap>
    </motion.div>
  );
};

export default VendorBuyerDashboard;
