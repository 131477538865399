import formatRichText from "../../utils/formatRichText";
import { RichTextWrapper } from "./RichTextContainerEl";

const RichTextContainer = ({ richText }) => {
  return (
    <RichTextWrapper
      dangerouslySetInnerHTML={{ __html: formatRichText(richText) }}
    />
  );
};

export default RichTextContainer;
