import { Link } from "react-router-dom";
import styled from "styled-components";
import { screenMd } from "../../constants";

export const ProductsCategoriesCartWrap = styled.div`
  border: 2px solid ${({ mainColor }) => mainColor};
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  margin-bottom: 20px;
  height: 300px;

  @media screen and (max-width: ${screenMd}) {
    grid-template-columns: 1fr;
    height: auto !important;
  }
`;

export const ProductsCategoriesCartImg = styled(Link)`
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
  img {
    width: 300px;
    height: 100%;
    transition: 0.4s ease-in-out;
  }

  @media screen and (max-width: ${screenMd}) {
    height: 250px !important;
  }
`;

export const ProductsCategoriesCartSubCategories = styled.div`
  padding: 20px;
`;

export const ProductsCategoriesCartSubCategoriesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;
  padding-bottom: 20px;
  margin-bottom: 20px;

  h3 {
    font-size: 1.5rem;
  }

  @media screen and (max-width: ${screenMd}) {
    flex-direction: column;

    h3 {
      margin-bottom: 15px;
      font-size: 1rem;
    }

    a {
      width: 100% !important;
      margin: 0 !important;

      span {
        justify-content: center;
      }
    }
  }
`;

export const ProductsCategoriesCartSubCategoriesList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: ${screenMd}) {
    grid-template-columns: 1fr;
  }
`;

export const ProductsCategoriesCartSubCategoriesListItem = styled(Link)`
  color: #333;
  font-size: 1rem;
  margin-bottom: 10px;
`;
