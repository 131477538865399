const reviewsTypes = {
  // Reviews
  FETCH_REVIEWS_REQUEST: "FETCH_REVIEWS_REQUEST",
  FETCH_REVIEWS_SUCCESS: "FETCH_REVIEWS_SUCCESS",
  FETCH_REVIEWS_ERROR: "FETCH_REVIEWS_ERROR",

  ADD_REVIEWS_REQUEST: "ADD_REVIEWS_REQUEST",
  ADD_REVIEWS_SUCCESS: "ADD_REVIEWS_SUCCESS",
  ADD_REVIEWS_ERROR: "ADD_REVIEWS_ERROR",
};

export default reviewsTypes;
