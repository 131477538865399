import { Select } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

const SelectWrapper = styled.div`
  .ant-select-selection-placeholder {
    color: rgb(117, 117, 117);
    font-size: 14px;
  }
`;
const CustomSelect = ({
  name,
  control,
  options,
  placeholder,
  style,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <SelectWrapper>
            <Select
              options={options}
              {...field}
              placeholder={placeholder}
              style={style}
              {...rest}
            />
          </SelectWrapper>
        );
      }}
    />
  );
};

export default CustomSelect;
