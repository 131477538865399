import styled from "styled-components";
import { marginBt } from "../../../constants";

export const MobileHeaderWrapper = styled.div`
  background-color: #fff;
  padding-bottom: 20px;
  margin-bottom: ${marginBt};
`;

export const MobileHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: 30px;
`;

export const ToggleMobileSidebar = styled.div``;
