import cartTypes from "./cart.types";

const initialState = { loading: false, data: {}, cartItems: [], error: false };

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartTypes.CART_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        cartItems: [],
        data: {},
        error: false,
      };
    case cartTypes.CART_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        cartItems: action.payload.data,
        data: action.payload.message,
        error: false,
      };
    case cartTypes.CART_ADD_FAILED:
      return {
        ...state,
        loading: false,
        cartItems: [],
        error: action.payload,
      };

    case cartTypes.GET_CART_ITEMS_REQUEST:
      return {
        ...state
      };
    case cartTypes.GET_CART_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        cartItems: action.payload,
        error: {},
      };
    case cartTypes.GET_CART_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        cartItems: [],
        error: action.payload,
      };
    case cartTypes.REMOVE_ITEM_FROM_CART_LOCAL:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          item => item.product_id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default cartReducer;
