import compareTypes from "./compare.types";

const initialState = { compareItems: [] };

export const addProductToCompareTable = (items, itemToAdd) => {
  const exitsItem = items.find(item => item.id === itemToAdd.id);

  if (exitsItem) {
    return items.map(item => (item.id === itemToAdd.id ? item : item));
  }
  return [...items, { ...itemToAdd }];
};

const compareReducer = (state = initialState, action) => {
  switch (action.type) {
    case compareTypes.ADD_PRODUCT_TO_COMPARE:
      return {
        ...state,
        compareItems: addProductToCompareTable(
          state.compareItems,
          action.payload
        ),
      };
    case compareTypes.REMOVE_ITEM_FROM_COMPARE_LOCAL:
      return {
        ...state,
        compareItems: state.compareItems.filter(
          item => item.product_id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default compareReducer;
