import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector } from "react-redux";
import { GalleryWrapper } from "./GalleryEl";

const ProductDetailsMainGallery = ({ product }) => {
  const { data } = useSelector(({ product }) => product);
  const { mainColor } = useSelector(({ ui }) => ui);
  const path = useSelector(
    (state) => state.homepage?.data?.featuredProducts?.path
  );

  const images = [
    {
      original: `${path}/${product?.image1}`,
      thumbnail: `${path}/${product?.image1}`,
    },
    {
      original: `${path}/${product?.image2}`,
      thumbnail: `${path}/${product?.image2}`,
    },
    {
      original: `${path}/${product?.image3}`,
      thumbnail: `${path}/${product?.image3}`,
    },
    {
      original: `${path}/${product?.image4}`,
      thumbnail: `${path}/${product?.image4}`,
    },
  ];

  return (
    <GalleryWrapper mainColor={mainColor}>
      <ImageGallery
        items={images}
        thumbnailPosition="left"
        showPlayButton={false}
        showFullscreenButton={false}
        useBrowserFullscreen={false}
      />
    </GalleryWrapper>
  );
};

export default ProductDetailsMainGallery;
