const vendorFields = [
  {
    name: "company_name",
    type: "text",
    placeholder: "Company Name",
    labelText: "Company Name",
    id: "company_name",
    htmlFor: "company_name",
  },
  {
    name: "name",
    type: "text",
    placeholder: "Contact Person Name",
    labelText: "Name",
    id: "name",
    htmlFor: "name",
  },
  {
    name: "contact_person_title",
    type: "text",
    placeholder: "Contact Person Title",
    labelText: "contact_person_title",
    id: "contact_person_title",
    htmlFor: "contact_person_title",
  },
  {
    name: "mobile",
    type: "tel",
    placeholder: "Mobile Number",
    labelText: "Phone",
    id: "phone",
    htmlFor: "phone",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email Address",
    labelText: "Email Address",
    id: "email",
    htmlFor: "email",
  },
  {
    name: "password",
    type: "password",
    placeholder: "Password",
    labelText: "Password",
    id: "password",
    htmlFor: "password",
  },
  {
    name: "password_confirmation",
    type: "password",
    placeholder: "Confirm Password",
    labelText: "Confirm Password",
    id: "password_confirmation",
    htmlFor: "password_confirmation",
  }
  // {
  //   name: "company_papers",
  //   type: "file",
  //   placeholder: "Company Papers",
  //   labelText: "Company Papers",
  //   id: "company_papers",
  //   htmlFor: "company_papers",
  // },
];

export default vendorFields;
