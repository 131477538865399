import { useEffect, useState } from "react";

import { RenderCheckoutSwitcher } from "..";
import CustomButton from "../../../components/custom-button";
import {
  ChosenPaymentMethodCardFooter,
  ChosenPaymentMethodCardHeader,
  ChosenPaymentMethodGrid,
  ChosenPaymentMethodWrap,
} from "../CheckoutPageEl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const ChosenPaymentMethodCard = ({ imgSrc, onClick, isActive }) => {
  const { mainColor } = useSelector(({ ui }) => ui);

  return (
    <ChosenPaymentMethodWrap
      mainColor={isActive ? mainColor : false}
      onClick={onClick}
    >
      <ChosenPaymentMethodCardHeader>
        <img src={imgSrc} alt="" width={100} height={40} />
      </ChosenPaymentMethodCardHeader>

      <ChosenPaymentMethodCardFooter>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam
        aperiam ab, pariatur reiciendis quia et sequi beatae id est ea?
      </ChosenPaymentMethodCardFooter>
    </ChosenPaymentMethodWrap>
  );
};

const CheckoutPaymentMethod = () => {
  const { push } = useHistory();

  const [favPaymentMethod, setFavPaymentMethod] = useState("visa");
  const { addressId } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <RenderCheckoutSwitcher step1 step2 step3 />

      <div className="custom-container checkout-payment-method-page formwrapper">
        <h1>Choose your way of payment</h1>
        <div>
          <form onSubmit={handleSubmit}>
            <ChosenPaymentMethodGrid>
              {/* <ChosenPaymentMethodCard
                onClick={() => setFavPaymentMethod("PayPal")}
                isActive={favPaymentMethod === "PayPal" && true}
                imgSrc="https://www.pngall.com/wp-content/uploads/2016/03/Paypal-Logo-PNG.png"
              /> */}
              <ChosenPaymentMethodCard
                onClick={() => setFavPaymentMethod("visa")}
                isActive={favPaymentMethod === "visa" && true}
                imgSrc="https://pngimg.com/uploads/visa/visa_PNG4.png"
              />
              <ChosenPaymentMethodCard
                onClick={() => setFavPaymentMethod("cash")}
                isActive={favPaymentMethod === "cash" && true}
                imgSrc="https://www.arawnaqperfume.com/wp-content/uploads/2021/03/cash-on-delivery-icon-14.png"
              />
              {/* <ChosenPaymentMethodCard
                onClick={() => setFavPaymentMethod("masterCard")}
                isActive={favPaymentMethod === "masterCard" && true}
                imgSrc="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-png-transparent-mastercard-logo-images-12.png"
              /> */}
            </ChosenPaymentMethodGrid>
            <CustomButton
              onClick={() =>
                push(`/checkout/place-order/${addressId}/${favPaymentMethod}`)
              }
              text="Continue"
              style={{
                padding: "13px 30px",
                marginTop: 40,
                fontWeight: 500,
              }}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CheckoutPaymentMethod;
