import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReviewsFormTitle, ReviewsFormWrapper, Form } from "./ReviewsFormEl";
import formFields from "./utils";
import { FormRow, InputWrapper } from "../../../form-elements/constants";
import FormHandler from "../../../form-elements/form-handler";
import Button from "../../../button";
import { AddReviewSchema } from "../../../../validationSchema";
import { useDispatch } from "react-redux";
import { addReviewsAction } from "../../../../redux/reviews/reviews.actions";
import { useParams } from "react-router-dom";

const ReviewsForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(AddReviewSchema) });
  const { productId } = useParams();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(addReviewsAction({ ...data, product_id: productId })).then(() => {
      reset();
    });
  };
  return (
    <ReviewsFormWrapper>
      <ReviewsFormTitle>Submit Your Review</ReviewsFormTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormHandler
          control={control}
          formFields={formFields}
          errors={errors}
        />
        <FormRow justifyContent="flex-end">
          <InputWrapper minWidth="20%">
            <Button type="submit" text="Submit" />
          </InputWrapper>
        </FormRow>
      </Form>
    </ReviewsFormWrapper>
  );
};

export default ReviewsForm;
