import React from "react";
import { Col, Row } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  ContactInfoValue,
  ContactInfoWrapper,
  ContactMail,
  DetailsWrapper,
  InfoTitle,
  InfoWrapper,
  OpeningHoursValue,
} from "./ContactUsDetailsEl";

const ContactUsDetails = () => {
  return (
    <DetailsWrapper>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55251.37709964616!2d31.223444832512136!3d30.05948381032293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583fa60b21beeb%3A0x79dfb296e8423bba!2sCairo%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1631455119497!5m2!1sen!2seg"
        width="100%"
        height={360}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
      />
      <InfoWrapper>
        <Row justify="space-between">
          <Col span="24">
            <InfoTitle>Contact Details</InfoTitle>
            <Row justify="space-between">
              <Col lg={{ span: "12" }}>
                <ContactInfoWrapper>
                  <PhoneOutlined />
                  <ContactInfoValue paddingLeft="24px">
                    1-677-124-447
                  </ContactInfoValue>
                </ContactInfoWrapper>
              </Col>
              <Col lg={{ span: "12" }}>
                <ContactInfoWrapper>
                  <MailOutlined />
                  <ContactMail> office@example.com</ContactMail>
                </ContactInfoWrapper>
              </Col>
              <Col span="24">
                <ContactInfoWrapper>
                  <FaMapMarkerAlt />
                  <ContactInfoValue paddingLeft="24px">
                    184 Main Collins Street West Victoria 8007
                  </ContactInfoValue>
                </ContactInfoWrapper>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: "12" }}>
            <InfoTitle>Opening Hours</InfoTitle>
            <Row>
              <Col span="24">
                <OpeningHoursValue>Monday to Friday: 9am-9pm</OpeningHoursValue>
              </Col>
              <Col span="24">
                <OpeningHoursValue>
                  Saturday to Sunday: 9am-10pm
                </OpeningHoursValue>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: "12" }}>
            <InfoTitle>Careers</InfoTitle>
            <Row>
              <Col span="24">
                <ContactInfoValue padding="0">
                  If you’re interested in employment please email us:
                  <ContactMail padding="0"> office@example.com</ContactMail>
                </ContactInfoValue>
              </Col>
            </Row>
          </Col>
        </Row>
      </InfoWrapper>
    </DetailsWrapper>
  );
};

export default ContactUsDetails;
