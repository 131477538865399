import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { secColor } from "../../constants";

export const CustomerBuyerDashboardWrap = styled.div`
  margin: 30px 0 50px 0;
  padding-top: 30px;
`;

export const CustomerBuyerDashboardTitle = styled.h1`
  text-align: center;
  color: ${({ mainColor }) => mainColor};
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 40px;
`;

export const CustomerBuyerDashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 25px;
  height: fit-content;
`;

export const CustomerBuyerDashboardSwitcherItemWrap = styled(ScrollLink)`
  border: 1px solid ${({ mainColor }) => mainColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ isActiveTab, mainColor }) =>
    isActiveTab ? secColor : mainColor};
  transition: 0.4s ease-in-out;
  background-color: ${({ isActiveTab, mainColor }) =>
    isActiveTab ? mainColor : "#fff"};

  &:hover {
    background-color: ${({ mainColor }) => mainColor};
    color: ${secColor} !important;
  }

  span:last-of-type {
    display: block;
    margin-bottom: 5px;
  }

  svg {
    margin-right: 20px;
    font-size: 1.5rem !important;
    line-height: 1;
    margin-top: 3px;
  }
`;

/***
 *  ====================================================
 *  ============== Customer Basics Info Tab=============
 *  ====================================================
 */

export const CustomerBasicsInfoTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #1d907936;

    & > div {
      display: flex;
      flex-direction: column;

      span {
        font-weight: 600;
        color: ${({ mainColor }) => mainColor};
      }
      span:first-of-type {
        margin-bottom: 10px;
      }
    }

    svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;

export const CustomerBasicsInfoTabContentHeader = styled.div`
  border-bottom: 1px solid #1d907936;
  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0 !important;
  }
`;

/***
 *  ====================================================
 *  ============== Customer Address Info Tab============
 *  ====================================================
 */

export const CustomerAddressTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;

export const CustomerAddressTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CustomerAddressTabContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #1d907936;
  }

  span {
    font-weight: 600;
    color: ${({ mainColor }) => mainColor};
    font-size: 0.8rem;
  }
  span:first-of-type {
    margin-bottom: 10px;
  }

  svg {
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  svg:first-of-type {
    color: #e31a1a;
  }

  svg:last-of-type {
    color: ${({ mainColor }) => mainColor};
  }
`;

/***
 *  ====================================================
 *  ============== Customer Payment Info Tab============
 *  ====================================================
 */

export const CustomerPaymentTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;
export const CustomerPaymentTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CustomerPaymentTabContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #1d907936;
  }

  & > div {
    display: flex;
    align-items: center;

    svg {
      font-size: 50px;
      margin-right: 20px;
    }

    span {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ mainColor }) => mainColor};
    }
  }

  span {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ mainColor }) => mainColor};
  }

  svg {
    margin-left: 20px;
    cursor: pointer;
  }

  svg:last-of-type {
    color: ${({ mainColor }) => mainColor};
  }
`;

/***
 *  ====================================================
 *  ============== Customer Wishlist Info Tab===========
 *  ====================================================
 */

export const CustomerWishlistTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;

export const CustomerWishlistTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CustomerWishlistTabContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #1d907936;
  }

  span {
    font-weight: 600;
    color: ${({ mainColor }) => mainColor};
    font-size: 0.8rem;
  }
  span:first-of-type {
    margin-bottom: 10px;
  }

  svg {
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
    color: ${({ mainColor }) => mainColor};
  }

  svg:last-of-type {
    color: #e31a1a;
  }
`;

export const CustomerWishlistTabContentRowLeft = styled(Link)`
  display: flex;

  img {
    width: 85px;
    height: 65px;
    margin-right: 10px;
    border-radius: 5px;
  }

  h5 {
    color: ${({ mainColor }) => mainColor};
    margin: 0 !important;
  }
`;

export const CustomerWishlistTabContentRowLeftStars = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #efef15 !important;
    margin: 0 !important;
    margin-right: 4px;
    font-size: 0.8rem;
  }
`;

/***
 *  ====================================================
 *  ============== Customer Orders Info Tab===========
 *  ====================================================
 */

export const CustomerOrdersTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 16px;
      transform: translate(5px, -50%);
      color: #1d9079;
    }
  }
`;

export const CustomerOrdersTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CustomerOrdersTabContentCollapseHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ mainColor }) => mainColor};

  & > div {
    margin-right: 80px;
    display: flex;
    flex-direction: column;
  }
`;

export const CollapseRowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.2rem;
  }
`;

export const CollapseRowLeft = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
    margin-right: 20px;
    border-radius: 5px;
  }

  a {
    color: ${({ mainColor }) => mainColor};
    font-size: 1rem;
    font-weight: 600;
  }
`;
