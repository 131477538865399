import React, { useState } from 'react';
import { Button, Modal, InputNumber, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import { getItemsFromCartAction } from '../redux/cart/cart.actions';
import { localCartAdd } from '../redux/localCart/localCart.actions';

const CartModal = ({ visible, setVisible, product }) => {
    const token = useSelector(({ buyer }) => buyer.data.access_token);
    const localCartItems = useSelector((state) => token ? state?.cart?.cartItems?.card : state?.localCart?.cartItems)
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = React.useState(1)
    const productPrice = parseInt(product?.offer_price) === 0 ? product?.price : product?.offer_price;
    const dispatch = useDispatch();
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setVisible(false);
        }, 3000);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    console.log("QUANTITY", quantity)

    React.useEffect(() => {
        if (quantity === null) {
            setQuantity(1)
        }
    }, [quantity])

    console.log("PRODUCT", product)

    const handleAddClick = () => {
        setLoading(true)
        api.post(`/addtocart`, { token, pro_id: product?.id, quantity })
            .then(() => {
                setVisible(false)
                setLoading(false)
                dispatch(getItemsFromCartAction(token))
                message.success('Item has been added to cart successfully')
            })
    }

    const handleLocalAdd = () => {
        dispatch(localCartAdd({ quantity: quantity, item: product }))
        message.success('Item has been added to cart')
        setVisible(false)
    }

    const currentItem = localCartItems?.find(item => item.item.id === product?.id);
    const currentItemQuntity = currentItem?.quantity;
    return (
        <>
            <Modal
                visible={visible}
                title="Add To Cart"
                onOk={handleOk}
                onCancel={handleCancel}
                width={600}
                footer={[
                    <div className='flex-between'>
                        <Button style={{ fontWeight: '500', borderRadius: '12px' }} key="back" onClick={handleCancel}>
                            Continue Shopping
                        </Button>
                        <Button onClick={() => token ? handleAddClick() : handleLocalAdd()} style={{ fontWeight: '500', borderRadius: '12px' }} key="submit" type="primary" loading={loading}>
                            Add To Cart
                        </Button>
                    </div>
                ]}
            >
                <div style={{ padding: '30px 20px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                            <img style={{ maxHeight: '70px', maxWidth: '70px' }} src={`${product?.path}/${product?.image}`} alt='' />
                            <p style={{ marginLeft: '10px', fontWeight: '500', color: '#444' }}>{product?.name}</p>
                        </div>
                        <div className='flex-end' style={{ flex: '1' }}>
                            <div>
                                <p style={{ marginBottom: '15px', fontWeight: '500', color: '#444' }}>{quantity} x {product?.productPrice} EGP</p>
                                <InputNumber min={1} max={20} onChange={(value) => setQuantity(value)} value={quantity} placeholder='Quantity' type='number' size='large' />
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '10px 0px', background: '#eee', color: '#eee', width: '100%', height: "1px" }} />
                    <div style={{ marginBottom: '-20px', fontWeight: '500' }} className='flex-between'>
                        <p style={{ color: "#777" }}>You have {currentItemQuntity ? currentItemQuntity : 0} items in your cart</p>
                        <p>Cart subtotal: {(quantity * product?.productPrice).toFixed(2)}</p>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CartModal;
