import localWishlistTypes from "./localWishlist.types"

export const getLocalWishlist = (data) => dispatch => {
    dispatch({
        type: localWishlistTypes.GET_WISHLIST,
        payload: data
    })
}
export const localWishlistAdd = (data) => dispatch => {
    dispatch({
        type: localWishlistTypes.ADD_ITEM,
        payload: data
    })
}
export const deleteLocalWishlistItem = (data) => dispatch => {
    dispatch({
        type: localWishlistTypes.DELETE_ITEM,
        payload: data
    })
}
export const clearLocalWishlist = (data) => dispatch => {
    dispatch({
        type: localWishlistTypes.CLEAR_WISHLIST,
        payload: data
    })
}