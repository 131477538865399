import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import {
  CustomerAddressTabContentHeader,
  CustomerAddressTabContentRow,
  CustomerAddressTabContentWrap,
} from "../CustomerBuyerDashboardEl";
import AddAddressModal from "../../../components/AddAddressModal";
import api from "../../../api";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import { getAddresses } from "../../../redux/address/address.actions";

const CustomerAddressTabContent = () => {
  const [show, setShow] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const token = useSelector(({ buyer }) => buyer.data.access_token);
  const data = useSelector((state) => state?.address?.data);

  console.log("DATA", data);
  return (
    <CustomerAddressTabContentWrap>
      <CustomerAddressTabContentHeader>
        <AddAddressModal
          getData={getAddresses}
          visible={show}
          setVisible={setShow}
        />
        <h4>Address</h4>
        <AiOutlinePlus onClick={() => setShow(true)} />
      </CustomerAddressTabContentHeader>
      {!loading ? (
        data?.map((item, index) => {
          return (
            <CustomerAddressTabContentRow>
              <div>
                <h5>{item?.address_title}</h5>{" "}
                <span>{item?.address_details}</span>
              </div>

              <div>
                <MdDelete />
                <FiEdit2 />
              </div>
            </CustomerAddressTabContentRow>
          );
        })
      ) : (
        <CustomerAddressTabContentRow>
          <Skeleton />
        </CustomerAddressTabContentRow>
      )}
    </CustomerAddressTabContentWrap>
  );
};

export default CustomerAddressTabContent;
