import { Link } from "react-router-dom";
import styled from "styled-components";
import { appLink, mainColor, marginBt, screenLg } from "../../../constants";

export const MainHeaderWrapper = styled.div`
  background-color: #fff;
  padding-bottom: 20px;
  margin-bottom: ${marginBt};

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    border-right: 1px solid ${mainColor} !important;
  }
`;

export const MainHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #bfbfbf8f;
  overflow: hidden;

  @media screen and (max-width: ${screenLg}) {
    margin-top: 30px;
  }
`;

export const UserArea = styled.div`
  display: flex;
  align-items: center;
  /* flex: 1; */
  /* max-width: 220px; */
  /* justify-content: space-between; */

  & > div {
    border: 1px solid red;
  }

  @media screen and (max-width: ${screenLg}) {
    display: none;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${screenLg}) {
    display: none;
  }
`;

export const UserAccount = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 30px;
    margin-right: 10px;
    color: ${appLink};
  }

  a {
    color: ${appLink};
    transition: 0.4s ease-in-out;

    &:hover {
      color: ${mainColor};
    }
  }
`;

export const ShoppingCartArea = styled(Link)`
  .ant-avatar {
    background-color: #fff;
    cursor: pointer;

    svg {
      color: ${appLink};
      font-size: 30px;
    }
  }
`;

export const WishlistArea = styled(Link)`
  svg {
    color: ${appLink};
    font-size: 30px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${screenLg}) {
    display: none;
  }
`;

export const SearchForm = styled.form`
  border: 1px solid ${mainColor};
  width: 500px;
  margin: 30px auto;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: ${screenLg}) {
    display: none;
  }
`;

export const ProductsSearchInput = styled.input`
  all: unset;
  padding-left: 10px;
  width: 100%;
`;

export const ProductsSearchIcon = styled.div`
  background-color: ${mainColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  border-radius: 3px;
  color: #fff;
  position: absolute;
  right: 3px;
  cursor: pointer;
`;

export const ToggleMobileSidebar = styled.div`
  @media screen and (min-width: ${screenLg}) {
    display: none;
  }

  @media screen and (max-width: ${screenLg}) {
    display: block;
  }
`;

export const MainHeaderRightWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const MainHeaderRightBox = styled(Link)`
  border-left: 1px solid #bfbfbf8f;
  padding: 10px 20px;
  font-size: 1.5rem;
  color: ${mainColor};
`;
