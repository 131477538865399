import React from "react";
import { Title, TitleContent, TitleWrapper } from "./ContactUsBannerEl";

const ContactUsBanner = () => {
  return (
    <TitleWrapper>
      <TitleContent backgroundImage="https://unsplash.com/photos/tMI2_-r5Nfo/download?force=true&w=1920">
        <Title>Contact Us</Title>
      </TitleContent>
    </TitleWrapper>
  );
};

export default ContactUsBanner;
