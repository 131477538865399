import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { secColor } from "../../constants";

export const CorporateBuyerDashboardWrap = styled.div`
  margin-top: 30px;
  padding-top: 30px;
`;

export const CorporateBuyerDashboardTitle = styled.h1`
  text-align: center;
  color: #333;
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 40px;
`;

export const CorporateBuyerDashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 25px;
  height: fit-content;
`;

export const CorporateBuyerDashboardSwitcherItemWrap = styled(ScrollLink)`
  border: 1px solid ${({ mainColor }) => mainColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ isActiveTab, mainColor }) =>
    isActiveTab ? secColor : mainColor};
  transition: 0.4s ease-in-out;
  background-color: ${({ isActiveTab, mainColor }) =>
    isActiveTab ? mainColor : "#fff"};

  &:hover {
    background-color: ${({ mainColor }) => mainColor};
    color: ${secColor} !important;
  }

  span:last-of-type {
    display: block;
    margin-bottom: 5px;
  }

  svg {
    margin-right: 20px;
    font-size: 1.5rem !important;
    line-height: 1;
    margin-top: 3px;
  }
`;

/***
 *  ====================================================
 *  ============== Corporate Basics Info Tab=============
 *  ====================================================
 */

export const CorporateBasicsInfoTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #1d907936;

    & > div {
      display: flex;
      flex-direction: column;

      span {
        font-weight: 600;
        color: ${({ mainColor }) => mainColor};
      }
      span:first-of-type {
        margin-bottom: 10px;
      }
    }

    svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;

export const CorporateBasicsInfoTabContentHeader = styled.div`
  border-bottom: 1px solid #1d907936;
  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0 !important;
  }
`;

/***
 *  ====================================================
 *  ============== Corporate Address Info Tab============
 *  ====================================================
 */

export const CorporateAddressTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;

export const CorporateAddressTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CorporateAddressTabContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #1d907936;
  }

  span {
    font-weight: 600;
    color: ${({ mainColor }) => mainColor};
    font-size: 0.8rem;
  }
  span:first-of-type {
    margin-bottom: 10px;
  }

  svg {
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  svg:first-of-type {
    color: #e31a1a;
  }

  svg:last-of-type {
    color: ${({ mainColor }) => mainColor};
  }
`;

/***
 *  ====================================================
 *  ============== Corporate Payment Info Tab============
 *  ====================================================
 */

export const CorporatePaymentTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;
export const CorporatePaymentTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CorporatePaymentTabContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #1d907936;
  }

  & > div {
    display: flex;
    align-items: center;

    svg {
      font-size: 50px;
      margin-right: 20px;
    }

    span {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ mainColor }) => mainColor};
    }
  }

  span {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ mainColor }) => mainColor};
  }

  svg {
    margin-left: 20px;
    cursor: pointer;
  }

  svg:last-of-type {
    color: ${({ mainColor }) => mainColor};
  }
`;

/***
 *  ====================================================
 *  ============== Corporate Wishlist Info Tab===========
 *  ====================================================
 */

export const CorporateWishlistTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }
`;

export const CorporateWishlistTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CorporateWishlistTabContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  &:not(:last-of-type) {
    border-bottom: 1px solid #1d907936;
  }

  span {
    font-weight: 600;
    color: ${({ mainColor }) => mainColor};
    font-size: 0.8rem;
  }
  span:first-of-type {
    margin-bottom: 10px;
  }

  svg {
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
    color: ${({ mainColor }) => mainColor};
  }

  svg:last-of-type {
    color: #e31a1a;
  }
`;

export const CorporateWishlistTabContentRowLeft = styled(Link)`
  display: flex;

  img {
    width: 85px;
    height: 65px;
    margin-right: 10px;
    border-radius: 5px;
  }

  h5 {
    color: ${({ mainColor }) => mainColor};
    margin: 0 !important;
  }
`;

export const CorporateWishlistTabContentRowLeftStars = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #efef15 !important;
    margin: 0 !important;
    margin-right: 4px;
    font-size: 0.8rem;
  }
`;

/***
 *  ====================================================
 *  ============== Corporate Orders Info Tab===========
 *  ====================================================
 */

export const CorporateOrdersTabContentWrap = styled.div`
  border: 1px solid #1d907936;
  border-radius: 5px;
  height: fit-content;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #1d907936;
    }

    & > svg {
      font-size: 20px;
      cursor: pointer;
      color: ${({ mainColor }) => mainColor};
    }
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 16px;
      transform: translate(5px, -50%);
      color: #1d9079;
    }
  }
`;

export const CorporateOrdersTabContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.3rem;
    font-weight: 600;
    border: none !important;
  }
`;

export const CorporateOrdersTabContentCollapseHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ mainColor }) => mainColor};

  & > div {
    margin-right: 80px;
    display: flex;
    flex-direction: column;
  }
`;

export const CollapseRowWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${({ mainColor }) => mainColor};
    font-size: 1.2rem;
  }
`;

export const CollapseRowLeft = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
    margin-right: 20px;
    border-radius: 5px;
  }

  a {
    color: ${({ mainColor }) => mainColor};
    font-size: 1rem;
    font-weight: 600;
  }
`;
