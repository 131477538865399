import styled, { css } from "styled-components";
import { mainColor, screenMd } from "../../constants";

export const CategoriesFilterSidebarWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  left: ${({ isOpen }) => (isOpen ? 0 : "-150%")};
  z-index: 9999;
  width: 100%;
  /* max-width: 300px; */
  height: 100%;
  background-color: #fff;
  justify-content: center;
  transition: 0.7s all ease-in-out;
  box-shadow: 1px 0px 0px 1px #1d9079;
  overflow-y: auto;
  padding: 20px;

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${mainColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5d9c90;
  }

  @media screen and (min-width: ${screenMd}) {
    display: none !important;
  }
`;

export const SidebarBoxWrap = styled.div`
  /* border: 1px solid red; */

  .ant-input-group-addon button {
    border-color: ${mainColor};
    background: ${mainColor};
  }
  .ant-input-group input {
    &:focus {
      border: 1px solid ${mainColor} !important;
      box-shadow: none !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${mainColor};
    border-color: ${mainColor};
  }
`;

export const BoxTitle = styled.h5`
  font-size: 1rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const OptionsWrapperCSS = css`
  margin: 20px 0 25px 0;
  padding-bottom: 13px;

  :not(:last-of-type) {
    border-bottom: 1px solid #eae3e3;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${mainColor};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #5d9c90;
  }
`;

export const OptionsWrapper = styled.div`
  ${OptionsWrapperCSS};
  height: 200px;
  overflow-y: auto;
`;

export const ColorsOptions = styled.div`
  ${OptionsWrapperCSS};
`;

export const SizesOptions = styled.div`
  ${OptionsWrapperCSS};
`;

export const ColorCircleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
